import { WIDGET_LIFECYCLE_EVENT, WidgetLifeCycleData, WidgetLifecycleEvent } from '@ddv/models';

export class BaseWidget {
    id = 0;
    selector?: string;

    widgetLifeCycleCallBack(_eventName: WidgetLifecycleEvent, _data: WidgetLifeCycleData): void {}

    widgetLifeCyclePostProcess(_eventName: WIDGET_LIFECYCLE_EVENT, _data: unknown): void {}

    getWidgetId(): number {
        return this.id;
    }

    destroy(_: string): void {}
}

