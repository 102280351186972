<div id="workspace-container"
        class="workspace"
        [ngStyle]="styleAttributes"
        appWidgetDragTarget
        (dragOver)="onDragOver($event)">
    <div *ngFor="let widget of widgets">
        <app-widget-on-board
            [widgetConfig]="widget"
            [mode]="mode"
            [dashboardId]="dashboardId"
            [isWorkspaceGlobal]="isGlobal"
            [isManagingWidget]="isManagingWidget"
            [useNewLegend]="useNewLegend"
            (unselectAllWidgets)="unselectAllWidgets()"
            (workspaceStateChange)="onWorkspaceStateChange($event)">
        </app-widget-on-board>
    </div>
</div>
