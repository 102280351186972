import { ApiLocation, getApmEnvironment, getApmHost, getOnPremApiHost, getNQSApiHost, HostAndPort, getDDVApiHost } from '@ddv/http';

import { baseEnvironment } from './environment.base';

export const environment = {
    ...baseEnvironment,
    production: true,
    apm: {
        environment: getApmEnvironment(),
        active: true,
        host: getApmHost(),
    },
    onPremApiHost: getOnPremApiHost(),
    replaceHostInMetadataAndQueryURLs: false,
};

(environment.ddvMWLocation as ApiLocation).hostAndPort = 'on-prem';
(environment.monitorMWLocation as ApiLocation).hostAndPort = 'on-prem';
(environment.trebekApiLocation as ApiLocation).hostAndPort = 'on-prem';
(environment.usageApiLocation as ApiLocation).hostAndPort = 'on-prem';
(environment.flagsApiLocation as ApiLocation).hostAndPort = 'on-prem';
(environment.wauthApiLocation as ApiLocation).hostAndPort = 'on-prem';
(environment.xtlkApiLocation as ApiLocation).hostAndPort = 'on-prem';
(environment.reportRunnerApiLocation as ApiLocation).hostAndPort = 'on-prem';
(environment.errorLoggerLocation as ApiLocation).hostAndPort = 'on-prem';
(environment.fmpLocation as ApiLocation).hostAndPort = 'on-prem';
(environment.pscHostAndPort as HostAndPort) = 'on-prem';
(environment.portfolioDetailsHostAndPort as HostAndPort) = 'on-prem';
(environment.nqsApiLocation as ApiLocation).hostAndPort = getNQSApiHost();
(environment.ddvApiLocation as ApiLocation).rootUri = '/ddv/api/';
(environment.ddvApiLocation as ApiLocation).hostAndPort = getDDVApiHost();
(environment.monitorApiLocation as ApiLocation).rootUri = '';
(environment.monitorApiLocation as ApiLocation).hostAndPort = getDDVApiHost();
