import { HttpResponse } from '@angular/common/http';
import { Optional } from '@angular/core';
import { Observable } from 'rxjs';

import { ExecutorService, ExecutorOptions, removeRedundantSlashesFromPath } from './executor.service';
import { ApiConfiguration, apiRootForService, defaultApiConfiguration } from './model/api-configuration';

export class ApiExecutorService {
    public readonly apiRoot: string;

    constructor(
        private readonly apiName: keyof ApiConfiguration,
        private readonly executor: ExecutorService,
        @Optional() config: ApiConfiguration | null,
    ) {
        this.apiRoot = apiRootForService(this.apiName, config || defaultApiConfiguration);
    }

    invokeServiceWithParams<T>(
        clientCode: string,
        url: string,
        params?: Record<string, unknown>,
        options?: ExecutorOptions,
    ): Observable<T> {
        return this.executor.invokeServiceWithParams(`${this.apiRoot}/${clientCode}/${removeRedundantSlashesFromPath(url)}`, params, options);
    }

    invokeServiceWithBody<T>(
        clientCode: string,
        url: string,
        method: string,
        body: unknown,
        options?: ExecutorOptions,
    ): Observable<T> {
        return this.executor.invokeServiceWithBody(`${this.apiRoot}/${clientCode}/${removeRedundantSlashesFromPath(url)}`, method, body, options);
    }

    invokeServiceWithBodyAndReturnFullResponse<T>(
        clientCode: string,
        url: string,
        method: string,
        body: unknown,
        options?: ExecutorOptions,
    ): Observable<HttpResponse<T>> {
        return this.executor.invokeServiceWithBodyAndReturnFullResponse(`${this.apiRoot}/${clientCode}/${removeRedundantSlashesFromPath(url)}`, method, body, options);
    }

    invokeService<T>(
        clientCode: string,
        url: string,
        method: string,
        responseType: ('json' | 'text') = 'json',
        options: ExecutorOptions = { catchError: true },
    ): Observable<T | string> {
        return this.executor.invokeService(`${this.apiRoot}/${clientCode}/${removeRedundantSlashesFromPath(url)}`, method, responseType, options);
    }
}
