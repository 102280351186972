import { Component, EventEmitter, Input, Output, OnChanges } from '@angular/core';
import { DateRangeString, DdvDate, FuzzyDate } from '@ddv/models';

import { CalendarOptions } from '../calendar/calendar-options';
import { DateFromRange } from '../calendar/date-from-range';
import { DateRange } from '../calendar/date-range';

@Component({
    selector: 'app-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements OnChanges {
    @Input() calendarOptions: CalendarOptions = { allowPrev: true, allowNext: true, allowRange: false, single: false };
    @Input() date: string | DateRangeString | undefined;
    @Input() nextRangeDate: DateFromRange = 'dateTo';
    @Input() dateRangeValue: DateFromRange = 'dateFrom';
    @Input() fuzzyDates: FuzzyDate[] = [];
    @Input() showCalendar = true;
    @Input() showListView = false;

    @Input() year: number | undefined;
    @Input() month: number | undefined;

    @Input() isHIDataAvailable = false;

    @Output() dateSelected = new EventEmitter<string | DateRangeString>();
    @Output() errorMessageChange = new EventEmitter<string | undefined>();

    @Output() nextRangeDateChange = new EventEmitter<DateFromRange>();
    @Output() yearMonthChange = new EventEmitter<{ year: number, month: number }>();

    showFuzzyDates = false;
    selectedFuzzyDate = '';
    selectedDate: DdvDate | DateRange | undefined;
    filteredFuzzyDates: FuzzyDate[] = [];

    ngOnChanges(): void {
        this.filteredFuzzyDates = this.fuzzyDates;
        let newSelectedDate: DdvDate | DateRange | undefined;
        let newSelectedFuzzyDate: string | undefined = this.selectedFuzzyDate;

        if (this.calendarOptions.allowRange) {
            const { date: dateFrom, fuzzyText: dateFromFuzzyText } =
                this.getDateAndFuzzyTextFromString((this.date as DateRangeString)?.dateFrom);
            const { date: dateTo, fuzzyText: dateToFuzzyText } =
                this.getDateAndFuzzyTextFromString((this.date as DateRangeString)?.dateTo);

            newSelectedFuzzyDate = this.dateRangeValue === 'dateFrom' ? dateFromFuzzyText : newSelectedFuzzyDate;
            newSelectedFuzzyDate = this.dateRangeValue === 'dateTo' ? dateToFuzzyText : newSelectedFuzzyDate;
            newSelectedDate = { dateFrom, dateTo };
        } else {
            const { date, fuzzyText } = this.getDateAndFuzzyTextFromString(this.date as string);
            newSelectedDate = date;
            newSelectedFuzzyDate = fuzzyText;
        }

        this.selectedDate = newSelectedDate;
        this.selectedFuzzyDate = (newSelectedFuzzyDate ??
            (this.calendarOptions.allowRange ?
                (this.dateRangeValue === 'dateFrom' ? (this.date as DateRangeString)?.dateFrom : (this.date as DateRangeString)?.dateTo) :
                this.date as string)) ?? '';
    }

    updateSelectedDate(date: DdvDate | DateRange): void {
        let newDate: string | DateRangeString;
        if (this.calendarOptions.allowRange) {
            newDate = {
                dateFrom: this.nextRangeDate === 'dateFrom' ?
                    (date as DateRange).dateFrom?.toUSPaddedFormat() :
                    (this.date as DateRangeString).dateFrom,
                dateTo: this.nextRangeDate === 'dateTo' ?
                    (date as DateRange).dateTo?.toUSPaddedFormat() :
                    (this.date as DateRangeString).dateTo,
            };
        } else {
            newDate = (date as DdvDate).toUSPaddedFormat();
        }

        this.date = newDate;
        this.selectedDate = date;

        this.dateSelected.emit(newDate);
    }

    resetSelectedDate(date: DateRange): void {
        this.date = { dateFrom: date.dateFrom?.toUSPaddedFormat(), dateTo: date.dateTo?.toUSPaddedFormat() };
        this.selectedDate = date;
        this.selectedFuzzyDate = '';

        this.dateSelected.emit(this.date);
    }

    handleError(errorMessage: string): void {
        this.errorMessageChange.emit(errorMessage);
    }

    setNextRangeDate(nextRangeDate: string | DateFromRange): void {
        this.nextRangeDate = nextRangeDate as DateFromRange;
        this.nextRangeDateChange.emit(this.nextRangeDate);
    }

    handleYearMonthChange(event: { year: number, month: number }): void {
        this.year = event.year;
        this.month = event.month;
        this.yearMonthChange.emit({ year: event.year, month: event.month });
    }

    clearDate(): void {
        let newSelectedDate: DdvDate | DateRange | undefined;
        let newDate: string | DateRangeString | undefined;

        if (this.calendarOptions.allowRange) {
            newSelectedDate = {
                dateFrom: this.dateRangeValue === 'dateFrom' ? undefined : (this.selectedDate as DateRange).dateFrom,
                dateTo: this.dateRangeValue === 'dateTo' ? undefined : (this.selectedDate as DateRange).dateTo,
            };

            newDate = {
                dateFrom: this.dateRangeValue === 'dateFrom' ? undefined : (this.date as DateRangeString).dateFrom,
                dateTo: this.dateRangeValue === 'dateTo' ? undefined : (this.date as DateRangeString).dateTo,
            };
        } else {
            newSelectedDate = undefined;
            newDate = undefined;
        }

        this.selectedDate = newSelectedDate;
        this.date = newDate;
        this.selectedFuzzyDate = '';

        this.dateSelected.emit(this.date);
        this.errorMessageChange.emit(undefined);
    }

    onUserInput(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            this.updateDateFromInput();
            this.showFuzzyDates = false;
            return;
        }

        if (!this.selectedFuzzyDate || this.selectedFuzzyDate.trim() === '') {
            this.showFuzzyDates = false;
        } else {
            this.showFuzzyDates = true;
            this.filterFuzzyDates(this.selectedFuzzyDate);
        }
    }

    updateDateFromInput(input?: string): void {
        if (!input && !this.selectedFuzzyDate) {
            this.clearDate();
            return;
        }

        const { date, fuzzyText: newFuzzyDate } = this.getDateAndFuzzyTextFromString(input || this.selectedFuzzyDate);
        this.selectedFuzzyDate = (newFuzzyDate ?? date?.toUSPaddedFormat()) ?? '';

        if (!date?.isValid()) {
            this.errorMessageChange.emit('Incorrect Date Format');
            return;
        }

        let newSelectedDate: DdvDate | DateRange;
        let newDate: string | DateRangeString | undefined;

        if (this.calendarOptions.allowRange) {
            newSelectedDate = {
                dateFrom: this.dateRangeValue === 'dateFrom' ? date : (this.selectedDate as DateRange).dateFrom,
                dateTo: this.dateRangeValue === 'dateTo' ? date : (this.selectedDate as DateRange).dateTo,
            };

            newDate = {
                dateFrom: this.dateRangeValue === 'dateFrom' ? this.selectedFuzzyDate : (this.date as DateRangeString).dateFrom,
                dateTo: this.dateRangeValue === 'dateTo' ? this.selectedFuzzyDate : (this.date as DateRangeString).dateTo,
            };

            if (newDate.dateTo !== (this.date as DateRangeString).dateTo ||
                newDate.dateFrom !== (this.date as DateRangeString).dateFrom) {
                this.setNextRangeDate(this.dateRangeValue === 'dateFrom' ? 'dateTo' : 'dateFrom');
            }
        } else {
            newSelectedDate = date;
            newDate = this.selectedFuzzyDate;
        }

        this.selectedDate = newSelectedDate;
        this.date = newDate;
        this.selectedFuzzyDate = newFuzzyDate ?? '';

        this.showFuzzyDates = false;

        this.dateSelected.emit(this.date);
        this.errorMessageChange.emit(undefined);
    }

    updateDateFromList(event: MouseEvent, input: string): void {
        event.stopPropagation();
        this.updateDateFromInput(input);
        this.showFuzzyDates = false;
    }

    toggleFuzzyDatesList(): void {
        this.showFuzzyDates = !this.showFuzzyDates;
        this.filteredFuzzyDates = this.fuzzyDates;
    }

    private filterFuzzyDates(value: string): void {
        this.filteredFuzzyDates = this.fuzzyDates.filter((date) => {
            return date.name.toLowerCase().includes(value.toLowerCase());
        });
    }

    private getDateAndFuzzyTextFromString(dateString?: string): { date?: DdvDate, fuzzyText?: string } {
        if (!dateString) {
            return { date: undefined, fuzzyText: undefined };
        }
        const fuzzyDate = this.fuzzyDates.findByName(dateString);
        const date = fuzzyDate ? DdvDate.fromISOFormat(fuzzyDate.value) : DdvDate.fromUSFormat(dateString);
        return { date, fuzzyText: fuzzyDate?.name };
    }
}
