import { Entitlements, Roles } from './entitlement.constants';
import { Authority, User } from './user-entitlement.model';

export class UserEntitlements {
    public readonly user: User;

    private readonly authoritiesByClient: { [id: string]: Authority[] };
    private readonly clientCode: string | undefined;

    constructor(
        authoritiesByClient: { [id: string]: Authority[] },
        user: User,
    ) {
        this.authoritiesByClient = authoritiesByClient;
        this.user = user;

        if (!this.hasNoPermissions()) {
            this.clientCode = Object.keys(this.authoritiesByClient)[0];
        }
    }

    get clientCodes(): string[] {
        if (this.hasNoPermissions() || !this.clientCode) {
            return [];
        }

        return [this.clientCode];
    }

    get canManageCrosstalk(): boolean {
        return this.hasPermissionForClient(this.clientCode, Entitlements.MANAGE_CONVERSABLE_TYPE) ||
            this.hasPermissionForClient(this.clientCode, Entitlements.CROSSTALK_COMMENT_IMPORT);
    }

    get haveViewAndEditAll(): boolean {
        return this.hasPermission(Entitlements.VIEW_AND_EDIT_ALL);
    }

    get haveExternalShare(): boolean {
        return this.hasPermission(Entitlements.EXTERNAL_SHARE);
    }

    get haveGlobalEditPartial(): boolean {
        return this.user.roleName !== Roles.DDV_SuperUser && this.hasPermissionForClient(this.clientCode, Entitlements.GLOBAL_EDIT_PARTIAL);
    }

    get haveCrosstalkCommentImport(): boolean {
        return this.hasPermissionForClient(this.clientCode, Entitlements.CROSSTALK_COMMENT_IMPORT);
    }

    hasNoPermissions(): boolean {
        return !this.authoritiesByClient || !Object.keys(this.authoritiesByClient).length;
    }

    hasPermission(permission: Entitlements): boolean {
        if (!permission || this.hasNoPermissions()) {
            return false;
        }

        return this.clientCodes.every((clientCode: string) => this.hasPermissionForClient(clientCode, permission));
    }

    private hasPermissionForClient(clientCode: string | undefined, permission: string): boolean {
        return !!clientCode && this.authoritiesByClient[clientCode].some((authority: Authority) => authority.authority === permission);
    }
}
