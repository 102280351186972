import { Injectable } from '@angular/core';

export class JSONStorage {
    constructor(private readonly storage: Storage) {}

    access<T>(key: string): T | undefined{
        const jsonString = this.storage.getItem(key);
        if (jsonString == null) {
            return;
        }

        return JSON.parse(jsonString);
    }

    store<T>(key: string, obj: T): void {
        this.storage.setItem(key, JSON.stringify(obj));
    }
}

@Injectable({ providedIn: 'root' })
export class LocalStorageProviderService {
    provide(): JSONStorage {
        return new JSONStorage(window.localStorage);
    }
}
