import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { IRowNode } from '@ag-grid-community/core';
import { Component, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { AlertService, ConfirmationPopupService, MultiSubscriptionComponent } from '@ddv/common-components';
import { CustomCellRendererParams } from '@ddv/data-grid';
import { UserData, UserEntitlementService } from '@ddv/entitlements';
import { Attachment, CrosstalkFields } from '@ddv/models';
import { UsageTracker, UsageTrackingService } from '@ddv/usage-tracking';
import { ModalAction } from '@hs/ui-core-notifications';
import { firstValueFrom, Observable } from 'rxjs';

import { CrosstalkComment } from '../../../models/crosstalk-comment';
import { CrosstalkModalService } from '../../../services/crosstalk-modal.service';
import { CrosstalkBulkAddResponse, CrosstalkService } from '../../../services/crosstalk.service';
import { FileDownloaderService } from './file-downloader.service';

export const MAXIMUM_FILE_SIZE = 15;
export const MAXIMUM_ATTACHMENT_COUNT = 10;
export const ALLOWED_DOC_TYPES = ['csv', 'xlsx', 'xls', 'pdf', 'txt', 'png', 'jpeg', 'jpg', 'gif', 'doc', 'docx', 'bmp', 'msg'];

@Component({
    selector: 'app-attachments-cell-renderer',
    templateUrl: './attachments-cell-renderer.component.html',
    styleUrls: ['./attachments-cell-renderer.component.scss'],
})
export class AttachmentsCellRendererComponent extends MultiSubscriptionComponent implements ICellRendererAngularComp {
    params: CustomCellRendererParams | undefined;
    clientCode = '';
    conversationId: string | undefined;
    attachmentsInputId: string | undefined;
    conversableType: string | undefined;
    isUserEntitledToUploadFiles = false;
    isUserEntitledToUploadBulkFiles = false;
    lastAttachments: Attachment[] | undefined;
    attachmentsCount = 0;
    selectedNodesCount = 0;
    private user: UserData | undefined;

    @ViewChild('attachment') attachmentInput: ElementRef | undefined;
    @ViewChild('multiAttachmentTemplate') multiAttachmentTemplate: TemplateRef<string> | undefined;

    constructor(
        private readonly crosstalkService: CrosstalkService,
        private readonly crosstalkModalService: CrosstalkModalService,
        private readonly usageTracking: UsageTrackingService,
        private readonly entitlementsService: UserEntitlementService,
        private readonly fileDownloader: FileDownloaderService,
        private readonly confirmationService: ConfirmationPopupService,
        private readonly alertService: AlertService,
    ) {
        super();
        this.subscribeTo(this.entitlementsService.userData$, (user) => this.user = user);
    }

    agInit(params: CustomCellRendererParams): void {
        this.parseParams(params);
    }

    refresh(params: CustomCellRendererParams): boolean {
        return this.parseParams(params);
    }

    async onFilesAttached($event: Event): Promise<void> {
        try {
            const files = Array.from(($event.target as HTMLInputElement).files ?? []);

            if (files.length) {
                this.verifyAttachmentsCount(files, MAXIMUM_ATTACHMENT_COUNT);
                const formData: FormData = new FormData();

                files.forEach((file) => {
                    this.verifyAttachmentSize(file, MAXIMUM_FILE_SIZE);
                    this.verifyFileType(file);
                    formData.append(file.name, file);
                });

                const selectedNodes = this.params?.api.getSelectedNodes() ?? [];
                if (selectedNodes.length > 1) {
                    this.verifyUserBulkAttachmentCredentials();
                    const conversationIds = selectedNodes.map((node) => node.data.links?.[CrosstalkFields.Attachments]?.conversationId);
                    if (!conversationIds.includes(this.conversationId)) {
                        conversationIds.push(this.conversationId);
                    }
                    this.attachmentsCount = files.length;
                    this.selectedNodesCount = conversationIds.length;
                    this.openConfirmationDialog().subscribe({
                        next: async (action) => {
                            this.keepSelectedNodes(selectedNodes);
                            if (action === 'confirm') {
                                try {
                                    const temporaryAttachmentsIds = await this.getTemporaryAttachmentsIds(formData);
                                    const savedBulkAttachments = await this.saveBulkAttachments(conversationIds, temporaryAttachmentsIds);
                                    if (savedBulkAttachments.requiresApproval) {
                                        this.alertService.info('The upload was successful. It is now waiting for approval.');
                                    }
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                } catch (error: any) {
                                    if (error !== 'BACKDROP_CLICK') {
                                        this.alertService.error(error?.error?.error ?? error.message);
                                    }
                                }
                            }
                            this.resetAttachmentInput();
                        },
                    });
                } else {
                    const temporaryAttachmentsIds = await this.getTemporaryAttachmentsIds(formData);
                    const savedAttachments = await this.saveAttachments(temporaryAttachmentsIds);
                    if (savedAttachments.requiresApproval) {
                        this.alertService.info('The upload was successful. It is now waiting for approval.');
                    }
                    this.resetAttachmentInput();
                }
            }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            if (error !== 'BACKDROP_CLICK') {
                this.alertService.error(error?.error?.error ?? error.message);
            }
            this.resetAttachmentInput();
        }
    }

    openHistory($event: MouseEvent): void {
        const colId = this.params?.colDef?.colId;
        const field = this.params?.colDef?.field ?? 'PRESUMABLY THIS SHOULD NEVER HAPPEN';
        const linkConfiguration = { columnId: colId, ...this.params?.data.links[field] };

        if (linkConfiguration) {
            this.crosstalkModalService.openModal(linkConfiguration, $event);
        }

        if ($event.stopImmediatePropagation) {
            $event.stopImmediatePropagation();
        }
    }

    downloadAttachments(): void {
        if (this.lastAttachments?.length) {
            const usage: UsageTracker = this.usageTracking.startTracking(
                'DDV.Attachment.Download',
                { username: this.user?.userName, isInternal: this.user?.internal });
            this.lastAttachments.forEach((attachment) => {
                this.fileDownloader.downloadFile(attachment.originalFilename, attachment.url);
            });
            usage.succeeded();
        }
    }

    private parseParams(params: CustomCellRendererParams): boolean {
        const attachmentsChanged =
            this.params?.data?.links?.[CrosstalkFields.Attachments] !== params.data?.links?.[CrosstalkFields.Attachments];

        this.params = params;
        this.clientCode = this.params.data?.links?.[CrosstalkFields.Attachments]?.clientCode;
        this.conversationId = this.params.data?.links?.[CrosstalkFields.Attachments]?.conversationId;
        this.attachmentsInputId = `attachments_upload_${this.conversationId}`;

        this.conversableType = this.params.conversableType;

        this.isUserEntitledToUploadFiles = this.params.data?.links?.[CrosstalkFields.Attachments]?.isUserEntitledToUploadAttachments;
        this.isUserEntitledToUploadBulkFiles = this.params.data?.links?.
            [CrosstalkFields.Attachments]?.isUserEntitledToUploadBulkAttachments;
        this.lastAttachments = this.params.data?.links?.[CrosstalkFields.Attachments]?.lastAttachments?.length ?
            this.params.data?.links?.[CrosstalkFields.Attachments]?.lastAttachments : null;

        return attachmentsChanged;
    }

    private async getTemporaryAttachmentsIds(formData: FormData): Promise<string[]> {
        const tempAttachments = await firstValueFrom(this.crosstalkService.addTemporaryAttachmentsToClient(this.clientCode, formData));
        return tempAttachments.map((attachment) => attachment.id);
    }

    private async saveAttachments(temporaryAttachmentsIds: string[]): Promise<CrosstalkComment> {
        if (!this.conversationId) {
            throw new Error('Cannot save attachments without a conversationId');
        }

        return firstValueFrom(
            this.crosstalkService.addAttachmentsToConversation(this.clientCode, this.conversationId, temporaryAttachmentsIds));
    }

    private async saveBulkAttachments(conversationIds: string[], temporaryAttachmentsIds: string[]): Promise<CrosstalkBulkAddResponse> {
        if (!this.conversableType) {
            throw new Error('Cannot bulkSave attachments without a conversation id');
        }

        return firstValueFrom(this.crosstalkService
            .addAttachmentsToManyConversations(this.clientCode, this.conversableType, conversationIds, temporaryAttachmentsIds));
    }

    private resetAttachmentInput(): void {
        if (this.attachmentInput) {
            this.attachmentInput.nativeElement.value = null;
        }
    }

    private verifyAttachmentsCount(files: File[], count: number): void {
        if (files.length > count) {
            throw new Error(`Attachments exceed total allowed count of ${count}.`);
        }
    }

    private verifyAttachmentSize(file: File, allowedSize: number): void {
        if (file.size > allowedSize * 1024 * 1024) {
            throw new Error(`File ${file.name} exceeds allowed file size of ${allowedSize} MB.`);
        }
    }

    private verifyFileType(file: File): void {
        const fileType = file.name.includes('.') ? file.name.split('.').pop()?.toLowerCase() : undefined;

        if (!fileType) {
            throw new Error(`You are trying to upload a document without an extension. THE LIST OF VALID DOCUMENT TYPES IS: ${ALLOWED_DOC_TYPES.join(', ')}.`);
        }

        if (!ALLOWED_DOC_TYPES.includes(fileType)) {
            throw new Error(`THE LIST OF VALID DOCUMENT TYPES IS: ${ALLOWED_DOC_TYPES.join(', ')}.`);
        }
    }

    private verifyUserBulkAttachmentCredentials(): void {
        if (!this.isUserEntitledToUploadBulkFiles) {
            throw new Error('You are not entitled to bulk attachment.');
        }
    }

    private keepSelectedNodes(selectedNodes: IRowNode[]): void {
        selectedNodes.forEach((node) => node.setSelected(true));
        this.params?.api.refreshCells({ force: true });
        this.params?.api.refreshHeader();
    }

    private openConfirmationDialog(): Observable<ModalAction | undefined> {
        const confirmDialogOptions = {
            message: this.multiAttachmentTemplate,
            confirmButtonText: 'OK',
            denyButtonText: 'Cancel',
        };
        return this.confirmationService.showConfirmationPopup(confirmDialogOptions);
    }
}
