import { Injectable } from '@angular/core';
import * as d3 from 'd3';

import { BarChartData } from '../../models/chart-data';
import { HorizontalBarChartScaleService } from './horizontal-bar-chart-scale.service';
import { HorizontalBarChartTextFormatterService } from './horizontal-bar-chart-text-formatter.service';

@Injectable()
export class HorizontalBarChartHoverLineService {
    private chartRootGSelection: d3.Selection<SVGGElement, BarChartData, null, undefined> | undefined;
    private height: number = 0;
    private focusLine: d3.Selection<SVGLineElement, BarChartData, null, undefined> | undefined;
    private focusWrapper: d3.Selection<SVGGElement, BarChartData, null, undefined> | undefined;

    constructor(
        private readonly scales: HorizontalBarChartScaleService,
        private readonly textFormatter: HorizontalBarChartTextFormatterService,
    ) {}

    initialize(chartRootGSelection: d3.Selection<SVGGElement, BarChartData, null, undefined>, height: number): void {
        this.chartRootGSelection = chartRootGSelection;
        this.height = height;

        this.appendLine(chartRootGSelection);
    }

    show(coordinates: [number, number]): void {
        if (!this.chartRootGSelection || !this.focusLine) {
            return;
        }

        const wrapper = this.removeAndInsertWrapper(this.chartRootGSelection);
        const value = this.scales.horizontalPositionToValue(coordinates[0]);
        const linePosition = this.scales.valueToHorizontalPosition(value);

        this.moveLine(this.focusLine, linePosition);
        this.showValueOnLine(wrapper, linePosition, value);
    }

    hide(): void {
        this.focusWrapper?.remove();
        this.focusLine?.attr('opacity', 0);
    }

    private removeAndInsertWrapper(
        chartRootGSelection: d3.Selection<SVGGElement, BarChartData, null, undefined>,
    ): d3.Selection<SVGGElement, BarChartData, null, undefined> {
        chartRootGSelection.selectAll('.focusvalue').remove();
        const wrapper = chartRootGSelection.append('g').attr('class', 'focusvalue');
        this.focusWrapper = wrapper;
        return wrapper;
    }

    private appendLine(chartRootGSelection: d3.Selection<SVGGElement, BarChartData, null, undefined>): void {
        const focus = chartRootGSelection.append('g').attr('class', 'focus--y');
        this.focusLine = focus.append('line').attr('pointer-events', 'none');
    }

    private moveLine(focusLine: d3.Selection<SVGLineElement, BarChartData, null, undefined>, position: number): void {
        focusLine
            .attr('x1', position)
            .attr('x2', position)
            .attr('y1', 0)
            .attr('y2', this.height)
            .attr('opacity', 1)
            .attr('stroke', 'block')
            .attr('class', 'tooltip-line');
    }

    private showValueOnLine(wrapper: d3.Selection<SVGGElement, BarChartData, null, undefined>, linePosition: number, value: number): void {
        wrapper.selectAll('.y-value').remove();
        wrapper.append('g')
            .attr('class', 'y-value')
            .attr('transform', `translate(${linePosition - 25},${this.height + 10})`)
            .append('text')
            .text(this.textFormatter.formatValue(value));
    }
}
