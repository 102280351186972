import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { DropdownOption, MultiSubscriptionComponent } from '@ddv/common-components';
import { Entitlements, UserEntitlements, UserData, UserEntitlementService, UserService } from '@ddv/entitlements';
import { Client, DdvDate, UserPreferences, Fund, FundAvailability, FuzzyDates } from '@ddv/models';
import { ClientsService, FundsService, FuzzyDatesService } from '@ddv/reference-data';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Versions, VersionsService } from './versions.service';

const RANGE_ERR_MSG = 'From date should not be greater than to date';

@Component({
    selector: 'app-user-preference',
    templateUrl: 'user-preference.component.html',
    styleUrls: ['user-preference.component.scss'],
})
export class UserPreferenceComponent extends MultiSubscriptionComponent implements OnInit {
    showDashboardFunds = false;
    showDashboardClients = false;
    userPreference: UserPreferences | undefined;
    isSmallDevice = false;

    private userPreferenceFuzzyDates: FuzzyDates = new FuzzyDates();
    get fuzzyDates(): FuzzyDates {
        return this.userPreferenceFuzzyDates;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    set fuzzyDates(value: any) {
        this.userPreferenceFuzzyDates = value;
        this.fuzzyDatesFromOptions = [
            { text: 'Select', key: '', value: '' },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ...value.from.map((d: any) => ({ text: d.name, key: d.name, value: d.name })),
        ];
        this.fuzzyDatesToOptions = [
            { text: 'Select', key: '', value: '' },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ...value.to.map((d: any) => ({ text: d.name, key: d.name, value: d.name })),
        ];
    }

    errorMessage: string | undefined;
    @Output() userPreferencesClosed = new EventEmitter();
    @Output() fundsApply = new EventEmitter();
    @Output() clientsApply = new EventEmitter();
    showManageDatasetsMenu = false;
    fullName: string | undefined;
    roleName: string | undefined;
    hasBothFundAvailability = false;
    funds: Fund[] = [];
    clients: Client[] = [];
    userSelectedFunds: Fund[] = [];
    userSelectedClients: Client[] = [];
    isMultiClient = false;
    areHIFunds = false;

    fuzzyDatesFromOptions: DropdownOption[] | undefined;
    selectedDefaultFromDate: DropdownOption | undefined;
    fuzzyDatesToOptions: DropdownOption[] | undefined;
    selectedDefaultToDate: DropdownOption | undefined;
    protected versions: Versions | undefined;

    constructor(
        private readonly userService: UserService,
        private readonly fuzzyDatesService: FuzzyDatesService,
        public userEntitlementService: UserEntitlementService,
        private readonly fundsService: FundsService,
        private readonly clientsService: ClientsService,
        private readonly currentStateService: CurrentStateService,
        public versionsService: VersionsService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscribeTo(this.userService.userPreferences$, (userPreferences: UserPreferences) => {
            this.userPreference = { ...userPreferences };
            this.areHIFunds = this.userPreference.fundNamesSource === FundAvailability.HI;
            this.selectedDefaultFromDate = {
                text: this.userPreference.startDate ?? '',
                key: this.userPreference.startDate,
                value: this.userPreference.startDate,
            };
            this.selectedDefaultToDate = {
                text: this.userPreference.endDate ?? '',
                key: this.userPreference.endDate,
                value: this.userPreference.endDate,
            };
            this.userSelectedFunds = this.funds.filter((f) => f.isIncludedInArrayOfIds(userPreferences.fundsCodes));
            this.userSelectedClients = this.clients.filter((c) => this.userPreference?.clientsCodes.includes(c.clientName));
        });

        this.subscribeTo(this.fuzzyDatesService.fuzzyDates(), (fuzzyLists: FuzzyDates) => {
            this.fuzzyDates = { ...fuzzyLists };
        });

        this.isSmallDevice = window.innerHeight < 550;

        this.subscribeTo(this.userEntitlementService.entitlementsForClientCode$, (entitlements: UserEntitlements) => {
            this.showManageDatasetsMenu = entitlements?.hasPermission(Entitlements.DATASET_DEFINITION_MANAGE);
            this.roleName = entitlements.user.roleName;
        });

        this.subscribeTo(this.userEntitlementService.userData$, (data: UserData) => {
            this.fullName = data.fullName;
        });

        this.subscribeTo(this.getSubscriptionBasedOnMultiClient(), (data: Fund[] | string[]) => {
            if (data.some((datum) => datum instanceof Fund)) {
                this.funds = data as Fund[];
                this.userSelectedFunds = this.funds.filter((f) => f.isIncludedInArrayOfIds(this.userPreference?.fundsCodes ?? []));
                this.hasBothFundAvailability = (data as Fund[]).some((fund) => fund.availability === FundAvailability.BOTH);
            } else if (data.some((datum) => typeof datum === 'string')) {
                this.clients = (data as string[]).map((datum) => ({ clientId: datum, clientName: datum }));
                this.userSelectedClients = this.clients.filter((c) => this.userPreference?.clientsCodes.includes(c.clientName));
                this.hasBothFundAvailability = false;
            } else {
                this.hasBothFundAvailability = false;
            }
        });

        this.subscribeTo(this.versionsService.getVersions(), (versions) => this.versions = versions);
    }

    toggleDashboardFunds(): void {
        this.showDashboardFunds = !this.showDashboardFunds;
    }

    toggleDashboardClients(): void {
        this.showDashboardClients = !this.showDashboardClients;
    }

    updateErrorAcknowledgementPreference(autoDismiss: boolean): void {
        if (this.userPreference) {
            this.userPreference.autoDismissErrors = autoDismiss;
        }
        this.savePreference();
    }

    updateFundNamesSourcePreference(areIvestorsFundsSelected: boolean): void {
        this.areHIFunds = areIvestorsFundsSelected;
        if (this.userPreference) {
            this.userPreference.fundNamesSource = areIvestorsFundsSelected ?
                FundAvailability.HI :
                FundAvailability.HSMAIN;
        }
        this.savePreference();
    }

    applySelectedFunds(funds: Fund[]): void {
        if (this.userPreference) {
            this.userPreference.fundsCodes = funds.map((f) => f.fundId);
        }
        this.showDashboardFunds = false;
        this.fundsApply.emit();
        this.savePreference();
    }

    applySelectedClients(clients: Client[]): void {
        if (this.userPreference) {
            this.userPreference.clientsCodes = clients.map((c) => c.clientId);
        }
        this.showDashboardClients = false;
        this.clientsApply.emit();
        this.savePreference();
    }

    validateDateRangeAndSave(): void {
        if (this.userPreference) {
            this.userPreference.startDate = this.selectedDefaultFromDate?.value;
            this.userPreference.endDate = this.selectedDefaultToDate?.value;
            this.errorMessage = '';
            if (this.userPreference.startDate && this.userPreference.endDate && !this.isStartDateIsLesser()) {
                this.errorMessage = RANGE_ERR_MSG;
            } else {
                this.savePreference();
            }
        }
    }

    savePreference(): void {
        if (this.userPreference) {
            this.userService.updateUserPreferenceData(this.userPreference).subscribe();
        }
    }

    private isStartDateIsLesser(): boolean {
        return DdvDate.fromISOFormat(this.fuzzyDates.from.findByName(this.userPreference?.startDate)?.value).toMilliseconds()
            <= DdvDate.fromISOFormat(this.fuzzyDates.to.findByName(this.userPreference?.endDate)?.value).toMilliseconds();
    }

    private getSubscriptionBasedOnMultiClient(): Observable<Fund[] | string[]> {
        return this.currentStateService.isMultiClient$.pipe(switchMap((isMultiClient) => {
            this.isMultiClient = isMultiClient;

            if (this.isMultiClient) {
                return this.clientsService.clients();
            }

            return this.fundsService.funds();
        }));
    }
}
