import { isStringValidISODate } from '@ddv/utils';

export class DdvDate {
    private static readonly dashPattern = /^(\d{1,4})-(\d{1,2})-(\d{1,2})$/;
    private static readonly usPattern = /^(\d{1,2})\/(\d{1,2})\/(\d{1,4})$/;
    private static readonly euPattern = /^(\d{1,2})\/(\d{1,2})\/(\d{1,4})$/;

    private readonly dayLengthInMs = 1000 * 60 * 60 * 24;

    static get monthsShort(): string[] {
        return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    }

    static get firstMonthInEachQuarter(): { [key: string]: number } {
        return { '01': 0, '02': 3, '03': 6, '04': 9 };
    }

    static getMonthIndex(monthName: string): number {
        return DdvDate.monthsShort.findIndex((n) => n === monthName);
    }

    // DdvDate works with zero-based months, the same way as js Date
    constructor(public year?: number, public month?: number, public day?: number) {}

    get monthName(): string {
        return DdvDate.monthsShort[this.definedMonth()];
    }

    get quarter(): number {
        return Math.ceil((this.definedMonth() + 1) / 3);
    }

    get dayOfTheWeek(): number {
        return this.toDate().getDay();
    }

    static get empty(): DdvDate {
        return new DdvDate();
    }

    static get today(): DdvDate {
        const d = new Date();
        return new DdvDate(d.getFullYear(), d.getMonth(), d.getDate());
    }

    static fromDate(date: Date): DdvDate {
        return new DdvDate(date.getFullYear(), date.getMonth(), date.getDate());
    }

    // Y-M-D
    static fromDashFormat(date?: string): DdvDate {
        if (!date || !DdvDate.dashPattern.test(date)) {
            return DdvDate.empty;
        }

        const [, year, month, day] = DdvDate.dashPattern.exec(date) ?? [];
        const d = new DdvDate(+year, +month - 1, +day);
        return d.isValid() ? d : DdvDate.empty;
    }

    // M/D/Y
    static fromUSFormat(date?: string): DdvDate {
        if (!date || !DdvDate.usPattern.test(date)) {
            return DdvDate.empty;
        }
        const [, month, day, year] = DdvDate.usPattern.exec(date) ?? [];
        const d = new DdvDate(+year, +month - 1, +day);
        return d.isValid() ? d : DdvDate.empty;
    }

    // D/M/Y
    static fromEUFormat(date: string): DdvDate {
        if (!date || !DdvDate.euPattern.test(date)) {
            return DdvDate.empty;
        }
        const [, day, month, year] = DdvDate.euPattern.exec(date) ?? [];
        const d = new DdvDate(+year, +month - 1, +day);
        return d.isValid() ? d : DdvDate.empty;
    }

    // Y-M-DTXXX
    // it is a bit relaxed ISO format, because it doesn't care about anything after the T and
    // it doesn't have to be padded year/month/day
    static fromISOFormat(date?: string): DdvDate {
        return DdvDate.fromDashFormat(date?.split('T')[0]);
    }

    private static doesStringLookLikeDate(date: string): boolean {
        return DdvDate.dashPattern.test(date) ||
            DdvDate.usPattern.test(date) ||
            DdvDate.euPattern.test(date) ||
            isStringValidISODate(date);
    }

    // that is a code smeel and it needs to be removed
    // it is enough to check whether the actual from method returns valid date
    static isStringValidDate(date?: string): boolean {
        return !!date && DdvDate.doesStringLookLikeDate(date) && !isNaN(Date.parse(date));
    }

    static isStringValidDashFormatDate(date: string | undefined): boolean {
        return !!date && DdvDate.dashPattern.test(date);
    }

    isValid(): boolean {
        return this.year != null && this.month != null && this.day != null &&
            !isNaN(this.toMilliseconds()) && this.toDate().getMonth() === this.month;
    }

    sameAs(other?: DdvDate): boolean {
        // eslint-disable-next-line eqeqeq
        return this.year == other?.year && this.month == other?.month && this.day == other?.day;
    }

    isBefore(compareValue: DdvDate): boolean {
        return this.toDate() < compareValue.toDate();
    }

    isAfter(compareValue: DdvDate): boolean {
        return this.toDate() > compareValue.toDate();
    }

    isBetween(from: DdvDate, to: DdvDate): boolean {
        return this.toDate() >= from.toDate() && this.toDate() <= to.toDate();
    }

    addDays(days: number): DdvDate {
        const d = new Date(this.toDate().getTime() + this.dayLengthInMs * days);
        return DdvDate.fromDate(d);
    }

    addWeeks(weeks: number): DdvDate {
        const d = new Date(this.toDate().getTime() + this.dayLengthInMs * 7 * weeks);
        return DdvDate.fromDate(d);
    }

    addMonths(months: number): DdvDate {
        const d = this.toDate();

        const nextMonth = this.definedMonth() + months < 12 ? this.definedMonth() + months : (this.definedMonth() + months) % 12;
        const nextYear = this.definedYear() + Math.floor((this.definedMonth() + months) / 12);
        const nextMonthDays = new DdvDate(nextYear, nextMonth, 1).endOfMonth().definedDay();

        if (this.definedDay() > nextMonthDays) {
            d.setDate(nextMonthDays);
        }

        d.setMonth(d.getMonth() + months);
        return DdvDate.fromDate(d);
    }

    startOfWeek(): DdvDate {
        const date = this.toDate();
        const diff = date.getDate() - date.getDay();
        const startOfWeek = new Date(date.setDate(diff));
        return new DdvDate(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate());
    }

    endOfWeek(): DdvDate {
        const date = this.toDate();
        const diff = date.getDate() - date.getDay() + 6;
        const endOfWeek = new Date(date.setDate(diff));
        return new DdvDate(endOfWeek.getFullYear(), endOfWeek.getMonth(), endOfWeek.getDate());
    }

    startOfMonth(): DdvDate {
        return new DdvDate(this.year, this.month, 1);
    }

    endOfMonth(): DdvDate {
        const lastDayOfMonth = new Date(this.definedYear(), this.definedMonth() + 1, 0);
        return new DdvDate(lastDayOfMonth.getFullYear(), lastDayOfMonth.getMonth(), lastDayOfMonth.getDate());
    }

    // that is probably a code smell and it needs to be removed
    toMilliseconds(): number {
        return new Date(this.definedYear(), this.definedMonth(), this.day).getTime();
    }

    // we should try to get rid of all exposure of js Date object in DDV,
    // so that needs at least to be made private
    toDate(): Date {
        return new Date(this.definedYear(), this.definedMonth(), this.day);
    }

    // DDMMYYYY
    toDDMMYYYYFormat(): string {
        if (!this.isValid()) {
            return '';
        }

        const month = (this.definedMonth() < 9 ? '0' : '') + (this.definedMonth() + 1).toString();
        const day = (this.definedDay() < 10 ? '0' : '') + this.definedDay().toString();
        let year = this.definedYear().toString();
        while (year.length < 4) {
            year = `0${year}`;
        }

        return `${day}${month}${year}`;
    }

    // YYYY-MM-DD
    toReversePaddedDashFormat(): string {
        if (!this.isValid()) {
            return '';
        }

        const month = (this.definedMonth() < 9 ? '0' : '') + (this.definedMonth() + 1).toString();
        const day = (this.definedDay() < 10 ? '0' : '') + this.definedDay().toString();
        let year = this.definedYear().toString();
        while (year.length < 4) {
            year = `0${year}`;
        }

        return `${year}-${month}-${day}`;
    }

    // M/D/Y
    toUSFormat(): string {
        if (!this.isValid()) {
            return '';
        }

        return `${this.definedMonth() + 1}/${this.day}/${this.year}`;
    }

    // MM/DD/YYYY
    toUSPaddedFormat(): string {
        if (!this.isValid()) {
            return '';
        }

        const month = (this.definedMonth() < 9 ? '0' : '') + (this.definedMonth() + 1).toString();
        const day = (this.definedDay() < 10 ? '0' : '') + this.definedDay().toString();
        let year = this.definedYear().toString();
        while (year.length < 4) {
            year = `0${year}`;
        }

        return `${month}/${day}/${year}`;
    }

    // DD/MM/YYYY
    toEUPaddedFormat(): string {
        if (!this.isValid()) {
            return '';
        }

        const month = (this.definedMonth() < 9 ? '0' : '') + (this.definedMonth() + 1).toString();
        const day = (this.definedDay() < 10 ? '0' : '') + this.definedDay().toString();
        let year = this.definedYear().toString();
        while (year.length < 4) {
            year = `0${year}`;
        }

        return `${day}/${month}/${year}`;
    }

    // YYYY-MM-DDT00:00:00.000Z
    toISOFormat(): string {
        if (!this.isValid()) {
            return '';
        }
        const month = (this.definedMonth() < 9 ? '0' : '') + (this.definedMonth() + 1).toString();
        const day = (this.definedDay() < 10 ? '0' : '') + this.definedDay().toString();
        let year = this.definedYear().toString();
        while (year.length < 4) {
            year = `0${year}`;
        }

        return `${year}-${month}-${day}T00:00:00.000Z`;
    }

    private definedYear(): number {
        return this.year === undefined ? 0 : this.year;
    }

    private definedMonth(): number {
        return this.month === undefined ? 0 : this.month;
    }

    private definedDay(): number {
        return this.day === undefined ? 1 : this.day;
    }
}
