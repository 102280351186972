import { Component, EventEmitter, OnDestroy, OnInit, Output, Input } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ModalDialogActive } from '@ddv/common-components';
import { MetadataService } from '@ddv/datasets';
import { BoardWidgetDefinitionIds, FILTER_TYPE, DashboardFilter } from '@ddv/models';
import { Subscription } from 'rxjs';

import { DashboardFiltersService } from '../../services/dashboard-filters.service';

// only dynamically instantiated and only by DashboardFiltersComponent
@Component({
    selector: 'ddv-dataset-filters-modal',
    templateUrl: 'dataset-filters-modal.component.html',
    styleUrls: ['dataset-filters-modal.component.scss'],
    providers: [NgModel],
})
export class DatasetFiltersModalComponent implements OnInit, OnDestroy {
    @Input() filterType = FILTER_TYPE.DASHBOARD;
    @Input() dashboardId = '';
    @Input() widgetId = 0;
    @Input() dashboardFilters: DashboardFilter[] | undefined;
    @Input() bwd: BoardWidgetDefinitionIds | undefined;

    @Output() filtersApplied = new EventEmitter<DashboardFilter[]>();

    showFilterableAttribute = 0;
    showFilterableValue = 0;
    showAddFilters = false;
    newDashboardFilter: DashboardFilter | undefined;
    noFiltersAvailable = true;
    metadataLoaded = false;
    private metadataSubscription: Subscription | undefined;

    constructor(
        public modalDialogActive: ModalDialogActive,
        public dashboardFiltersService: DashboardFiltersService,
        private readonly metadataService: MetadataService,
    ) {}

    ngOnInit(): void {
        this.dashboardFiltersService.widgetId = this.widgetId;
        this.dashboardFiltersService.filterType = this.filterType;
        this.dashboardFiltersService.setSlicerValue();
        this.getSavedDashboardFilters();
        this.showFilterableAttribute = -1;
        this.showFilterableValue = -1;
        this.showAddFilters = false;
        this.dashboardFiltersService.updateTotalValueCount();

        let contexts: BoardWidgetDefinitionIds[] = [];

        if (this.filterType === FILTER_TYPE.DASHBOARD) {
            contexts = this.dashboardFiltersService.initFilterDashboard();
        } else if (this.widgetId) {
            contexts = this.dashboardFiltersService.initFilterWidget(this.widgetId);
        } else if (this.bwd) {
            contexts = [this.bwd];
        }

        this.noFiltersAvailable = contexts.length === 0;

        if (!this.noFiltersAvailable) {
            this.metadataSubscription = this.metadataService.fetchMetadataUniqueMetadata(contexts[0])
                .subscribe((uniqueMetadata) => {
                    Object.entries(uniqueMetadata).forEach(([key, value]) => {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (value as any).name = key;
                    });
                    this.metadataLoaded = true;
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    this.dashboardFiltersService.updateMetadata(uniqueMetadata as any);
                });
        }
    }

    clearAllFilters(): void {
        this.dashboardFiltersService.dashboardFilters = [];
    }

    onCancelFilters(): void {
        this.modalDialogActive.close();
    }

    onApplyFilters(): void {
        this.filtersApplied.emit(this.dashboardFiltersService.dashboardFilters);
        this.modalDialogActive.close();
    }

    showAddFilter(): void {
        this.newDashboardFilter = { values: [] };
        this.showAddFilters = true;
    }

    addNewAttribute(selectedFilter: DashboardFilter): void {
        this.newDashboardFilter = selectedFilter;
    }

    addNewFilterEntry(selectedFilter: DashboardFilter): void {
        const dashboardFilter = this.dashboardFiltersService.dashboardFilters?.some((filter) => filter.name === selectedFilter.name);
        if (!dashboardFilter) {
            this.dashboardFiltersService.dashboardFilters?.push(selectedFilter);
        }
    }

    deleteFilterEntry(index: number): void {
        this.dashboardFiltersService.dashboardFilters?.splice(index, 1);
    }

    addFilterValues(selectedFilterValues: DashboardFilter, index: number): void {
        if (this.dashboardFiltersService.dashboardFilters) {
            this.dashboardFiltersService.dashboardFilters[index] = selectedFilterValues;
        }
    }

    selectedAttribute(index: number): void {
        this.showFilterableAttribute = -1;
        this.showFilterableValue = index;
    }

    toggleFilterableAttribute(event: Event, index: number): void {
        event.stopPropagation();
        this.showFilterableAttribute = (this.showFilterableAttribute === index) ? -1 : index;
        this.showFilterableValue = -1;
        this.showAddFilters = false;
    }

    toggleFilterableValue(event: Event, index: number): void {
        event.stopPropagation();
        this.showFilterableAttribute = -1;
        this.showFilterableValue = (this.showFilterableValue === index) ? -1 : index;
        this.showAddFilters = false;
    }

    removeValue(filterIndex: number, valueIndex: number): void {
        this.dashboardFiltersService.dashboardFilters?.[filterIndex].values?.splice(valueIndex, 1);
    }

    ngOnDestroy(): void {
        this.unsubscribeFromMetadata();
    }

    private getSavedDashboardFilters(): void {
        this.dashboardFiltersService.dashboardFilters = Object.assign([], this.dashboardFilters);
    }

    private unsubscribeFromMetadata(): void {
        if (this.metadataSubscription) {
            this.metadataSubscription.unsubscribe();
        }
    }
}
