import { Injectable } from '@angular/core';
import { ColorConfig, ConfigItem, SliceManagement } from '@ddv/models';
import { Theme } from '@hs/ui-core-presentation';
import * as d3 from 'd3';

import { ChartData } from '../models/chart-data';
import { ChartSettings } from '../models/chart-settings';
import { ColorInfo } from '../models/color-info';
import { ColorType } from '../models/color-type';
import { PieChartDataModel } from '../models/pie-chart-data-model';
import { hasOwnProperty, sliceOthers } from '../utils';
import { BarChartService } from './bar-chart.service';
import { BaseChartService } from './base-chart.service';
import { ColorMetadataService } from './color-metadata.service';
import { ColorProvider } from './interfaces';
import { LineChartService } from './line-chart.service';
import { PieChartService } from './pie-chart.service';
import { StackedAreaChartService } from './stacked-area-chart.service';

@Injectable()
export class DataSourceService {
    private readonly colorMetadataService: ColorMetadataService;
    private isComparing: boolean = false;

    constructor() {
        this.colorMetadataService = new ColorMetadataService();
    }

    prepareChartData(
        config: ChartSettings | PieChartDataModel,
        chartData: BaseChartService,
        isComparing: boolean = false,
    ): ChartData[] {
        this.isComparing = isComparing;
        const series = config.series[0];

        switch (series.type) {
            case 'bar':
                return this.prepareBarData(config as ChartSettings, chartData as BarChartService);
            case 'line':
                return this.prepareLineData(config as ChartSettings, chartData as LineChartService);
            case 'pie':
            case 'donut':
                return this.preparePieData(config as PieChartDataModel, chartData as PieChartService);
            case 'stacked-area':
                return this.prepareStackedAreaData(config as ChartSettings, chartData as StackedAreaChartService);
            default:
                return [];
        }
    }

    private prepareBarData(config: ChartSettings, chartData: BarChartService): ChartData[] {
        if (chartData.dataSource && !chartData.dataCompareSource) {
            return chartData.dataSource;
        }

        const series = config.series[0];
        if (series.stacked && !series.mirror) {
            return this.prepareStackedBarData(config, chartData);
        } else if (series.mirror) {
            return this.prepareMirroredBarData(config, chartData);
        } else {
            return this.prepareRegularBarData(config, chartData);
        }
    }

    private prepareStackedBarData(config: ChartSettings, chartData: BarChartService): ChartData[] {
        const series = config.series[0];
        const xConfig = series.horizontal ? config.axis[1][0].field[0] : config.axis[0][0].field[0];
        const yConfigFieldName = series.horizontal ? series.xField[0] : series.yField[0];
        const yConfig = series.horizontal ? config.axis[0][0] : config.axis[1][0];
        const field = config.multiSeries?.field ?? '';
        // Get the correctly sorted details fields. See charts-shared.service getStackedBarDataSource
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const details: any[] = config.dataSource[0].chart_details_sort;

        const yFields = details.map((detailItem) => detailItem[field]);

        const columns = yFields.slice(0);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let preparedData: any[] = [];
        let grandTotal = 0;

        config.dataSource.forEach((dataItem) => {
            let rollup = preparedData.find((rollupItem) => rollupItem[xConfig] === dataItem[xConfig]);
            if (!rollup) {
                rollup = { [xConfig]: dataItem[xConfig], total: 0 };
                preparedData.push(rollup);
            }
            const amount = dataItem[yConfigFieldName];
            if (amount < 0) {
                config.negativeValue = true;
            }
            rollup[dataItem[field]] = amount;

            rollup.total += amount;
            grandTotal += amount;
        });

        yConfig.field = series.yField = series.horizontal ? [xConfig] : yFields;
        config.axis[0][0].field = series.xField = series.horizontal ? yFields : [xConfig];
        if (chartData.config?.enableSliceManagement) {
            preparedData = this.getStackedBarChartManagementData(chartData, preparedData, xConfig, grandTotal, columns);
        }

        config.dataSource = preparedData;
        chartData.updateColorRange(columns);
        const seriesData = columns.slice(0).map((id: string, index: number) => ({
            key: id,
            values: preparedData,
            color: chartData.getColor(id, index),
        }));
        chartData.dataSource = seriesData.slice(0);

        return chartData.dataSource;
    }

    private prepareMirroredBarData(config: ChartSettings, chartData: BarChartService): ChartData[] {
        const series = config.series[0];
        chartData.dataSource = [];
        chartData.updateColorRange(config.dataSource, true);
        if (config.dataSource.length) {
            for (let i = 0; i < series.yField.length; i++) {
                const groupMap = d3.group(config.dataSource, () => series.yField[i]);
                const data = [{ key: series.yField[i], values: groupMap.get(series.yField[i]) }]
                    .map((d) => ({ ...d, color: '', displayName: '' }));
                data.forEach((d) => {
                    d.color = chartData.color(i);
                    if (config.axis[1][0].fieldLabels) {
                        d.displayName = config.axis[1][0].fieldLabels[i];
                    }
                });
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                chartData.dataSource.push(data[0] as any);
            }
        }

        config.negativeValue = true;
        return chartData.dataSource;
    }

    private prepareRegularBarData(config: ChartSettings, chartData: BarChartService): ChartData[] {
        const series = config.series[0];
        const xConfig = series.horizontal ? config.axis[1][0].field[0] : config.axis[0][0].field[0];
        const yConfig = series.horizontal ? config.axis[0][0] : config.axis[1][0];
        const data = [...config.dataSource];
        const yField = yConfig.field[0];
        const columns = getColumns(data, xConfig);
        const isColorSorting = !!(config.selectedSlicer?.colorSortBy && config.selectedSlicer?.colorSortDirection);

        chartData.updateColorRange(columns);

        let columnsBasedOnColorSorting: string[] = [];
        let dataBasedOnColorSorting: ChartData[] = [];

        if (isColorSorting) {
            const field = config.selectedSlicer?.colorSortBy === 'value' ? yField : xConfig;
            dataBasedOnColorSorting = sortDataBasedOnColorSorting(config.selectedSlicer, data, field);
            columnsBasedOnColorSorting = dataBasedOnColorSorting.map((d) => d[xConfig]);
        }

        const seriesData = getSeriesData(
            chartData,
            isColorSorting ? columnsBasedOnColorSorting : columns,
            isColorSorting ? dataBasedOnColorSorting : data,
            xConfig,
            yField,
        );

        if (isColorSorting) {
            restoreOriginalOrderOfData(columns, seriesData);
            const colors = seriesData.map((d) => d.color);
            chartData.setColorRange(seriesData, colors, true);
        }

        chartData.dataSource = [...seriesData];

        this.configNegativeValue(config);

        if (this.isComparing) {
            const dataCompare = [...(config.dataCompareSource ?? [])];
            const columnsCompare = getColumns(dataCompare, xConfig);
            const seriesDataCompare = getSeriesData(chartData, columnsCompare, dataCompare, xConfig, yField);
            chartData.dataCompareSource = [...seriesDataCompare];
            this.configNegativeValue(config);
        }

        return (this.isComparing ? chartData.dataCompareSource : chartData.dataSource) ?? [];
    }

    private configNegativeValue(config: ChartSettings): void {
        const data = this.isComparing ? config.dataCompareSource : config.dataSource;
        const series = config.series[0];

        data?.forEach((object) => {
            const dataField = series.horizontal ? series.xField[0] : series.yField[0];
            if (object[dataField] < 0) {
                config.negativeValue = true;
            }
        });
    }

    private prepareLineData(config: ChartSettings, chartData: LineChartService): ChartData[] {
        const series = config.series[0];
        let dataSource: ChartData[] = [];
        const inputData = config.dataSource;
        if (config.showMultipleSeries) {
            const field = config.multiSeries?.field ?? '';
            const columns = getColumns(inputData, field);
            chartData.updateColorRange(columns);
            const data = inputData.slice(0);
            dataSource = this.getMultiSeriesAndStackedAreaDataSource(columns, data, field, chartData);
        } else {
            if (inputData.length) {
                chartData.updateColorRange(inputData, true);
                dataSource = this.getLineSeriesDataSource(inputData, series.yField, chartData);
            }
        }
        return dataSource;
    }

    private preparePieData(config: PieChartDataModel, chartData: PieChartService): ChartData[] {
        const baseData = [...config.source];
        let dataSource: ChartData[] = [];
        const xf = config.xf;
        const yf = config.yf;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const columns: string[] = getColumns(baseData, xf);
        const drillDownLevel: number = chartData.drillService.getLevel();
        const isColorSorting = !!(config.selectedSlicer?.colorSortBy && config.selectedSlicer?.colorSortDirection);

        const field = isColorSorting ? (config.selectedSlicer?.colorSortBy === 'value' ? yf : xf) : '';
        let columnsBasedOnColorSorting: string[] = [];
        let dataBasedOnColorSorting: ChartData[] = [];
        let attributeCustomColors: { [key: string]: string }[] | undefined;

        if (drillDownLevel) {
            const drilldownColorConfig: ColorConfig = chartData.drillService.getColorConfigForLevel(chartData.drillService.getLevel());
            const colorNameConfig = this.getColorConfigForDrilldown(drilldownColorConfig, chartData.theme);
            attributeCustomColors = drilldownColorConfig.configCustomStyles?.length ?
                drilldownColorConfig.configCustomStyles.map((customConfig) => (
                    { [customConfig.attributeValue]: customConfig.attributeColorName }
                )) :
                [];

            if (isColorSorting) {
                chartData.updateColorRange(columns);
            } else {
                chartData.setColorRange(baseData, colorNameConfig?.displayValues ?? []);
            }
        } else {
            chartData.updateColorRange(columns);
        }

        if (isColorSorting) {
            dataBasedOnColorSorting = sortDataBasedOnColorSorting(config.selectedSlicer, baseData, field);
            columnsBasedOnColorSorting = dataBasedOnColorSorting.map((d) => d[xf]);
        }

        dataSource = getSeriesData(
            chartData,
            isColorSorting ? columnsBasedOnColorSorting : columns,
            isColorSorting ? dataBasedOnColorSorting : baseData,
            xf,
            yf,
            attributeCustomColors,
            true,
        );

        if (isColorSorting) {
            restoreOriginalOrderOfData(columns, dataSource);
        }

        const levelList = chartData.drillService.levelList;
        const hasOthers = levelList?.some((level) => level.label === 'others');
        if (chartData.config?.enableSliceManagement &&
            (!hasOthers || (config.isDrillUp && levelList.filter((level) => level.label === 'others').length === 1))) {
            dataSource = this.preparePieDataWithOthersLevel(chartData, config, dataSource, xf, yf);
        }

        return dataSource;
    }

    private prepareStackedAreaData(config: ChartSettings, chartData: StackedAreaChartService): ChartData[] {
        const slicerName = config.selectedSlicer?.value ?? '';
        const valueName = config.series[0].yField[0];
        const inputData = config.dataSource;
        const originalColumnsOrder = getColumns(inputData, slicerName);
        const slicerNamesAndTotalValueSum = this.getTotalValueSumPerSlicers(inputData, slicerName, valueName);
        const sortedByTotalSumOfValues = [...slicerNamesAndTotalValueSum].sort((a, b) => Math.abs(a.total) < Math.abs(b.total) ? 1 : -1);
        const slicerColumnNames: string[] = sortedByTotalSumOfValues.map((el) => el.name).slice(0);

        chartData.updateColorRange(slicerColumnNames);

        const dataSourceSortedByTotalSumOfValues =
            this.getMultiSeriesAndStackedAreaDataSource(slicerColumnNames, inputData, slicerName, chartData);
        return this.getOriginalDataSourceOrder(originalColumnsOrder, dataSourceSortedByTotalSumOfValues);
    }

    private getStackedBarChartManagementData(
        chartData: BarChartService,
        preparedData: { [key: string]: string | number }[],
        xConfig: string,
        grandTotal: number,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        columns: any[],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): { [key: string]: string | number | any[] }[] {
        preparedData.forEach((datum: { [key: string]: string | number }, index: number) => {
            datum.id = index;
            datum.percentage = (datum.total as number) / grandTotal;
        });

        const otherData = {
            [xConfig]: 'others',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            children: [] as any[],
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let newData: { [key: string]: string | number | any[] }[];

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const pushOthers = (datum: any): void => {
            otherData.children.push(datum);
            columns.forEach((column) => {
                const safeColumn = column?.toString() || 'N/A';
                if (hasOwnProperty(otherData, safeColumn)) {
                    (otherData[safeColumn] as unknown as number) += (datum[column] as number) || 0;
                } else {
                    (otherData[safeColumn] as unknown as number) = (datum[column] as number) || 0;
                }
            });
        };

        if (chartData.config?.groupByType === SliceManagement.PERCENTAGE) {
            newData = preparedData.filter((d) => {
                if (Math.abs(d.percentage as number) > (chartData.config?.groupByValue ?? 0) / 100) {
                    if (hasOwnProperty(d, 'percentage')) {
                        delete d.percentage;
                    }
                    return true;
                }
                pushOthers(d);
                return false;
            });
        } else {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const { newData: data, others } = sliceOthers<any>(preparedData, chartData.config?.groupByValue ?? 0);
            newData = data;
            others.forEach(pushOthers);
        }

        if (otherData.children.length > 1) {
            if (chartData.config?.showOthers) {
                newData.push(otherData);
            }
            return newData;
        }
        return preparedData;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getTotalValueSumPerSlicers(dataSource: any[], slicer: string, value: string): { name: string, total: number }[] {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const slicerNamesAndTotalValueSum: any[] = [];
        dataSource.forEach((row) => {
            const columnData = slicerNamesAndTotalValueSum.find((el) => el.name === row[slicer]);
            if (columnData) {
                columnData.total += row[value];
            } else {
                slicerNamesAndTotalValueSum.push({ name: row[slicer], total: row[value] });
            }
        });

        return slicerNamesAndTotalValueSum;
    }

    private getOriginalDataSourceOrder(columnsOrder: string[], dataSource: ChartData[]): ChartData[] {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return columnsOrder.map((columnName) => dataSource.find((row) => row.key === columnName)) as any[];
    }

    private getMultiSeriesAndStackedAreaDataSource(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        columns: any[],
        data: ChartData[],
        field: string,
        chartData: LineChartService | StackedAreaChartService,
    ): ChartData[] {
        return columns.slice(0).map((id, index: number) => ({
            key: id,
            values: data.filter((d) => d[field] === id),
            color: chartData.getColor(id, index),
        })).slice(0);
    }

    private getLineSeriesDataSource(inputData: ChartData[], yField: string[], chartData: LineChartService): ChartData[] {
        const dataSource: ChartData[] = [];
        for (let i = 0; i < yField.length; i++) {
            const groupMap = d3.group(inputData, () => yField[i]);
            const data = [{ key: yField[i], values: groupMap.get(yField[i]) }]
                .map((d) => ({ ...d, color: '' }));
            data.forEach((d) => {
                d.color = chartData.color(i);
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            dataSource.push(data[0] as any);
        }
        return dataSource;
    }

    private preparePieDataWithOthersLevel(
        chartData: PieChartService,
        config: PieChartDataModel,
        dataSource: ChartData[],
        xf: string,
        yf: string,
    ): ChartData[] {
        let othersColor;
        let innerData: ChartData[] = dataSource;
        this.setPropertiesForOthers(chartData, config.source, xf, yf);
        const total = d3.sum(innerData, (d) => d[yf]);
        innerData.forEach((d, i) => {
            d.id = i;
            d.percentage = d[yf] / total;
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const otherData: any = {
            key: 'others',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            children: [] as any[],
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            values: [] as any[],
            color: chartData.color(chartData.color.length - 1),
            sum: 0,
            value: undefined,
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let newData: any[];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const pushOthers = (d: any): void => {
            otherData.children.push(d);
            otherData.sum += d[yf];
            otherData.values.push(...d.values);
            othersColor = d.color;
        };

        if (chartData.config?.groupByType === SliceManagement.PERCENTAGE) {
            newData = innerData.filter((d) => {
                if (Math.abs(d.percentage) > (chartData.config?.groupByValue ?? 0) / 100) {
                    return true;
                }
                pushOthers(d);
                return false;
            });
        } else {
            const { newData: data, others } = sliceOthers(innerData, chartData.config?.groupByValue ?? 0);
            newData = data;
            others.forEach(pushOthers);
        }

        otherData[xf] = otherData.key;
        otherData[yf] = otherData.sum;
        otherData.value = otherData;
        otherData.color = othersColor;

        if (otherData.children.length > 1) {
            newData.push(otherData);
        }

        if (newData.length === 1 && newData[0].key === 'undefined') {
            innerData = newData[0].value.children;
        } else if (otherData.children.length !== 1) {
            innerData = newData;
        }

        return innerData;
    }

    private setPropertiesForOthers(chartData: PieChartService, data: ChartData[], xf: string, yf: string): void {
        const currentLevelItem = chartData.drillService.levelList[chartData.drillService.levelList.length - 1];
        const priority = currentLevelItem ? chartData.drillService.drillConfig?.keys
            .find((key) => key.label === currentLevelItem.label) : undefined;
        const slicers = chartData.drillService.drillConfig?.keys.map((key) => key.value);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data.forEach((d) => this.transformProperties(d, slicers as any, priority as any, xf, yf));
    }

    private orderByArray<T>(values: T[], propertyNames: string[]): T[] {
        let newValues = values;
        for (let i = propertyNames.length - 1; i >= 0; i--) {
            const prop = propertyNames[i];
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            newValues = newValues.sort((a: any, b: any) => a[prop] < b[prop] ? -1 : a[prop] > b[prop] ? 1 : 0);
        }
        return newValues;
    }

    private transformProperties(
        data: ChartData,
        slicers: string[],
        priority: { label?: string, value?: string },
        xf: string,
        yf: string,
    ): void {
        if (!data) {
            return;
        }
        const sortedData = this.orderByArray(
            [...data.values],
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            priority ? [priority.label, ...(slicers.filter((slicer) => slicer !== priority.value))] : slicers as any);
        const dt = sortedData[0];
        Object.keys(dt).forEach((key) => {
            if ([xf, yf, 'color', 'values', 'key'].every((val) => key !== val)) {
                data[key] = dt[key] || 'N/A';
            }
        });
    }

    private getColorConfigForDrilldown(drilldownColorConfig: ColorConfig, theme: Theme = Theme.light): ColorInfo | undefined {
        const colorName = ColorMetadataService.mapOutdatedSolidColorName(
            ColorMetadataService.mapOutdatedMultiColorName(drilldownColorConfig.colorName ?? ''),
        );
        const colorTypeConfig = drilldownColorConfig.colorType === ColorType[ColorType.SOLID] ?
            this.colorMetadataService.getSolidColorConfig(theme) :
            this.colorMetadataService.getColorConfig()
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .filter((colorConfig) => (colorConfig.type as any) === ColorType[drilldownColorConfig.colorType as any]);
        return colorTypeConfig.find((color) => color.label === colorName);
    }
}

export function sortDataBasedOnColorSorting(slicer: Partial<ConfigItem> | undefined, data: ChartData[], field: string): ChartData[] {
    if (slicer?.colorSortDirection === 'asc') {
        return [...data].sort((a, b) => a[field] > b[field] ? 1 : -1);
    }
    return [...data].sort((a, b) => a[field] < b[field] ? 1 : -1);
}

export function getColumns(data: ChartData[], field: string): string[] {
    const columns = [];

    for (const dataItem of [...data]) {
        if (columns.indexOf(dataItem[field]) === -1) {
            columns.push(dataItem[field]);
        }
    }

    return columns;
}

export function getSeriesData(
    chartData: ColorProvider,
    columns: string[],
    data: ChartData[],
    xField: string,
    yField: string,
    attributeCustomColors?: { [key: string]: string }[] | undefined,
    isPieChart: boolean = false,
): ChartData[] {
    return [...columns].map((id: string, index: number) => {
        const values = isPieChart ?
            data.find((d) => d[xField] === id)?.values ?? [] :
            data.filter((d) => d[xField] === id);

        // this is for slice management
        let children: ChartData[] | undefined;
        if (values.length === 1) {
            children = values[0].children;
        }
        children?.forEach((child) => child.key = child[xField]);

        return {
            ...(isPieChart ? data[index] : {}),
            key: id,
            [xField]: id, // adding there here to make this look more like what they look like before going to HBar DatSource Service
            values,
            [yField]: data[index][yField],
            color: chartData.getColor(id, index, attributeCustomColors),
            children,
        };
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function restoreOriginalOrderOfData(columns: any[], data: ChartData[]): void {
    columns.forEach((column, index) => {
        if (data[index].key !== column) {
            const fieldIndex = data.findIndex((d) => d.key === column);
            if (fieldIndex !== -1) {
                data.splice(index, 0, data.splice(fieldIndex, 1)[0]);
            }
        }
    });
}
