import { Injectable } from '@angular/core';
import * as d3 from 'd3';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as d3Shape from 'd3-shape';

import { ChartData } from '../models/chart-data';
import { ChartSettings } from '../models/chart-settings';
import { ParentChartProperties } from '../models/parent-chart-properties';
import { Series } from '../models/series';
import { BaseChartService } from './base-chart.service';
import { ColorProvider } from './interfaces';
import { LineChartBrushService } from './line-chart-brush.service';

@Injectable()
export class LineChartService extends BaseChartService implements ColorProvider {
    protected brushService: LineChartBrushService | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private chartSvg: any | undefined;

    drawLineChart(config: ChartSettings, legendVisibility: 'show' | 'hide'): void {
        this.config = config;
        this.legendVisibility = legendVisibility;
        this.prepareData(config);
        this.calculateAutoDomain();
        this.initSvg();
        this.initAxis();
        this.drawAxis();
        this.drawLine();
        this.applyLineChartConfigs();
        if (this.config.showBrush) {
            this.brushService = new LineChartBrushService();
            this.brushService.addBrush(this);
        }
    }

    resizeBrush(timeline: { notation: string, scale?: number }): void {
        if (this.brushService) {
            this.brushService.resizeBrush(timeline, this);
        }
    }

    drawLine(series?: Series): void {
        let newSeries = series;
        if (!newSeries) {
            newSeries = this.config?.series[0];
        }
        this.lines = [];
        this.appendRectArea();
        (newSeries?.yField)?.forEach((yf, i) => {
            let yAxis = this.y || this.yAxisRight;

            if (this.config?.axis[1][i] &&
                this.config.axis[1][i].position === 'right') {
                yAxis = this.yAxisRight;
            }

            const line = d3Shape.line()
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .x((d: any) => this.x(d[newSeries?.xField[0] ?? '']))
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .y((d: any) => yAxis(d[yf]));

            this.removeClipPath();
            this.appendClipPath();

            this.plotLineOnAxis(newSeries!, yAxis, line, yf, i);
            this.appendLine();
            this.svg.selectAll('.rectdrawarea')
                .on('mousemove', (event: MouseEvent) => {
                    this.showTooltip(event);
                    this.showHoverLines(event);
                })
                .on('mouseout', () => {
                    this.removeTooltip();
                    this.removeHoverLines();
                })
                .on('click touchend', (event: PointerEvent) => {
                    const mousePoint = d3.pointer(event);
                    const xValue = this.x.invert(mousePoint[0]);
                    this.onChartClicked({ date: xValue }, false);
                });

            this.svg.selectAll('.line')
                .on('mousemove', (event: MouseEvent) => {
                    this.showTooltip(event);
                    this.showHoverLines(event);
                })
                .on('mouseout', () => {
                    this.removeTooltip();
                    this.removeHoverLines();
                })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .on('click touchend', (event: PointerEvent, d: any) => {
                    if (this.config?.hiddenLegends && this.config.hiddenLegends.some((legend) => legend === d.key)) {
                        return;
                    }
                    const mousePoint = d3.pointer(event);
                    d.date = this.x.invert(mousePoint[0]);
                    this.onChartClicked({ data: d }, false);
                });

            if (!this.lines) {
                this.lines = [];
            }

            this.lines.push({
                key: newSeries?.yField[i] ?? '',
                path: line,
            });
        });
    }

    override getParentChartProperties(): ParentChartProperties {
        return {
            ...super.getParentChartProperties(),
            dataSource: this.dataSource,
            lines: this.lines,
        };
    }

    private calculateAutoDomain(): void {
        const flatDataSource = this.getFlatDataSource();
        this.config?.axis[1].forEach((axis, index) => {
            if (axis.domain?.some((value) => value == null)) {
                if (axis.domain[0] == null) {
                    axis.domain[0] = this.calculateAutoDomainLowerBound(flatDataSource, index);
                }
                if (axis.domain[1] == null) {
                    axis.domain[1] = this.calculateAutoDomainUpperBound(flatDataSource, index);
                }
            }
        });
    }

    private getFlatDataSource(): { [key: string]: number }[] {
        return this.dataSource?.flatMap((datum: any) => datum.values) ?? [];
    }

    private calculateAutoDomainLowerBound(dataSource: { [key: string]: number }[], index: number): number {
        const field = this.config?.axis[1][index].field[0] ?? '';
        return dataSource.reduce((lowerBound, datum) => datum[field] < lowerBound ? datum[field] : lowerBound, Number.MAX_SAFE_INTEGER);
    }

    private calculateAutoDomainUpperBound(dataSource: { [key: string]: number }[], index: number): number {
        const field = this.config?.axis[1][index].field[0] ?? '';
        return dataSource.reduce((upperBound, datum) => datum[field] > upperBound ? datum[field] : upperBound, Number.MIN_SAFE_INTEGER);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private plotLineOnAxis(series: Series, yAxis: any, line: d3.Line<[number, number]>, key: string, count: number): void {
        if (this.config?.showMultipleSeries) {
            const g = this.svg.selectAll('.series')
                .data(this.dataSource)
                .enter()
                .append('g')
                .attr('class', 'series');

            this.chartSvg = g.append('path')
                .attr('class', () => 'line')
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .attr('d', (d: any) => line(d.values))
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .attr('data-legend', (d: any) => d.key)
                .style('clip-path', `url(#${this.config.parentSelector}-clip)`)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .style('stroke', (d: any) => d.color);

            if (this.dataCompareSource) {
                g.exit().remove()
                    .data(this.dataCompareSource)
                    .enter()
                    .append('g')
                    .attr('class', 'series-compared')
                    .style('background', 'red')
                    .append('path')
                    .attr('class', () => 'line')
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    .attr('d', (d: any) => line(d.values))
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    .attr('data-legend', (d: any) => d.key)
                    .style('clip-path', `url(#${this.config.parentSelector}-clip)`)
                    .style('stroke', 'gray');
            }

            if (this.config.legend?.inline) {
                this.createInlineLegends(g, series, key, yAxis);
            }
        } else {
            this.chartSvg = this.svg.selectAll(`.line.yAxis${count}`)
                .data(this.getData(key))
                .enter()
                .append('path')
                .attr('class', () => `line yAxis${count}`)
                .style('clip-path', `url(#${this.config?.parentSelector}-clip)`)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .attr('d', (d: any) => line(d.values))
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .attr('data-legend', (d: any) => d.key)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .style('stroke', (d: any) => d.color);
        }
    }

    private getData(key: string): ChartData[] {
        return (this.dataSource || []).filter((d) => d.key === key);
    }

    private createInlineLegends(g: any, series: Series, key: string, yAxis: any): void {
        g.append('text')
            .datum((d: any) => ({ id: d.key, value: d.values[d.values.length - 1] }))
            .attr('transform', (d: any) => `translate(${this.x(d.value[series.xField[0]])},${yAxis(d.value[key])})`)
            .attr('x', 3)
            .attr('dy', '0.35em')
            .attr('class', 'inline-legend')
            .style('font', '10px sans-serif')
            .text((d: any) => d.id);
    }

    private createTooltip(event: MouseEvent, xField: string): void {
        const mousePoint = d3.pointer(event);
        const xValue = this.x.invert(mousePoint[0]);
        const groupMap = d3.group(this.config?.dataSource ?? [], (d) => d[xField]);
        const formattedData = [{ key: xField, values: groupMap.get(xField) }];

        formattedData.sort((a, b) => d3.ascending(new Date(a.key), new Date(b.key)));

        if (this.config?.series[0].tooltipHTML) {
            const hiddenLines = this.svg.selectAll('.line.hidden').data().map((line: any) => line.key);
            const tooltipValue = {
                [xField]: xValue,
                hiddenValues: hiddenLines,
            };
            const tooltipColors = this.getTooltipColors();
            const html = this.config.series[0].tooltipHTML({ tooltipValue, tooltipColors });

            if (html.includes('tr')) {
                this.getTooltipDiv()
                    .style('display', 'block')
                    .style('left', `${event.pageX + 15}px`)
                    .style('top', `${event.pageY + 15}px`)
                    .html(html);
            } else {
                this.removeTooltip();
            }
        } else {
            this.getTooltipDiv().classed('hidden', true);
        }
    }

    private getTooltipColors(): Map<string, string> {
        return this.dataSource?.reduce((colors, datum) => colors.set(datum.key, datum.color), new Map()) ?? new Map();
    }

    private showHoverLines(event: MouseEvent): void {
        const enableAxis = { left: false, right: false };

        if (this.config?.highlightXValueOnHover || this.config?.highlightYValueOnHover) {
            const mousePoint = d3.pointer(event);

            this.config.axis[1].forEach((item) => {
                if (item.position === 'left') {
                    enableAxis.left = item.customClass !== 'hide';
                }

                if (item.position === 'right') {
                    enableAxis.right = item.customClass !== 'hide';
                }
            });

            this.changeLinePosition(!!this.config.highlightYValueOnHover, !!this.config.highlightXValueOnHover, mousePoint, enableAxis);
        }
    }

    private removeHoverLines(): void {
        if (this.config?.highlightXValueOnHover || this.config?.highlightYValueOnHover) {
            this.revertLinePosition(!!this.config.highlightYValueOnHover, !!this.config.highlightXValueOnHover);
        }
    }

    private showTooltip(event: MouseEvent): void {
        if (this.config?.showTooltip) {
            const hiddenLines = this.svg.selectAll('.line.hidden').data();

            if (hiddenLines.length < (this.dataSource?.length ?? 0)) {
                this.createTooltip(event, this.config.axis[0][0].field as any);
            }
        }
    }

    private removeTooltip(): void {
        if (this.config?.showTooltip) {
            this.getTooltipDiv().classed('hidden', true);
        }
    }

    private applyLineChartConfigs(): void {
        this.verifyHighlighting();

        if (this.config?.showCurveValues) {
            this.addInlineValues();
        }

        if (this.config?.legend?.showCustom) {
            this.legendsService.setDefaults({
                svg: this.svg,
                config: this.config,
                dataSource: this.dataSource || this.config.dataSource,
                onChartClicked: this.onChartClicked,
            });
            this.legendsService.createCustomLegends();
        }
    }

    private verifyHighlighting(): void {
        if (!this.config?.hiddenLegends || !this.config.highlight || !this.config.highlight.data ||
            !(this.config.highlight.data as any).data) {
            return;
        }

        const legends = this.dataSource?.map((legend) => legend.key);
        const hiddenLegends = this.config.hiddenLegends || [];
        const included = hiddenLegends.some((hidden) => legends?.includes(hidden));

        if (!included) {
            const selectedItem = (this.config.highlight.data as any).data.key;
            const hidden = legends?.filter((legend) => legend !== selectedItem);
            // check that not all legends are hidden
            this.config.hiddenLegends = hidden?.length !== legends?.length ? hidden : [];
        }
    }
}
