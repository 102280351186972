import { Component, OnInit, OnChanges, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DdvDate } from '@ddv/models';
import { Subscription } from 'rxjs';

import { DropdownOption } from '../dropdown/dropdown-option';
import { HiDate, HiDateStatus } from '../hi-date';
import { HiDatesService } from '../hi-dates.service';
import { CalendarOptions } from './calendar-options';
import { DateFromRange } from './date-from-range';
import { DateRange } from './date-range';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit, OnChanges, OnDestroy {
    private readonly currentDate = DdvDate.today;

    @Input() options: CalendarOptions | undefined;

    private calendarYear: number | undefined;
    selectedYear: DropdownOption | undefined;
    get year(): number | undefined{
        return this.calendarYear;
    }

    @Input()
    set year(value: number | undefined) {
        this.calendarYear = value;
        this.selectedYear = value ? { text: value.toString(), key: value, value } : undefined;
    }

    private calendarMonth: number | undefined;
    selectedMonth: DropdownOption | undefined;
    get month(): number | undefined{
        return this.calendarMonth;
    }

    @Input()
    set month(value: number | undefined) {
        this.calendarMonth = value;
        this.selectedMonth = value != null ? this.allMonths[value] : undefined;
    }

    @Input() date: DdvDate | DateRange | undefined;
    @Input() nextRangeDate: DateFromRange = 'dateFrom';

    @Input() isHIDataAvailable = false;

    @Output() dateSelected = new EventEmitter<DdvDate | DateRange>();
    @Output() errorMessageChange = new EventEmitter<string>(true);

    @Output() nextRangeDateChange = new EventEmitter<string>();
    @Output() yearMonthChange = new EventEmitter<{ year: number, month: number }>();

    allYears: DropdownOption[] = [];
    allMonths: DropdownOption[] = [];
    allDates: (number | undefined)[] = [];
    weekDays: string[] = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    hiDates: HiDate[] = [];
    relevantHiDates: HiDate[] = [];

    hiDatesSubscription: Subscription | undefined;

    constructor(private readonly hiDatesService: HiDatesService) {}

    ngOnInit(): void {
        this.populateYears();
        this.populateMonths();

        if (this.isHIDataAvailable) {
            this.hiDatesSubscription = this.hiDatesService.hiDates().subscribe((hiDates: HiDate[]) => {
                this.hiDates = hiDates;
                if (this.hiDates.length) {
                    this.populateRelevantHiDatesList(this.hiDates);
                }
            });
        }
    }

    ngOnChanges(): void {
        this.options = this.options ?? { allowPrev: true, allowNext: true, allowRange: false };
        this.year = this.year ?? (this.date ? (this.date as DdvDate).year : this.currentDate.year);
        this.month = this.month != null ? this.month : (this.date ? (this.date as DdvDate).month : this.currentDate.month);
        this.date = this.date ?? this.emptyDate();
        this.populateDates();
        if (this.options.allowRange) {
            this.checkForErrorInTheRange();
        }
        if (this.hiDates.length) {
            this.populateRelevantHiDatesList(this.hiDates);
        }
    }

    ngOnDestroy(): void {
        if (this.hiDatesSubscription) {
            this.hiDatesSubscription.unsubscribe();
        }
    }

    private populateYears(): void {
        if (!this.currentDate.year) {
            return;
        }

        const yearsFrom = this.currentDate.year - 50;
        const yearsTo = this.currentDate.year + 5;
        for (let year = yearsFrom; year <= yearsTo; year++) {
            this.allYears.push({ text: year.toString(), key: year, value: year });
        }
    }

    private populateMonths(): void {
        if (typeof this.calendarMonth !== 'number') {
            return;
        }

        this.allMonths = DdvDate.monthsShort.map((monthName, i) => ({ text: monthName, key: i, value: i }));
        this.selectedMonth = this.allMonths[this.calendarMonth];
    }

    private emptyDate(): DateRange | undefined {
        return this.options?.allowRange ? { dateFrom: undefined, dateTo: undefined } : undefined;
    }

    private populateDates(): void {
        this.allDates = [];
        const daysInMonth = new DdvDate(this.year, this.month, 1).endOfMonth().day ?? 0;

        const firstDayOfMonth = new DdvDate(this.year, this.month, 1).dayOfTheWeek;
        for (let i = 0; i < firstDayOfMonth; i++) {
            this.allDates.push(undefined);
        }

        for (let date = 1; date <= daysInMonth; date++) {
            this.allDates.push(date);
        }
    }

    addMonths(monthsToAdd: number): void {
        if (!this.year || typeof this.month !== 'number') {
            return;
        }

        const date = new Date(this.year, this.month + monthsToAdd);
        this.month = date.getMonth();
        this.year = date.getFullYear();
        this.populateDates();
        this.emitYearMonthChange();
    }

    selectMonth(month: number): void {
        if (!this.year) {
            return;
        }

        const date = new Date(this.year, month);
        this.month = date.getMonth();
        this.populateDates();
        this.emitYearMonthChange();
    }

    selectYear(year: number): void {
        if (typeof this.month !== 'number') {
            return;
        }

        const date = new Date(year, this.month);
        this.calendarYear = date.getFullYear();
        this.populateDates();
        this.emitYearMonthChange();
    }

    private emitYearMonthChange(): void {
        if (!this.year || typeof this.month !== 'number') {
            return;
        }

        this.yearMonthChange.emit({ year: this.year, month: this.month });
    }

    selectDate(date: number | null | undefined): void {
        if (date == null) {
            return;
        }

        const selectedDate = new DdvDate(this.year, this.month, date);

        if (!this.options?.allowRange) {
            this.date = selectedDate;
            this.dateSelected.emit(this.date);
        } else {
            this.date = this.date as DateRange;

            if (this.nextRangeDate === 'dateFrom') {
                this.date.dateFrom = selectedDate;
            } else {
                this.date.dateTo = selectedDate;
            }

            this.checkForErrorInTheRange();
            this.dateSelected.emit(this.date);
            this.swapNextRangeDate();
        }
    }

    private checkForErrorInTheRange(): void {
        const errorMessage = !this.isRangeValid() ? 'From Date Should Not Be Greater Than To Date' : '';
        this.errorMessageChange.emit(errorMessage);
    }

    private swapNextRangeDate(): void {
        this.nextRangeDate = this.nextRangeDate === 'dateFrom' ? 'dateTo' : 'dateFrom';
        this.nextRangeDateChange.emit(this.nextRangeDate);
    }

    private isRangeValid(): boolean {
        this.date = this.date as DateRange;
        return !this.date.dateTo || !this.date.dateFrom || this.date.dateFrom.toDate() <= this.date.dateTo.toDate();
    }

    isSelected(calendarDateNumber: number | undefined): boolean {
        const calendarDate = new DdvDate(this.year, this.month, calendarDateNumber).toMilliseconds();

        let selectedDate: DdvDate | undefined;
        if (this.options?.allowRange) {
            const dateRange = this.date as DateRange;
            selectedDate = !dateRange.dateFrom ?
                new DdvDate(this.currentDate.year, this.currentDate.month, this.currentDate.day) :
                (!this.isRangeValid() ? dateRange.dateFrom : undefined);
        } else {
            selectedDate = (this.date as DdvDate) || new DdvDate(this.currentDate.year, this.currentDate.month, this.currentDate.day);
        }

        return !!selectedDate && calendarDate === selectedDate.toMilliseconds();
    }

    isInRange(date?: number): boolean {
        if (!this.options?.allowRange) {
            return false;
        }

        const range = this.date as DateRange;
        if (!range.dateFrom) {
            return false;
        }

        const calendarDate = new DdvDate(this.year, this.month, date).toMilliseconds();
        return !range.dateTo ? range.dateFrom.toMilliseconds() === calendarDate :
            calendarDate >= range.dateFrom.toMilliseconds() && calendarDate <= range.dateTo.toMilliseconds();
    }

    isWeekend(date: number | undefined, index: number): boolean {
        return !!date && (index === 0 || (index + 1) % 7 === 0 || index % 7 === 0);
    }

    isRangeBoundary(date: number | undefined, boundary: 'start' | 'end'): boolean {
        if (!this.options?.allowRange) {
            return false;
        }

        const range = this.date as DateRange;
        if (!range?.dateFrom) {
            return false;
        }

        const tempDate = new DdvDate(this.year, this.month, date).toMilliseconds();
        const rangeDate = boundary === 'start' ? range.dateFrom : range.dateTo;

        return !!rangeDate && rangeDate.toMilliseconds() === tempDate && this.isRangeValid();
    }

    checkForHIDate(date?: number): string | undefined{
        let hiDateStatus: string | undefined;

        if (this.relevantHiDates.length) {
            this.relevantHiDates.forEach((hiDate: HiDate) => {
                if (hiDate.date.day === date) {
                    hiDateStatus = hiDate.status === HiDateStatus.ALL ? 'all' : hiDate.status === HiDateStatus.PARTIAL ? 'partial' : 'none';
                }
            });
        }

        return hiDateStatus;
    }

    private populateRelevantHiDatesList(hiDates: HiDate[]): void {
        this.relevantHiDates = [];

        hiDates.forEach((hiDate: HiDate) => {
            if (hiDate.date.year === this.year && hiDate.date.month === this.month) {
                this.relevantHiDates.push(hiDate);
            }
        });
    }
}
