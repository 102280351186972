import {
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ElementRef,
} from '@angular/core';
import { ConfirmationPopupService, ModalDialogActive, MultiSubscriptionComponent } from '@ddv/common-components';
import { DashboardGroup, DashboardGroupsService, DashboardService } from '@ddv/dashboards';
import { Entitlements, FeatureFlagService, UserEntitlementService } from '@ddv/entitlements';
import { DashboardSnapshot, getDeleteDashboardDialogOptions } from '@ddv/models';
import { combineLatest, concat, Observable } from 'rxjs';

@Component({
    selector: 'app-dashboard-finder',
    templateUrl: 'dashboard-finder.component.html',
    styleUrls: ['dashboard-finder.component.scss'],
})
export class DashboardFinderComponent extends MultiSubscriptionComponent implements OnInit, OnDestroy {
    searchParam = '';
    filteredDashboardGroupsList: DashboardGroup[] = [];
    isFiltered = false;
    isApplyButtonEnabled = false;
    showDetails: Record<string, boolean> = {};
    canManageViewGroups = false;
    showSelectedViews = false;
    protected changedDashboardGroupList: DashboardGroup[] = [];
    protected isLoading = false;
    protected showFamilyToggle: Record<string, boolean> = {};
    protected dashboardGroupsList: DashboardGroup[] = [];
    protected canDeleteDashboard = false;
    protected showCreateNewViewButton = false;

    constructor(
        private readonly activeModal: ModalDialogActive,
        private readonly dashboardService: DashboardService,
        private readonly dashboardGroupsService: DashboardGroupsService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly userEntitlementsService: UserEntitlementService,
        private readonly el: ElementRef,
        private readonly confirmationService: ConfirmationPopupService,
        private readonly featureFlagService: FeatureFlagService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.getAllDashboards();

        this.subscribeTo(this.userEntitlementsService.entitlementsForClientCode$, (entitlements) => {
            this.canManageViewGroups = entitlements?.hasPermission(Entitlements.VIEW_GROUP_MANAGE);
            this.canDeleteDashboard = entitlements?.hasPermission(Entitlements.DASHBOARD_DELETE);
        });

        this.subscribeTo(
            this.featureFlagService.isFlagEnabled('allow-access-new-widget-flow'),
            (isEnabled) => {
                this.showCreateNewViewButton = isEnabled;
            });
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.dashboardGroupsList = [];
        this.changedDashboardGroupList = [];
        this.showDetails = {};
    }

    filterSearchResults(): void {
        this.isFiltered = false;
        this.searchParam = this.searchParam ? this.searchParam.trim() : '';

        let dashboardGroups = this.dashboardGroupsList.map((dashboardGroup) => {
            const dashboards = dashboardGroup.dashboards.map((dashboard) => new DashboardSnapshot(dashboard));
            return this.createDashboardGroup(
                dashboardGroup.id,
                dashboardGroup.name,
                dashboardGroup.sortOrder,
                !!dashboardGroup.isDefault,
                dashboards);
        }) || [];

        this.isFiltered = true;
        dashboardGroups = dashboardGroups.filter((dashboardGroup) => {
            if (dashboardGroup.dashboards.length) {
                return dashboardGroup;
            }

            return;
        });

        if (this.searchParam) {
            this.isFiltered = true;
            const normalizedSearchTerm = this.searchParam.toLowerCase();
            dashboardGroups = dashboardGroups.filter((dashboardGroup) => {
                dashboardGroup.dashboards = dashboardGroup.dashboards.filter((dashboard) => {
                    return dashboard.name?.toLowerCase().includes(normalizedSearchTerm) ||
                        dashboard.dashboardTags.some((tag) => tag.name.toLowerCase().includes(normalizedSearchTerm)) ||
                        ('global'.includes(normalizedSearchTerm) && dashboard.isGlobal);
                });

                if (dashboardGroup.dashboards.length) {
                    return dashboardGroup;
                }

                return;
            });
        }

        this.filteredDashboardGroupsList = dashboardGroups;
        this.filterBy();
    }

    removeDashboard(dashboardId: string | number | undefined): void {
        if (!dashboardId) {
            return;
        }

        const dashboardIdAsString = dashboardId.toString();
        let snapshot: DashboardSnapshot | undefined;
        this.dashboardGroupsList.forEach((dashboardGroup) => {
            const db = dashboardGroup.dashboards.find((dashboard) => dashboard.id?.toString() === dashboardIdAsString);
            if (db) {
                snapshot = db;
                return;
            }
        });

        if (!snapshot) {
            return;
        }

        const confirmDialogOptions = getDeleteDashboardDialogOptions(!!snapshot.isShared);
        this.confirmationService.showConfirmationPopup(confirmDialogOptions).subscribe({
            next: (action) => {
                if (action === 'confirm') {
                    this.onDashboardRemoval(dashboardIdAsString, snapshot!);
                }
            },
        });
    }

    findCurrentDashboardGroup(name: string): DashboardGroup | undefined {
        return this.dashboardGroupsList.find((dashboardGroup) => dashboardGroup.name === name);
    }

    changeDashboardFamily(
        { oldDbFamily, newDbFamily, dashboard }: { oldDbFamily: DashboardGroup, newDbFamily: DashboardGroup, dashboard: DashboardSnapshot },
    ): void {
        const dbIndex = oldDbFamily.dashboards.findIndex((db) => db.id === dashboard.id);
        oldDbFamily.dashboards.splice(dbIndex, 1);
        newDbFamily.dashboards.push(dashboard);
        newDbFamily.dashboards.sort((dbA, dbB) => dbA.name!.localeCompare(dbB.name!));

        this.fillChangedGroupList(oldDbFamily);
        this.fillChangedGroupList(newDbFamily);

        const oldDbFamilyHasDefaultViews = oldDbFamily.dashboards.some((db) => db.isDefault);

        if (oldDbFamily.isDefault && (oldDbFamily.dashboards.length === 0 || !oldDbFamilyHasDefaultViews)) {
            oldDbFamily.isDefault = false;
        }

        if (!newDbFamily.isDefault) {
            newDbFamily.isDefault = true;
        }

        this.filterSearchResults();
    }

    toggleDetails(id?: string): void {
        if (!id) {
            return;
        }
        this.showDetails[id] = !this.showDetails[id];
    }

    toggleDefault(dashboard: DashboardSnapshot, dashboardGroup: DashboardGroup): void {
        this.changeDefault(dashboard, !dashboard.isDefault, dashboardGroup);

        if (dashboardGroup.name) {
            if (dashboardGroup.dashboards.every((db) => !db.isDefault)) {
                this.onGroupDefaultToggle(dashboardGroup, false);
            } else if (dashboardGroup.dashboards.some((db) => db.isDefault) && !dashboardGroup.isDefault) {
                this.onGroupDefaultToggle(dashboardGroup, true);
            }
        }
    }

    toggleGroupDefault(dashboardGroup: DashboardGroup): void {
        this.onGroupDefaultToggle(dashboardGroup, !dashboardGroup.isDefault);
        dashboardGroup.dashboards.forEach((dashboard) => {
            this.changeDefault(dashboard, !!dashboardGroup.isDefault, dashboardGroup);
        });
    }

    toggleFamilyModal(id?: string): void {
        if (!id) {
            return;
        }
        const element = this.el.nativeElement.querySelector(`.view-${id}`);
        element.scrollIntoView({ behavior: 'smooth' });
        this.showFamilyToggle[id] = !this.showFamilyToggle[id];
    }

    onCancel(): void {
        this.activeModal.close();
    }

    onApply(): void {
        this.isApplyButtonEnabled = false;
        const updatedDashboardGroups: Observable<DashboardGroup>[] = [];
        this.changedDashboardGroupList.forEach((changedDashboardGroup) => {
            if (changedDashboardGroup.id && changedDashboardGroup.dashboards.length) {
                updatedDashboardGroups.push(this.dashboardGroupsService.updateDashboardGroup(changedDashboardGroup));
            } else if (changedDashboardGroup.id && changedDashboardGroup.dashboards.length === 0) {
                updatedDashboardGroups.push(this.dashboardGroupsService.deleteDashboardGroup(changedDashboardGroup.id));
            } else if (!changedDashboardGroup.id && changedDashboardGroup.dashboards.length) {
                updatedDashboardGroups.push(this.dashboardGroupsService.addDashboardGroup(changedDashboardGroup));
            }
        });

        concat(...updatedDashboardGroups).subscribe(
            (group: DashboardGroup) => {
                if (group) {
                    if (this.dashboardGroupsList.findIndex((dbGroup) => !dbGroup.id && dbGroup.name === group.name)) {
                        const g = this.dashboardGroupsList.find((dbGroup) => dbGroup.name === group.name);
                        if (g) {
                            g.id = group.id;
                        }
                    }
                    this.onGroupDefaultToggle(group, !!group.isDefault);
                }
            },
            () => { this.isApplyButtonEnabled = true; this.dashboardService.refetchClientLevelUserData(); },
            () => {
                this.activeModal.close();
                this.dashboardService.refetchClientLevelUserData();
                this.dashboardGroupsService.refetchDashboardGroups();
            });
    }

    toggleSelectedViews(areSelectedViewsToggled: boolean): void {
        this.showSelectedViews = areSelectedViewsToggled;
        this.filterSearchResults();
    }

    filterBy(): void {
        if (this.showSelectedViews) {
            this.filterByFavorites(this.filteredDashboardGroupsList);
        } else {
            this.filterAlphabetically(this.filteredDashboardGroupsList);
        }
    }

    filterAlphabetically(dbgList: DashboardGroup[]): void {
        dbgList.forEach((dashboardGroup) => {
            dashboardGroup.dashboards.sort((dbA, dbB) => {
                return dbA.name!.localeCompare(dbB.name!);
            });
        });
    }

    filterByFavorites(dbgList: DashboardGroup[]): void {
        this.filteredDashboardGroupsList = dbgList.filter((dashboardGroup) => {
            dashboardGroup.dashboards = dashboardGroup.dashboards.filter((dashboard) => dashboard.isDefault);
            if (dashboardGroup.dashboards.length) {
                return dashboardGroup;
            }

            return;
        });
    }

    private getAllDashboards(): void {
        this.isLoading = true;
        combineLatest([this.dashboardGroupsService.getDashboardGroups(), this.dashboardService.getAllDashboardsForUser()])
            .subscribe(([dashboardGroups, dashboardSnapshots]) => {
                const dashboardsInGroups: Set<string> = new Set();
                const groupDashboards: DashboardSnapshot[] = [];

                dashboardGroups.forEach((group) => {
                    group.dashboards.forEach((db) => {
                        dashboardsInGroups.add(db.id ?? '');
                        groupDashboards.push(db);
                    });
                });

                dashboardSnapshots.forEach((dashboard) => {
                    this.showDetails[dashboard.id ?? ''] = false;
                    this.showFamilyToggle[dashboard.id ?? ''] = false;
                });

                const nonGroupDashboards = dashboardSnapshots.filter((dashboard) => {
                    if (dashboardsInGroups.has(dashboard.id ?? '')) {
                        dashboardsInGroups.delete(dashboard.id ?? '');
                        const g = groupDashboards.find((db) => db.id === dashboard.id);
                        if (g) {
                            g.isDefault = dashboard.isDefault;
                        }
                    } else {
                        return dashboard;
                    }

                    return;
                });

                // The following lines are here, because we sometimes receive wrong dashboards in groups from the
                // backend, so we have to filter them somehow.
                // Once we fix the backend issue, we would not need this code.
                dashboardGroups.forEach((dbGroup) => {
                    dbGroup.dashboards = dbGroup.dashboards.filter((dashboard) => {
                        if (!dashboardsInGroups.has(dashboard.id ?? '')) {
                            return dashboard;
                        } else {
                            dashboardsInGroups.delete(dashboard.id ?? '');
                        }
                        return;
                    });
                });

                this.dashboardGroupsList = dashboardGroups.length > 1 ?
                    [...dashboardGroups.sort((dbgA, dbgB) => dbgA.name.localeCompare(dbgB.name))] :
                    [...dashboardGroups];

                this.dashboardGroupsList.forEach((dbGroup) => {
                    const hasDefaultViews = dbGroup.dashboards.some((dashboard) => dashboard.isDefault);

                    if (dbGroup.isDefault && !hasDefaultViews) {
                        this.onGroupDefaultToggle(dbGroup, false);
                    } else if (!dbGroup.isDefault && hasDefaultViews) {
                        this.onGroupDefaultToggle(dbGroup, true);
                    }
                });

                if (nonGroupDashboards.length) {
                    const otherDashboardsGroup = this.createDashboardGroup(0, '', -1, false, nonGroupDashboards);
                    this.dashboardGroupsList.push(otherDashboardsGroup);
                }

                this.filterSearchResults();
                this.isLoading = false;
            });
    }

    private onDashboardRemoval(dashboardId: string, snapshot: DashboardSnapshot): void {
        this.isLoading = true;
        this.filteredDashboardGroupsList = [];
        this.changeDetectorRef.detectChanges();

        this.dashboardService.removeDashboard(dashboardId).subscribe(() => {
            this.dashboardService.updateDashboardList(snapshot, 'remove');
            this.getAllDashboards();
        });
    }

    private createDashboardGroup(
        id: number,
        name: string,
        sortOrder: number,
        isDefault: boolean,
        dashboards: DashboardSnapshot[],
    ): DashboardGroup {
        return new DashboardGroup({ id, name, sortOrder, isDefault, dashboards });
    }

    private fillChangedGroupList(family: DashboardGroup): void {
        if (family.name) {
            const index = this.changedDashboardGroupList.findIndex((group) => group.name === family.name);
            if (index !== -1) {
                this.changedDashboardGroupList.splice(index, 1, family);
            } else {
                this.changedDashboardGroupList.push(family);
            }
        }
    }

    private changeDefault(dashboard: DashboardSnapshot, isDefault: boolean, dashboardGroup: DashboardGroup): void {
        this.dashboardService.toggleDefault(dashboard, !isDefault).subscribe(() => {
            this.dashboardService.refetchClientLevelUserData();
        });
        dashboard.isDefault = isDefault;
        const g = this.dashboardGroupsList.find((group) => group.name === dashboardGroup.name);
        const d = g?.dashboards.find((db) => db.id === dashboard.id);
        if (d) {
            d.isDefault = isDefault;
        }
    }

    private onGroupDefaultToggle(dashboardGroup: DashboardGroup, isDefault: boolean): void {
        dashboardGroup.isDefault = isDefault;
        const g = this.dashboardGroupsList.find((group) => group.name === dashboardGroup.name);
        if (g) {
            g.isDefault = isDefault;
        }
        if (dashboardGroup.id) {
            this.dashboardGroupsService.toggleDefault(dashboardGroup.id, dashboardGroup.isDefault).subscribe(() => {
                this.dashboardService.refetchClientLevelUserData();
            });
        }
    }
}
