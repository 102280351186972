import {
    Component,
    ComponentRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { ManagerService, WorkspaceSelection } from '@ddv/layout';
import { WIDGET_LIFECYCLE_EVENT, Widget } from '@ddv/models';
import { Subscription } from 'rxjs';

import { WorkspaceComponent } from '../workspace/workspace.component';

@Component({
    selector: 'app-dashboard',
    template: '<div #workspaceContainer></div>',
})
export class DashBoardComponent implements OnInit, OnChanges, OnDestroy {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private static workspaceComponentRefs: { [selector: string]: ComponentRef<any> } = {};

    @Input() isManagingWidget = false;
    @Input() isGlobal = false;

    private workspaceSelectionObserver: Subscription | undefined;
    private currentWSWidgetsObserver: Subscription | undefined;

    @ViewChild('workspaceContainer', { read: ViewContainerRef, static: true }) container: ViewContainerRef | undefined;

    currentWorkspaceComponentRef: ComponentRef<WorkspaceComponent> | undefined;

    constructor(private readonly manager: ManagerService) {}

    ngOnInit(): void {
        this.workspaceSelectionObserver = this.manager.workspaceSelection
            .subscribe((workspaceSelection: WorkspaceSelection) => {
                if (workspaceSelection) {
                    const fromWSId = workspaceSelection.previousWorkspace?.id ?? '';
                    if (workspaceSelection.currentWorkspace) {
                        const toWSId = workspaceSelection.currentWorkspace.id;
                        this.renderWorkspace(fromWSId, toWSId);
                    } else {
                        const fromWorkspaceComponentRef = DashBoardComponent.workspaceComponentRefs[fromWSId];
                        if (fromWorkspaceComponentRef) {
                            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                            delete DashBoardComponent.workspaceComponentRefs[fromWSId];
                            this.container?.remove();
                        }
                    }
                }
            });

        this.currentWSWidgetsObserver = this.manager.currentWorkspaceWidgets.subscribe((widgets: Widget[]) => {
            this.updateWorkspaceWidgets(widgets);
        });
    }

    ngOnChanges(): void {
        this.updateWorkspaceIsGlobalProperty();
    }

    ngOnDestroy(): void {
        this.workspaceSelectionObserver?.unsubscribe();
        this.currentWSWidgetsObserver?.unsubscribe();
        DashBoardComponent.workspaceComponentRefs = {};
    }

    private renderWorkspace(fromWorkspaceId: string, toWorkspaceId: string): void {
        const toWorkspaceComponentRef = DashBoardComponent.workspaceComponentRefs[toWorkspaceId];
        if (fromWorkspaceId) {
            const fromWorkspaceComponentRef = DashBoardComponent.workspaceComponentRefs[fromWorkspaceId];
            if (fromWorkspaceComponentRef) {
                this.triggerLifeCycleEventForWidgets(
                    WIDGET_LIFECYCLE_EVENT.WIDGET_PASSIVE_MODE,
                    fromWorkspaceComponentRef.instance.widgets);
                this.container?.detach();
            }
        }
        if (!toWorkspaceComponentRef) {
            this.container?.detach();
            this.createWorkspace();
            DashBoardComponent.workspaceComponentRefs[toWorkspaceId] = this.currentWorkspaceComponentRef!;
        } else {
            this.container?.insert(toWorkspaceComponentRef.hostView);
            this.currentWorkspaceComponentRef = DashBoardComponent.workspaceComponentRefs[toWorkspaceId];
            this.triggerLifeCycleEventForWidgets(
                WIDGET_LIFECYCLE_EVENT.WIDGET_ACTIVE_MODE,
                this.currentWorkspaceComponentRef.instance.widgets);
        }
    }

    private createWorkspace(widgets: Widget[] = []): void {
        if (!this.container) {
            return;
        }

        this.currentWorkspaceComponentRef = this.container.createComponent(WorkspaceComponent);
        this.currentWorkspaceComponentRef.instance.isManagingWidget = this.isManagingWidget;
        this.updateWorkspaceWidgets(widgets);
        this.updateWorkspaceIsGlobalProperty();
    }

    private updateWorkspaceWidgets(widgets: Widget[]): void {
        if (this.currentWorkspaceComponentRef) {
            this.currentWorkspaceComponentRef.instance.widgets = widgets;
        }
    }

    private updateWorkspaceIsGlobalProperty(): void {
        if (this.currentWorkspaceComponentRef) {
            this.currentWorkspaceComponentRef.instance.isGlobal = this.isGlobal;
        }
    }

    resetCurrentWorkspace(): void {
        if (!this.currentWorkspaceComponentRef) {
            return;
        }

        this.currentWorkspaceComponentRef.instance.prepareToReinitializeWidgets();
    }

    private triggerLifeCycleEventForWidgets(eventName: string, widgets: Widget[]): void {
        (widgets || []).filter((w) => !!w.lifeCycleCallBack).forEach((w) => {
            w.lifeCycleCallBack?.(eventName);
        });
    }
}
