import { UserDefinedField, WidgetData } from '@ddv/models';

export class ConversableType {
    asciiOnly = false;
    maxCommentLength = 0;
    id = '';
    type = '';
    displayName = '';
    fields: string[] = [];
    createdBy = '';
    created: Date = new Date();
    updated: Date = new Date();
    displayFields: string[] = [];
    userDefinedFields: UserDefinedField[] = [];

    constructor(init?: Partial<ConversableType>) {
        Object.assign(this, init);

        if (this.created) {
            this.created = new Date(this.created);
        }

        if (this.updated) {
            this.updated = new Date(this.updated);
        }
    }

    liftKeys(row: WidgetData): string[] {
        return this.fields.map((field) => row[field] as string);
    }

    getUserDefinedFields(): UserDefinedField[] {
        if (this.userDefinedFields?.length) {
            return this.userDefinedFields.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        } else {
            return [];
        }
    }
}
