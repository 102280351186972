import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DataGridModule } from '@ddv/data-grid';
import { HttpModule } from '@ddv/http';
import { CcDatesModule } from '@hs/ui-core-date';
import { CcDropdownsModule } from '@hs/ui-core-dropdowns';
import { CcInputsModule } from '@hs/ui-core-inputs';
import { CcLayoutModule } from '@hs/ui-core-layout';

import { IconsModule } from '../icons/icons.module';
import { AttachmentsCellRendererComponent } from './attachments-cell-renderer/attachments-cell-renderer.component';
import { FileDownloaderService } from './attachments-cell-renderer/file-downloader.service';
import { AuthorCellRendererComponent } from './author-cell-renderer/author-cell-renderer.component';
import { CommentCellRendererComponent } from './comment-cell-renderer/comment-cell-renderer.component';
import { CrosstalkCommentCounterComponent } from './crosstalk-comment-counter/crosstalk-comment-counter.component';
import {
    PostRealtimeCommentUpdateNotificationService,
} from './crosstalk-comment-counter/post-realtime-comment-update-notification.service';
import { CrosstalkCustomCheckboxComponent } from './crosstalk-custom-checkbox/crosstalk-custom-checkbox.component';
import {
    CrosstalkCustomHeaderCheckboxComponent,
} from './crosstalk-custom-header-checkbox/crosstalk-custom-header-checkbox.component';
import {
    UserDefinedFieldCellRendererComponent,
} from './user-defined-field-cell-renderer/user-defined-field-cell-renderer.component';

@NgModule({
    imports: [
        CcDatesModule,
        CcDropdownsModule,
        CcInputsModule,
        CcLayoutModule,
        CommonModule,
        DataGridModule,
        HttpModule,
        IconsModule,
    ],
    declarations: [
        AttachmentsCellRendererComponent,
        AuthorCellRendererComponent,
        CommentCellRendererComponent,
        CrosstalkCommentCounterComponent,
        CrosstalkCustomCheckboxComponent,
        CrosstalkCustomHeaderCheckboxComponent,
        UserDefinedFieldCellRendererComponent,
    ],
    exports: [
        AttachmentsCellRendererComponent,
        AuthorCellRendererComponent,
        CommentCellRendererComponent,
        CrosstalkCommentCounterComponent,
        CrosstalkCustomCheckboxComponent,
        CrosstalkCustomHeaderCheckboxComponent,
        UserDefinedFieldCellRendererComponent,
    ],
    providers: [
        FileDownloaderService,
        PostRealtimeCommentUpdateNotificationService,
    ],
})
export class CellRenderersModule {}
