import { Injectable } from '@angular/core';
import * as d3 from 'd3';

import { BarChartData } from '../../models/chart-data';
import { Margin } from '../../models/margin';
import { getDefaultMargin, getStringValueWithLongestCharacterCount } from '../../services/base-chart.service';
import { hasOwnProperty } from '../../utils';
import { distinctSlicerValues, maxNumberOfSlicerCharactersToDisplayForAGivenChartWidth, substr } from './utils';

export const minCharactersToConsider = 16;

export interface ChartPosition {
    // this is adjusted for the left margin and the right legend (if there is one)
    widthForBars: number;
    // this is adjusted for both the top and bottom margins
    heightForBars: number;
    width: number;
    height: number;
    margin: Margin;
    // this feels a little out of place, but I think is better than exposing thing we don't want
    slicerMaxCharacterCount: number;
}

@Injectable()
export class HorizontalBarChartPositionService {
    build(
        wrapper: SVGElement,
        data: BarChartData[],
        compareData: BarChartData[] | undefined,
        // this is false when either there is no axis, or there is but it is configured not to show labels
        isDisplayingLabelsOnSlicerAxis: boolean,
        // this function updates the margin object that is passed in from the outside
        previousMargin: Margin = getDefaultMargin(),
        isMaximized: boolean = false,
        width: number,
        height: number,
        legendPosition: string | undefined,
        slicerAxisPosition: string | undefined,
    ): ChartPosition {
        const slicerMaxCharacterCount = maxNumberOfSlicerCharactersToDisplayForAGivenChartWidth(minCharactersToConsider, width);

        if (isDisplayingLabelsOnSlicerAxis) {
            calculateLeftMarginFromLargestSlicerValue(wrapper, data, compareData, previousMargin, isMaximized, slicerMaxCharacterCount);
        }

        // this copies the values from the passed in margin (which was modified above) to the local copy
        const margin = copyValuesFromPotentiallyPartialMarginObjectToRealMarginObject(previousMargin);

        const widthForBars = calculateWidthLeftAfterAxisAndLegend(
            width,
            legendPosition,
            slicerAxisPosition,
            margin);

        margin.right = rightMarginForBarWidth(widthForBars);

        const chartOffsets = getChartOffsets(margin);
        return {
            widthForBars,
            width,
            heightForBars: height - chartOffsets.y,
            height,
            margin,
            slicerMaxCharacterCount,
        };
    }
}

function getChartOffsets(margin: Margin): { x: number, y: number } {
    return {
        x: margin.left + margin.right,
        y: margin.top + margin.bottom,
    };
}

function rightMarginForBarWidth(calculatedWidth: number): number {
    return calculatedWidth > 350 ? 35 : (calculatedWidth > 250 ? 25 : 15);
}

function calculateLeftMarginFromLargestSlicerValue(
    wrapper: SVGElement,
    data: BarChartData[],
    compareData: BarChartData[] | undefined,
    margin: Margin,
    isMaximized: boolean,
    slicerMaxCharacterCount: number,
): void {
    const longestSlicerValue = getStringValueWithLongestCharacterCount(distinctSlicerValues(data, compareData)).toString() || 'Blanks';
    let longestSlicerValueAdjustedForChartStateAndWidth = longestSlicerValue;

    if (!isMaximized && longestSlicerValue.length > minCharactersToConsider) {
        longestSlicerValueAdjustedForChartStateAndWidth = substr(longestSlicerValue, 0, slicerMaxCharacterCount);
    }

    // i have no idea why this check exists.  i don't think it's possible to end here with an empty string
    // if (!truncatedSlicerValue) {
    //     return;
    // }

    const longestTextLabel = d3.select(wrapper)
        .append('text')
        .attr('class', 'tick text tickLabel')
        .classed('hidden', true)
        .text(longestSlicerValueAdjustedForChartStateAndWidth);

    // this is the width as actually rendered by the browser so would account for things like font size
    const longestTextLabelWidth = (longestTextLabel.node()?.getBoundingClientRect().width ?? 0) + 1;
    margin.left += longestTextLabelWidth;

    longestTextLabel.remove();
}

// this function assumes that the margin passed in might actually be a partial even though the type says it's not
function copyValuesFromPotentiallyPartialMarginObjectToRealMarginObject(margin: Margin | undefined): Margin {
    const realMarginObject: Margin = getDefaultMargin();
    if (!margin) {
        return realMarginObject;
    }

    for (const key in realMarginObject) {
        if (hasOwnProperty(realMarginObject, key)) {
            realMarginObject[key as keyof Margin] = margin[key as keyof Margin];
        }
    }
    return realMarginObject;
}

function calculateWidthLeftAfterAxisAndLegend(
    width: number,
    legendPosition: string | undefined,
    slicerAxisPosition: string | undefined,
    margin: Margin,
): number {
    const hasLeftAxis = slicerAxisPosition === 'left';
    const hasRightLegend = legendPosition === 'right';

    // remove the space taken by the legend and the axis, leaving only what should be used by the chart itself
    return (hasRightLegend ? width * 0.8 : width) - (hasLeftAxis ? margin.left : 0);
}
