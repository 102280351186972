import { Inject, Injectable } from '@angular/core';
import { CurrentStateService, RealtimeActiveService } from '@ddv/behaviors';
import { AlertService } from '@ddv/common-components';
import { DatasetDefinitionsService } from '@ddv/dataset-definitions';
import { ApiExecutorService, ApiServices } from '@ddv/http';
import { ManagerService } from '@ddv/layout';
import {
    Client,
    DashboardDetails,
    DashboardModel,
    DashboardSnapshot,
    DashboardPreference,
    toDefaultQueryParams,
    toPrioritizedFilterParams,
    WidgetOnBoard,
    UserPreferences,
    ExportDatasetInfo,
    TExportFormat,
    FilterQueryParam,
    Fund,
    Tag,
    AppWidgetState,
    DashboardFilter,
    DatasetDefinitionDetails,
    NamedQuery,
    DatasetDefinition,
} from '@ddv/models';
import { NamedQueriesService } from '@ddv/named-queries';
import { ClientsService, FundsService, FuzzyDatesService } from '@ddv/reference-data';
import { ServerSocketService } from '@ddv/socket';
import { DashboardDetailsRelayService } from '@ddv/visualizations';
import { WidgetExportService } from '@ddv/widgets';
import { Observable, ReplaySubject, Subject, of, firstValueFrom, combineLatest } from 'rxjs';
import { map, mergeMap, take, catchError, switchMap } from 'rxjs/operators';

import { LastState, SaveDashboard } from '../models/dashboard';

@Injectable()
export class DashboardService {
    public readonly dashboardSnapshots$: Observable<DashboardSnapshot[]>;
    public readonly widgetAddedToBoard: Observable<void>;
    public readonly widgetRemovedFromBoard: Observable<void>;
    public readonly datePickerInfo: Observable<DatePickerInfo>;

    private currentDashboardId: string | number | undefined;
    private readonly dashboardSnapshotsSubject: Subject<DashboardSnapshot[]> = new ReplaySubject<DashboardSnapshot[]>(1);
    private dashboardSnapshots: DashboardSnapshot[] = [];
    private clientCode = '';
    private lastState: LastState | undefined;
    private funds: Fund[] = [];
    private clients: Client[] = [];
    private readonly widgetAddedToBoardSubject = new Subject<void>();
    private readonly widgetRemovedFromBoardSubject = new Subject<void>();
    private readonly datePickerInfoSubject: Subject<DatePickerInfo> = new ReplaySubject<DatePickerInfo>(1);

    constructor(
        @Inject(ApiServices.ddvMW) private readonly ddvApiService: ApiExecutorService,
        private readonly currentStateService: CurrentStateService,
        private readonly socketService: ServerSocketService,
        private readonly alertService: AlertService,
        private readonly fuzzyDateService: FuzzyDatesService,
        private readonly fundsService: FundsService,
        private readonly clientsService: ClientsService,
        private readonly realtimeActiveService: RealtimeActiveService,
        private readonly manager: ManagerService,
        private readonly dashboardDetailsRelay: DashboardDetailsRelayService,
        private readonly widgetExportService: WidgetExportService,
        private readonly datasetDefinitionsService: DatasetDefinitionsService,
        private readonly namedQueriesService: NamedQueriesService,
    ) {
        this.dashboardSnapshots$ = this.dashboardSnapshotsSubject.asObservable();

        this.currentStateService.isMultiClient$.pipe(switchMap((isMultiClient) => {
            if (isMultiClient) {
                return this.clientsService.clients();
            }

            return this.fundsService.funds();
        })).subscribe((data: Fund[] | string[]) => {
            if (data.some((datum) => datum instanceof Fund)) {
                this.funds = data as Fund[];
            } else {
                this.clients = (data as string[]).map((client) => ({ clientId: client, clientName: client }));
            }
        });

        this.currentStateService.clientCode$
            .subscribe((clientCode) => {
                this.clientCode = clientCode;
                this.refetchClientLevelUserData();
            });

        this.widgetAddedToBoard = this.widgetAddedToBoardSubject.asObservable();
        this.widgetRemovedFromBoard = this.widgetRemovedFromBoardSubject.asObservable();
        this.datePickerInfo = this.datePickerInfoSubject.asObservable();

        this.manager.currentDashboardId.subscribe((currentDashboardId) => {
            this.currentDashboardId = currentDashboardId;
            this.relayCurrentDashboardDetails();
        });

        this.widgetExportService.exportRequested.subscribe((request) => {
            this.exportDashboard(
                request.dashboardName,
                request.dashboardId,
                request.datasets,
                request.exportFormat,
                request.exportType);
        });
    }

    private static ensureDashboardIdsAreStrings(dashboardSnapshots: DashboardSnapshot[]): DashboardSnapshot[] {
        dashboardSnapshots.forEach((dashboardSnap) => {
            dashboardSnap.id = dashboardSnap.id?.toString();
        });

        return dashboardSnapshots;
    }

    toggleDefault(dashboard: DashboardSnapshot, currentDB: boolean): Observable<void> {
        const method = currentDB ? 'unmarkDefault' : 'markDefault';
        return this[method](dashboard.id ?? '', !currentDB).pipe(map((isDefaultFlag: boolean) => {
            dashboard.isDefault = isDefaultFlag;

            const workspace = this.manager.getWorkspace();
            if (workspace?.extraParameters) {
                workspace.extraParameters.isDefault = isDefaultFlag;
            }
        }));
    }

    getDashboardSnapshotById(dashboardId: string | number): DashboardSnapshot | DashboardDetails | undefined {
        return this.dashboardSnapshots.find((dashboardSnapshot): boolean => `${dashboardSnapshot.id}` === `${dashboardId}`);
    }

    getDashboardDetails(dashboardId: string | number): DashboardDetails | undefined{
        return this.getDashboardSnapshotById(dashboardId) as DashboardDetails;
    }

    noOpenDashboards(): boolean {
        return this.dashboardSnapshots == null || this.dashboardSnapshots.length === 0;
    }

    refetchClientLevelUserData(): void {
        this.fetchInitialDashboardsRespectingPreference();
    }

    fetchDashboardNAVStateForClient(clientCode: string): Observable<LastState> {
        return this.ddvApiService.invokeServiceWithParams<Partial<LastState>>(clientCode, 'user/dashboards')
            .pipe(
                map((response) => {
                    const lastState = new LastState(response);
                    DashboardService.ensureDashboardIdsAreStrings(lastState.dashboards);
                    return lastState;
                }),
            );
    }

    resetDashboardsToDefaults(): Observable<void> {
        return this.ddvApiService.invokeServiceWithParams<DashboardSnapshot[]>(this.clientCode, 'dashboard/default')
            .pipe(map((defaultDashboardSnapshots: DashboardSnapshot[]): void => {
                if (this.lastState) {
                    this.lastState.dashboards = defaultDashboardSnapshots;
                }
                this.setDashboardsAndNotifySubscribers();
            }));
    }

    async getDefaultDashboardId(): Promise<string | undefined> {
        const dashboards = await firstValueFrom(this.dashboardSnapshotsSubject.pipe(take(1)));
        if (!dashboards.length) {
            return;
        }

        if (!dashboards.some((dashboard) => dashboard.id === this.lastState?.selectedDashboardId)) {
            return dashboards[0].id;
        }

        return this.lastState?.selectedDashboardId;
    }

    updateDashboardList(dashboardSnapshot: DashboardSnapshot, action: 'addOrReplace' | 'remove'): void {
        if (!this.lastState) {
            return;
        }

        if (action === 'addOrReplace') {
            const index = this.dashboardSnapshots.findIndex((dashboard) => dashboard.id === dashboardSnapshot.id);
            if (index !== -1) {
                this.dashboardSnapshots[index] = dashboardSnapshot;
                this.lastState.dashboards[index] = dashboardSnapshot;
            } else {
                this.dashboardSnapshots.push(dashboardSnapshot);
                this.lastState.dashboards.push(dashboardSnapshot);
            }
        } else if (action === 'remove') {
            this.dashboardSnapshots = this.dashboardSnapshots.filter((dashboard) => dashboard.id !== dashboardSnapshot.id?.toString());
            this.lastState.dashboards = this.lastState.dashboards.filter((dashboard) => dashboard.id !== dashboardSnapshot.id?.toString());
        }

        this.dashboardSnapshotsSubject.next(this.dashboardSnapshots);
        this.relayCurrentDashboardDetails();
    }

    getAllDashboardsForUser(): Observable<DashboardSnapshot[]> {
        return this.ddvApiService.invokeServiceWithParams<DashboardSnapshot[]>(this.clientCode, 'dashboard')
            .pipe(map((dashboards: DashboardSnapshot[]) => {
                return dashboards
                    .map((data) => new DashboardSnapshot(data))
                    .sort((a, b) => (a.name?.toLowerCase() ?? '').localeCompare(b.name?.toLowerCase() ?? ''));
            }));
    }

    getDashboard(id: string, clientCode?: string): Observable<DashboardDetails> {
        return this.ddvApiService.invokeServiceWithParams<DashboardDetails>(clientCode ?? this.clientCode, 'dashboard/search', { dashboardId: id }, { catchError: false })
            .pipe(
                switchMap((dashboard: DashboardDetails): Observable<DashboardDetails> => {
                    dashboard.dashboardPreferences
                        .forEach((preference) => preference.filters?.forEach((filter) => setFilterValues(filter)));

                    const dashboardDetails = new DashboardDetails(dashboard);

                    if (dashboardDetails.widgets.some((widget) => widget.namedQueryId)) {
                        return this.updateDashboardsWithNamedQueryWidgets(dashboardDetails);
                    }

                    return of(dashboardDetails);
                }),
                mergeMap((dashboardDetails: DashboardDetails): Observable<DashboardDetails> => {
                    if (this.funds?.length) {
                        dashboardDetails.dashboardPreferences.forEach((dp) => {
                            const fundIds = dp.funds?.map((f) => f.fundId) ?? [];
                            dp.funds = this.funds.filter((fund) => fund.isIncludedInArrayOfIds(fundIds));
                        });
                    }

                    this.realtimeActiveService.updateRealtimeActive(dashboardDetails.widgets);

                    this.fuzzyDateService.emptyAdHocFuzzyDatesList();
                    this.fuzzyDateService.emptyInvestorFuzzyDatesList();

                    if (dashboardDetails.isInvestorOnly()) {
                        return this.getDashboardAfterInvestorFuzzyDateUpdate(dashboardDetails);
                    }

                    if (dashboardDetails.isSingleDataset()) {
                        return this.getDashboardWithSingleDataset(dashboardDetails);
                    }

                    this.updateDashboardList(dashboardDetails, 'addOrReplace');
                    return of(dashboardDetails);
                }));
    }

    closeDashboard(dashboard: DashboardSnapshot): Observable<void> {
        return this.ddvApiService.invokeServiceWithBody(this.clientCode, `user/state/dashboards/open/${dashboard.id}`, 'DELETE', null)
            .pipe(map((): void => {
                this.updateDashboardList(dashboard, 'remove');
            }));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    removeDashboard(id: string): Observable<any> {
        return this.ddvApiService.invokeServiceWithBody(this.clientCode, `dashboard?dashboardId=${id}`, 'DELETE', null);
    }

    markDefault(dashboardId: string, currentDB: boolean): Observable<boolean> {
        const requestParam = {
            dashboardID: dashboardId,
            defaultFlag: currentDB,
        };
        return this.ddvApiService.invokeServiceWithBody(this.clientCode, 'dashboard/mark/default', 'POST', requestParam);
    }

    unmarkDefault(dashboardId: string, currentDB: boolean): Observable<boolean> {
        const requestParam = {
            dashboardID: dashboardId,
            defaultFlag: currentDB,
        };
        return this.ddvApiService.invokeServiceWithBody(this.clientCode, 'dashboard/unmark/default', 'POST', requestParam);
    }

    // should this call that updateDashboardList method?
    createDashboard(requestParam: DashboardDetails): Observable<DashboardDetails> {
        return this.ddvApiService.invokeServiceWithBody<Partial<DashboardDetails>>(this.clientCode, 'dashboard/save', 'POST', requestParam)
            .pipe(map((dashboard) => new DashboardDetails(dashboard)));
    }

    updateDashboard(requestParam: DashboardDetails): Observable<DashboardDetails> {
        return this.ddvApiService.invokeServiceWithBody<DashboardDetails>(this.clientCode, 'dashboard/update', 'POST', requestParam)
            .pipe(map((dashboard: DashboardDetails) => {
                dashboard.id = dashboard.id?.toString();
                this.updateDashboardList(dashboard, 'addOrReplace');
                return dashboard;
            }));
    }

    copyDashboard(boardToCopy: DashboardDetails): Observable<number> {
        return this.ddvApiService.invokeServiceWithBody(this.clientCode, `dashboard/copy/${boardToCopy.id}`, 'POST', boardToCopy);
    }

    addWidgetToBoard(dashboardId: string | number, widgetId: number): Observable<WidgetOnBoard> {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.ddvApiService.invokeService<any>(this.clientCode, `dashboards/${dashboardId}/widgets/${widgetId}`, 'POST')
            .pipe(
                switchMap((wob: WidgetOnBoard) => {
                    if (wob.widget?.namedQueryId) {
                        return this.namedQueriesService.fetchNamedQuery(wob.widget.namedQueryId).pipe(
                            map((namedQuery) => {
                                this.updateDatasetDefinition(wob.widget!.datasetDefinition!, namedQuery);
                                return wob;
                            }),
                        );
                    }

                    return of(wob);
                }),
                map((wob: WidgetOnBoard) => {
                    this.alertService.success('Successfully added widget to board');
                    return new WidgetOnBoard(wob);
                }),
            );
    }

    addDetailWidgetToBoard(widgetId: number): Observable<AppWidgetState> {
        return this.ddvApiService.invokeServiceWithParams<Partial<AppWidgetState>>(this.clientCode, `widgets/${widgetId}`)
            .pipe(map((widget) => {
                return new AppWidgetState(widget);
            }));
    }

    removeWidgetFromBoard(dashboardId: string | number, widgetOnBoardId: number): Observable<void> {
        return this.ddvApiService.invokeService(this.clientCode, `dashboards/${dashboardId}/widgets/${widgetOnBoardId}`, 'DELETE')
            .pipe(map(() => {
                this.alertService.success('Successfully removed widget from board');
            }));
    }

    saveDashboard(requestParam: SaveDashboard): Observable<DashboardModel> {
        return this.ddvApiService.invokeServiceWithBody<DashboardModel>(this.clientCode, 'dashboard/update/config', 'POST', requestParam)
            .pipe(map((dashboard: DashboardModel) => new DashboardModel(dashboard)));
    }

    updateDashboardTags(dashboardId: string, requestParam: Tag[]): Observable<Tag[]> {
        return this.ddvApiService.invokeServiceWithBody(this.clientCode, `dashboards/${dashboardId}/tags`, 'POST', requestParam);
    }

    getTagsAutocompleterData(startsWith: string, clientCode?: string): Observable<TagSearchResult[]> {
        return this.ddvApiService.invokeServiceWithParams(clientCode ?? this.clientCode, 'tags/search', { searchStr: startsWith });
    }

    getAllTagsForClient(clientCode: string): Observable<string[]> {
        return this.ddvApiService.invokeServiceWithParams<TagSearchResult[]>(clientCode, 'tags/search', { searchStr: '' })
            .pipe(map((results) => results.map((result) => result.name)));
    }

    isNameAvailable(dashboardName: string, clientCode?: string): Observable<boolean> {
        return this.ddvApiService.invokeServiceWithParams(
            clientCode ?? this.clientCode,
            'dashboard/isNameValid',
            { nameStr: encodeURIComponent(dashboardName) });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    saveDashboardLastState(preference: DashboardPreference, currDashboardId: string): Observable<any> {
        return this.ddvApiService.invokeServiceWithBody(this.clientCode, `user/dashboards/${currDashboardId}/preference`, 'POST', preference);
    }

    exportDashboard(
        dashboardName: string,
        dashboardId: string,
        datasets: ExportDatasetInfo[],
        exportFormat: TExportFormat,
        exportType: string,
    ): void {
        this.socketService.send({
            type: 'dashboard export',
            payload: {
                clientCode: this.clientCode,
                dashboardName,
                dashboardId,
                datasets,
                format: exportFormat,
                exportType,
            },
        });
    }

    // jasmine does not seem to have a restore so you can't mock a static
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    getDefaultQueryParams(fundOptions: Fund[], clientOptions: Client[], extraParametersForWorkspace: any): DashboardPreference {
        return toDefaultQueryParams(fundOptions, clientOptions, extraParametersForWorkspace);
    }

    // this does not belong here at all
    getPrioritizedFilterParams(
        fundOptions: Fund[],
        clientOptions: Client[],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
        extraParametersForWorkspace: any,
        userPreferences?: UserPreferences,
    ): FilterQueryParam {
        return toPrioritizedFilterParams(fundOptions, clientOptions, extraParametersForWorkspace, userPreferences);
    }

    notifyForWidgetAddedOnDashboard(): void {
        this.widgetAddedToBoardSubject.next();
    }

    notifyForWidgetRemovedFromDashboard(): void {
        this.widgetRemovedFromBoardSubject.next();
    }

    updateDatePickerInfo(isCalendarVisible: boolean, isDateRangeSupported: boolean, isActiveDateSupported: boolean): void {
        this.datePickerInfoSubject.next({ isCalendarVisible, isDateRangeSupported, isActiveDateSupported });
    }

    private updateDashboardsWithNamedQueryWidgets(dashboardDetails: DashboardDetails): Observable<DashboardDetails> {
        const namedQueries$ = dashboardDetails.widgets.reduce((namedQueries: Observable<NamedQuery>[], widget) => {
            if (widget.namedQueryId) {
                namedQueries.push(this.namedQueriesService.fetchNamedQuery(widget.namedQueryId));
            }

            return namedQueries;
        }, []);

        return combineLatest(namedQueries$).pipe(
            map((namedQueries) => {
                namedQueries.forEach((namedQuery) => {
                    const namedQueryWidget = dashboardDetails.widgets.find((widget) => widget.namedQueryId === namedQuery.id);
                    this.updateDatasetDefinition(namedQueryWidget!.datasetDefinition!, namedQuery);
                });

                return dashboardDetails;
            }),
        );
    }

    private updateDatasetDefinition(datasetDefinition: DatasetDefinition, namedQuery: NamedQuery): void {
        datasetDefinition.namedQueryId = namedQuery.id;
        datasetDefinition.conversableType = namedQuery.crosstalkOptions?.conversableType;
        datasetDefinition.dataType = namedQuery.type.name;
        datasetDefinition.queryPeriodType = { name: namedQuery.periodType, id: -1 };
    }

    private relayCurrentDashboardDetails(): void {
        if (!this.currentDashboardId) {
            return;
        }

        const currentDashboardDetailsOrSnapshot = this.getDashboardDetails(this.currentDashboardId);
        if (currentDashboardDetailsOrSnapshot && currentDashboardDetailsOrSnapshot instanceof DashboardDetails) {
            this.dashboardDetailsRelay.relay(currentDashboardDetailsOrSnapshot);
        }
    }

    private fetchInitialDashboardsRespectingPreference(): void {
        this.fetchDashboardNAVStateForClient(this.clientCode).subscribe({
            next: (lastState: LastState) => {
                this.lastState = lastState;
                this.setDashboardsAndNotifySubscribers();
            },
        });
    }

    private setDashboardsAndNotifySubscribers(): void {
        if (!this.lastState) {
            return;
        }

        DashboardService.ensureDashboardIdsAreStrings(this.lastState.dashboards);
        this.dashboardSnapshots = Array.from(this.lastState.dashboards);
        this.dashboardSnapshotsSubject.next(this.dashboardSnapshots);
        this.relayCurrentDashboardDetails();
    }

    private getDashboardAfterInvestorFuzzyDateUpdate(dashboardDetails: DashboardDetails): Observable<DashboardDetails> {
        return this.fuzzyDateService
            .pushMostRecentFuzzyDatesForInvestorDataset(dashboardDetails.getAllFundCodes(), dashboardDetails.getAllDSDIds())
            .pipe(
                map((): DashboardDetails => {
                    this.updateDashboardList(dashboardDetails, 'addOrReplace');
                    return dashboardDetails;
                }),
                catchError(() => {
                    this.updateDashboardList(dashboardDetails, 'addOrReplace');
                    return of(dashboardDetails);
                }),
            );
    }

    private getDashboardWithSingleDataset(dashboardDetails: DashboardDetails): Observable<DashboardDetails> {
        const dashboard = dashboardDetails.firstDatasetDefinition();
        const id = dashboard?.namedQueryId ?? dashboard?.id;
        const datasetDefinition$: Observable<NamedQuery | DatasetDefinitionDetails> =
            typeof id === 'string' ?
                this.namedQueriesService.fetchNamedQuery(id) :
                this.datasetDefinitionsService.fetchDatasetDefinitionDetails(id ?? 0);

        return datasetDefinition$.pipe(
            switchMap((dsd: DatasetDefinitionDetails | NamedQuery) => {
                const dataEndpoint = dsd instanceof NamedQuery ? dsd.type.dataEndpoint : dsd.queryType.dataEndpoint;
                if (dataEndpoint.toLowerCase().includes('adhoc')) {
                    return this.getDashboardAfterAdHocFuzzyDateUpdate(dashboardDetails, dsd);
                }

                this.updateDashboardList(dashboardDetails, 'addOrReplace');
                return of(dashboardDetails);
            }),
            catchError(() => {
                this.updateDashboardList(dashboardDetails, 'addOrReplace');
                return of(dashboardDetails);
            }),
        );
    }

    private getDashboardAfterAdHocFuzzyDateUpdate(
        dashboardDetails: DashboardDetails,
        datasetDefinitionDetails: DatasetDefinitionDetails | NamedQuery,
    ): Observable<DashboardDetails> {
        return this.fuzzyDateService
            .pushMostRecentFuzzyDatesForAdhocDataset(datasetDefinitionDetails)
            .pipe(
                map((): DashboardDetails => {
                    this.updateDashboardList(dashboardDetails, 'addOrReplace');
                    return dashboardDetails;
                }),
                catchError(() => {
                    this.updateDashboardList(dashboardDetails, 'addOrReplace');
                    return of(dashboardDetails);
                }),
            );
    }
}

function setFilterValues(filter: DashboardFilter): void {
    if (filter.filterValuesType === 'number') {
        filter.values = filter.values?.map((value) => value != null ? Number(value) : value);
    }

    if (filter.filterValuesType === 'boolean') {
        filter.values = filter.values?.map((value) => value != null ? value === 'true' : value);
    }
}

interface DatePickerInfo {
    isCalendarVisible: boolean;
    isDateRangeSupported: boolean;
    isActiveDateSupported: boolean;
}

export interface TagSearchResult {
    clientCode: null;
    id: null;
    name: string;
    type: null;
}
