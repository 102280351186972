export const lightCellAndFontColors = [
    { fontColor: '#00aeef', cellColor: '' },
    { fontColor: '#72a13b', cellColor: '' },
    { fontColor: '#c30b1e', cellColor: '' },
    { fontColor: '#07506d', cellColor: '' },
    { fontColor: '#f5ad1c', cellColor: '' },
    { fontColor: '#c1c0c2', cellColor: '' },
    { fontColor: '#58595b', cellColor: '' },
    { fontColor: '#000', cellColor: '' },
    { fontColor: '#000', cellColor: '#e5f5fd'},
    { fontColor: '#fff', cellColor: '#00aeef'},
    { fontColor: '#fff', cellColor: '#07506d' },
    { fontColor: '#000', cellColor: '#f1f2f2' },
    { fontColor: '#000', cellColor: '#dfe1e6' },
    { fontColor: '#000', cellColor: '#b7bcc6' },
    { fontColor: '#fff', cellColor: '#7e8c95' },
    { fontColor: '#fff', cellColor: '#5b676e' },
    { fontColor: '#000', cellColor: '#fde1de' },
    { fontColor: '#000', cellColor: '#f47d7f' },
    { fontColor: '#fff', cellColor: '#c30b1e' },
    { fontColor: '#fff', cellColor: '#8dc449' },
    { fontColor: '#000', cellColor: '#c2dc9d' },
    { fontColor: '#000', cellColor: '#ecf4e1' },
    { fontColor: '#000', cellColor: '#ffd050' },
];

export const darkCellAndFontColors = [
    { fontColor: '#13aee5', cellColor: '' },
    { fontColor: '#72a13b', cellColor: '' },
    { fontColor: '#ea3a39', cellColor: '' },
    { fontColor: '#07506d', cellColor: '' },
    { fontColor: '#f9be14', cellColor: '' },
    { fontColor: 'rgba(250,251,255,0.25)', cellColor: '' },
    { fontColor: 'rgba(250,251,255,0.65)', cellColor: '' },
    { fontColor: '#fff', cellColor: '' },
    { fontColor: '#fff', cellColor: '#143a49' },
    { fontColor: '#fff', cellColor: '#1485b6' },
    { fontColor: '#000', cellColor: '#95c1dd' },
    { fontColor: '#fff', cellColor: 'rgba(250,251,255,0.08)' },
    { fontColor: '#fff', cellColor: 'rgba(250,251,255,0.15)' },
    { fontColor: '#fff', cellColor: 'rgba(250,251,255,0.30)' },
    { fontColor: '#000', cellColor: 'rgba(250,251,255,0.50)' },
    { fontColor: '#000', cellColor: 'rgba(250,251,255,0.70)' },
    { fontColor: '#fff', cellColor: 'rgba(195,11,30,0.30)' },
    { fontColor: '#fff', cellColor: 'rgba(195,11,30,0.68)' },
    { fontColor: '#fff', cellColor: 'rgba(195,11,30,1.00)' },
    { fontColor: '#fff', cellColor: 'rgba(141,196,73,0.89)' },
    { fontColor: '#fff', cellColor: 'rgba(141,196,73,0.50)' },
    { fontColor: '#fff', cellColor: 'rgba(141,196,73,0.20)' },
    { fontColor: '#000', cellColor: '#f9be14' },
];
