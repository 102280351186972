import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BehaviorsModule } from '@ddv/behaviors';
import { HttpModule } from '@ddv/http';
import { CcInputsModule } from '@hs/ui-core-inputs';

import { UserListFilter } from './components/user-select/user-list-filter.pipe';
import { UserSelectComponent } from './components/user-select/user-select.component';
import { FeatureFlagService } from './services/feature-flag.service';
import { UserEntitlementService } from './services/user-entitlement.service';
import { UserService } from './services/user.service';

@NgModule({
    imports: [
        BehaviorsModule,
        CcInputsModule,
        CommonModule,
        FormsModule,
        HttpModule,
    ],
    providers: [
        FeatureFlagService,
        UserEntitlementService,
        UserService,
    ],
    declarations: [
        UserSelectComponent,
        UserListFilter,
    ],
    exports: [
        UserSelectComponent,
    ],
})
export class EntitlementsModule {}
