import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BehaviorsModule } from '@ddv/behaviors';
import { CommonComponentsModule } from '@ddv/common-components';
import { EntitlementsModule } from '@ddv/entitlements';
import { ReferenceDataModule } from '@ddv/reference-data';
import { LibSvgIconsModule } from '@ddv/svg-icons';
import { VisualizationsModule } from '@ddv/visualizations';
import { CcDropdownsModule } from '@hs/ui-core-dropdowns';
import { CcInputsModule } from '@hs/ui-core-inputs';
import { CcListsModule } from '@hs/ui-core-lists';
import { CcPresentationModule } from '@hs/ui-core-presentation';
import { ResizableModule } from 'angular-resizable-element';

import { ClearFiltersIconComponent } from './components/clear-filters-icon/clear-filters-icon.component';
import { ClientsDashboardFormComponent } from './components/dashboard-clients/clients-dashboard-form.component';
import { DashboardFiltersComponent } from './components/dashboard-filters/dashboard-filters.component';
import { FundsDashboardFormComponent } from './components/dashboard-funds/funds-dashboard-form.component';
import { DashBoardComponent } from './components/dashboard/dashboard.component';
import { DatasetFiltersModalComponent } from './components/dataset-filters-modal/dataset-filters-modal.component';
import { FilterableAttributeComponent } from './components/filterable-attribute/filterable-attribute.component';
import { FilterableValueComponent } from './components/filterable-values/filterable-value.component';
import { MasterWidgetIconComponent } from './components/master-widget-icon/master-widget-icon.component';
import { SaveDashboardFormComponent } from './components/save-dashboard-form/save-dashboard-form.component';
import {
    UnsubscribedWidgetIconComponent,
} from './components/unsubscribed-widget-icon/unsubscribed-widget-icon.component';
import { UserPreferenceComponent } from './components/user-preference/user-preference.component';
import { VersionsService } from './components/user-preference/versions.service';
import { WidgetOnBoardComponent } from './components/widget-on-board/widget-on-board.component';
import { WorkspaceComponent } from './components/workspace/workspace.component';
import { WidgetDragModule } from './directives/widget-drag.module';
import { DashboardFiltersService } from './services/dashboard-filters.service';
import { DashboardGroupsService } from './services/dashboard-groups.service';
import { DashboardService } from './services/dashboard.service';
import { DdvWidgetService } from './services/ddv-widget.service';
import { WidgetQueryparamService } from './services/widget-queryparam.service';

@NgModule({
    imports: [
        BehaviorsModule,
        CcDropdownsModule,
        CcInputsModule,
        CommonComponentsModule,
        CommonModule,
        EntitlementsModule,
        FormsModule,
        ReactiveFormsModule,
        ReferenceDataModule,
        ResizableModule,
        RouterModule,
        VisualizationsModule,
        WidgetDragModule,
        LibSvgIconsModule,
        CcListsModule,
        CcPresentationModule,
    ],
    providers: [
        DashboardFiltersService,
        DashboardService,
        DashboardGroupsService,
        DdvWidgetService,
        VersionsService,
        WidgetQueryparamService,
    ],
    declarations: [
        ClearFiltersIconComponent,
        ClientsDashboardFormComponent,
        DashBoardComponent,
        DashboardFiltersComponent,
        DatasetFiltersModalComponent,
        FilterableAttributeComponent,
        FilterableValueComponent,
        FundsDashboardFormComponent,
        MasterWidgetIconComponent,
        SaveDashboardFormComponent,
        UnsubscribedWidgetIconComponent,
        UserPreferenceComponent,
        WidgetOnBoardComponent,
        WorkspaceComponent,
    ],
    exports: [
        DashBoardComponent,
        DashboardFiltersComponent,
        FilterableValueComponent,
        SaveDashboardFormComponent,
        UserPreferenceComponent,
        WorkspaceComponent,
    ],
})
export class DashboardsModule {}
