import { Injectable } from '@angular/core';
import * as d3 from 'd3';

import { ParentChartProperties } from '../models/parent-chart-properties';
import { BaseChartService, getDefaultMargin } from './base-chart.service';

@Injectable()
export class BrushService extends BaseChartService {
    protected parentChart: BaseChartService | undefined;
    protected parentChartProperties: ParentChartProperties | undefined;
    protected brushHeight = 0;
    protected brushWidth = 0;
    protected context: any;
    protected xField: string | undefined;
    protected xAxis2: d3.Axis<Date | d3.NumberValue> | undefined;

    protected setParentChartProperties(parentChart: BaseChartService): void {
        this.parentChart = parentChart;
        this.parentChartProperties = this.parentChart.getParentChartProperties();
    }

    protected setBrushProperties(): void {
        this.config = this.parentChartProperties?.config;
        this.svg = this.parentChartProperties?.svg;
        this.margin = this.parentChartProperties?.margin ??  getDefaultMargin();
        this.legendVisibility = this.parentChartProperties?.legendVisibility ?? 'show';
        this.yAxisRight = this.parentChartProperties?.yAxisRight;
        this.y = this.parentChartProperties?.y;

        this.xField = this.config?.series[0].xField[0];
        this.width = this.config?.width ?? 0;
    }

    protected setBrushDimensions(): void {
        const minDims = { height: 20, width: 20 };
        const margin = this.getMargin();
        this.brushWidth = this.getCalculatedWidth();
        this.width = this.brushWidth = (this.brushWidth < minDims.width) ? minDims.width : this.brushWidth;
        this.brushHeight = margin.bottom - margin.top;
        this.height = this.brushHeight = (this.brushHeight < minDims.height) ? minDims.height : this.brushHeight;
    }

    protected getMargin(): { top: number, bottom: number, right: number, left: number } {
        return { top: this.getMarginTop(), bottom: this.getMarginBottom(), right: this.margin.right, left: this.margin.left };
    }

    protected getMarginTop(): number {
        return this.config?.axis[0][0].rotate === 270 ? 30 : this.margin.top;
    }

    protected getMarginBottom(): number {
        const marginBottom = (this.config?.height ?? 0) - ((this.config?.brushSize ?? 0) + this.margin.bottom);
        if (this.config?.axis[0][0].rotate === 270) {
            return marginBottom + 30;
        }
        if (!this.config?.axis[0][0].customClass) {
            return marginBottom;
        }
        return (this.config.height ?? 0) < 400 ? marginBottom - 10 : marginBottom;
    }

    protected getContext(offset: number): any {
        return this.svg.append('g')
            .attr('class', 'context')
            .attr('transform', `translate(0,${this.brushHeight + offset})`);
    }

    protected setXAxis2(): void {
        this.context.append('g')
            .attr('class', 'axis axis--x')
            .attr('transform', 'translate(0,5)')
            .call(this.xAxis2);
    }

    protected formatTicks(selector: string): void {
        this.svg.selectAll(selector).each(function (this: any, d: any) {
            if (this.textContent === d3.timeFormat('%B')(d)) {
                d3.select(this)
                    .select('text')
                    .attr('class', 'brush-month')
                    .text(d3.timeFormat('%b')(d));
            }
            /* eslint-enable no-invalid-this */
        });
    }

    protected getFirstDateOnBrushResize(timeline: { notation: string, scale?: number }, secondDate: Date): Date {
        let { notation, scale } = timeline;
        scale = scale ?? 0;
        const xDomain = this.x2!.domain();
        const minDate = xDomain[0];
        const dateDiff = getBrushDateDiff(minDate, secondDate);

        let firstDate = new Date(secondDate);

        switch (notation) {
            case 'ALL':
                firstDate = xDomain[0];
                break;
            case 'YTD':
                if (firstDate.getFullYear() - minDate.getFullYear() !== 0) {
                    firstDate.setMonth(0);
                    firstDate.setDate(1);
                } else {
                    firstDate = xDomain[0];
                }
                break;
            case 'D':
                firstDate.setDate(secondDate.getDate() - scale);
                break;
            case 'M':
                if (dateDiff.month >= scale) {
                    firstDate.setMonth(secondDate.getMonth() - scale);
                } else {
                    firstDate = xDomain[0];
                }
        }

        return firstDate;
    }
}

function getBrushDateDiff(minDate: Date, maxDate: Date): { fullYear: number, month: number } {
    const minDateFullYear = minDate.getFullYear();
    const maxDateFullYear = maxDate.getFullYear();

    let yearDiff = maxDateFullYear - minDateFullYear;
    let monthDiff = maxDate.getMonth() - minDate.getMonth();
    const dayDiff = maxDate.getDate() - minDate.getDate();

    if (dayDiff < 0) {
        monthDiff--;
        if (monthDiff <= 0) {
            monthDiff += 12;
            yearDiff--;
        }
    }

    const fullYear = maxDateFullYear - 1;
    const month = yearDiff * 12 + monthDiff;

    return { fullYear, month };
}
