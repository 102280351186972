import { Injectable } from '@angular/core';
import * as d3 from 'd3';

import { BarLabelDetails } from '../models/bar-label-details';
import { ChartSettings } from '../models/chart-settings';
import { Element } from '../models/element';
import { Highlight } from '../models/highlight';
import { ParentChartProperties } from '../models/parent-chart-properties';
import { Series } from '../models/series';
import { Tooltip } from '../models/tooltip';
import { safeStringFormatter } from '../safe-string-formatter';
import { BarChartBrushService } from './bar-chart-brush.service';
import { BaseChartService } from './base-chart.service';
import { ColorProvider } from './interfaces';

@Injectable()
export class BarChartService extends BaseChartService implements ColorProvider {
    mirrorY1Axis = false;
    mirrorY2Axis = false;
    protected brushService: BarChartBrushService | undefined;
    private isVertical = false;
    private isInsideLabel = false;
    private isXAxisCustomDomain = false;
    private isYAxisCustomDomain = false;
    private readonly textBoxBarHeightPadding = 10;
    private readonly textBoxBarWidthPadding = 2;
    private stackedChartUpdated = false;

    drawBarChart(config: ChartSettings, isMaximized: boolean, legendVisibility?: 'show' | 'hide'): void {
        this.config = config;
        this.legendVisibility = legendVisibility ?? 'show';
        this.isVertical = !!this.config.curveValues && this.config.curveValues.labelOrientation === 'vertical';
        this.isInsideLabel = !!this.config.curveValues && this.config.curveValues.enableInsideLabel;
        if (this.config.axis && this.config.axis.length > 1) {
            this.isXAxisCustomDomain = !!this.config.axis[0][0].domain && this.config.axis[0][0].domain.length > 1;
            this.isYAxisCustomDomain = !!this.config.axis[1][0].domain && this.config.axis[1][0].domain.length > 1;
        }
        this.initSvg(isMaximized);
        this.prepareData(config);
        this.initAxis();
        this.createOrdinal();
        if (this.config.series[0].stacked) {
            this.prepareStackedChart();
        }
        this.setAxisDomain();
        this.drawAxis(isMaximized);
        // I know that we have the same if a few lines above but these have to happen in the order they do
        // otherwise stacked charts are not drawn
        if (this.config.series[0].stacked) {
            this.stackedBars(this.config.series[0]);
        } else {
            this.drawBars(this.config.series[0], this.config.dataSource, this.config.dataCompareSource);
        }
        this.applyConfigs();
        this.setAxisLabelFilter();
        if (this.config.showBrush) {
            this.brushService = new BarChartBrushService();
            this.brushService.addBrush(this);
        }
    }

    drawBars(series: Series, data: any, compareData?: any): void {
        const showHoverLine = this.config?.highlightXValueOnHover || this.config?.highlightYValueOnHover;
        this.appendRectArea();
        this.removeClipPath();
        this.appendClipPath();

        if (compareData) {
            const selection = this.svg.selectAll()
                .data(data)
                .enter()
                .append('rect')
                .attr('class', (d: any) => {
                    const key = series.horizontal ? series.yField[0] : series.xField[0];
                    return `bar _${this.convertSafeNameToId(d, key)}`;
                })
                .attr('x', (d: any) => this.getXAxisValue(d, series))
                .attr('y', (d: any) => this.getYAxisValue(d, series))
                .attr('width', (d: any) => {
                    const res = this.calculateBarWidth(d, series);
                    return series.horizontal ? res : (compareData ? res / 2 - 5 : res);
                })
                .attr('height', (d: any) => {
                    const res = this.calculateBarHeight(d, series);
                    return series.horizontal ? (compareData ? res / 2 - 5 : res) : res;
                })
                .attr('fill', (d: any, i: number) => this.getColor(d[series.horizontal ? series.yField[0] : series.xField[0]], i))
                .attr('stroke', 'gray')
                .attr('clip-path', () => `url(#${this.config?.parentSelector}-clip)`);
            selection
                .on('mousemove', (event: MouseEvent, d: any) => {
                    if (showHoverLine) {
                        this.showHoverLines(event, series);
                    }
                    if (this.config?.showTooltip) {
                        d3.selectAll('.toolTip').remove();
                        this.createTooltip(event, d, this.config.tooltip ?? []);
                    }
                })
                .on('mouseout', () => {
                    if (showHoverLine) {
                        this.revertLinePosition(!series.horizontal, !!series.horizontal);
                    }
                    d3.selectAll('.toolTip').remove();
                })
                .on('click touchend', (event: MouseEvent, d: any) => {
                    const gList = selection.nodes();
                    const clickedIndex = gList.indexOf(event.currentTarget);
                    this.highlightBar(d);
                    const key = series.horizontal ? series.yField[0] : series.xField[0];
                    d.key = d[key];
                    this.onChartClicked(d.data ? d : { data: d }, !d3.select(gList[clickedIndex]).classed('enabled'));
                })
                .exit()
                .remove()
                .data(compareData)
                .enter()
                .append('rect')
                .attr('class', (d: any) => {
                    const key = series.horizontal ? series.yField[0] : series.xField[0];
                    return `bar _${this.convertSafeNameToId(d, key)} compare`;
                })
                .attr('x', (d: any) => {
                    const res = this.getXAxisValue(d, series);
                    return series.horizontal ? res : res + this.calculateBarWidth(d, series) / 2;
                })
                .attr('y', (d: any) => {
                    const res = this.getYAxisValue(d, series);
                    return series.horizontal ? res + this.calculateBarHeight(d, series) / 2 : res;
                })
                .attr('width', (d: any) => {
                    const res = this.calculateBarWidth(d, series);
                    return series.horizontal ? res : res / 2 - 5;
                })
                .attr('height', (d: any) => {
                    const res = this.calculateBarHeight(d, series);
                    return series.horizontal ? res / 2 - 5 : res;
                })
                .attr('fill', (d: any, i: number) => {
                    return this.config?.selectedSlicer ?
                        this.getColor(d[series.horizontal ? series.yField[0] : series.xField[0]], i) :
                        'lightgray';
                })
                .attr('stroke', 'gray')
                .attr('clip-path', () => `url(#${this.config?.parentSelector}-clip)`)
                .on('mousemove', (event: MouseEvent, d: any) => {
                    if (showHoverLine) {
                        this.showHoverLines(event, series);
                    }
                    if (this.config?.showTooltip) {
                        d3.selectAll('.toolTip').remove();
                        this.createTooltip(event, d, this.config.tooltip ?? []);
                    }
                })
                .on('mouseout', () => {
                    if (showHoverLine) {
                        this.revertLinePosition(!series.horizontal, !!series.horizontal);
                    }
                    d3.selectAll('.toolTip').remove();
                });
        } else {
            const selection = this.svg.selectAll()
                .data(data)
                .enter()
                .append('rect')
                .attr('class', (d: any) => {
                    const key = series.horizontal ? series.yField[0] : series.xField[0];
                    return `bar _${this.convertSafeNameToId(d, key)}`;
                })
                .attr('x', (d: any) => this.getXAxisValue(d, series))
                .attr('y', (d: any) => this.getYAxisValue(d, series))
                .attr('width', (d: any) => this.calculateBarWidth(d, series))
                .attr('height', (d: any) => this.calculateBarHeight(d, series))
                .attr('fill', (d: any, i: number) => this.getColor(d[series.horizontal ? series.yField[0] : series.xField[0]], i))
                .attr('stroke', 'gray')
                .attr('clip-path', () => `url(#${this.config?.parentSelector}-clip)`);
            selection
                .on('mousemove', (event: MouseEvent, d: any) => {
                    if (showHoverLine) {
                        this.showHoverLines(event, series);
                    }
                    if (this.config?.showTooltip) {
                        d3.selectAll('.toolTip').remove();
                        this.createTooltip(event, d, this.config.tooltip ?? []);
                    }
                })
                .on('mouseout', () => {
                    if (showHoverLine) {
                        this.revertLinePosition(!series.horizontal, !!series.horizontal);
                    }
                    d3.selectAll('.toolTip').remove();
                })
                .on('click touchend', (event: MouseEvent, d: any) => {
                    const gList = selection.nodes();
                    const clickedIndex = gList.indexOf(event.currentTarget);
                    this.highlightBar(d);
                    const key = series.horizontal ? series.yField[0] : series.xField[0];
                    d.key = d[key];
                    this.onChartClicked(d.data ? d : { data: d }, !d3.select(gList[clickedIndex]).classed('enabled'));
                });
        }

        if (showHoverLine) {
            this.appendLine();
            this.svg.select('.rectdrawarea')
                .on('mousemove', (event: MouseEvent) => {
                    this.showHoverLines(event, series);
                })
                .on('mouseout', () => {
                    if (showHoverLine) {
                        this.revertLinePosition(!series.horizontal, !!series.horizontal);
                    }
                });
        }
    }

    setAxisDomain(): void {
        if (!this.config) {
            throw new Error('cannot setAxisDomain without config');
        }

        const series = this.config.series[0];
        let customDomain;
        const tempDataSource = [...this.config.dataSource, ...(this.config.dataCompareSource || [])];
        if (this.config.series[0].horizontal) {
            const xFieldAccessorFn = (d: any): number => d[series.xField[0]];
            const stackMinXFieldAccessorFn = (d: any): number => {
                let negValues = series.xField.map((xField) => d[xField]);
                negValues = [...negValues, ...d.negTotal ? [d.negTotal] : []];
                return d3.min(negValues);
            };
            const posTotalAccessorFn = (d: any): number => d.posTotal;
            if (this.isXAxisCustomDomain) {
                customDomain = this.config.axis[0][0].domain as any;
                const customDomainMin = customDomain[0];
                this.config.negativeValue = customDomainMin < 0;
                this.x.domain([customDomainMin, customDomain[1]]);
            } else {
                this.x.domain([
                    this.config.negativeValue ? d3.min(tempDataSource, series.stacked ?
                        stackMinXFieldAccessorFn : xFieldAccessorFn) : 0,
                    series.stacked ? d3.max(tempDataSource, posTotalAccessorFn) : this.getMaxDomainValue(xFieldAccessorFn),
                ]).nice();
            }
        } else {
            const yAxis = this.y || this.yAxisRight;
            const yFieldAccessorFn = (d: any): number => d[series.yField[0]];
            const yFieldMinAccessorFn = (d: any): number => d3.min(series.yField.map((yField) => d[yField]));
            const posTotalAccessorFn = (d: any): number => d.posTotal;
            const stackMinYFieldAccessorFn = (d: any): number => {
                let negValues = series.yField.map((yField) => d[yField]);
                negValues = [...negValues, ...d.negTotal ? [d.negTotal] : []];
                return d3.min(negValues);
            };
            if (this.isYAxisCustomDomain) {
                customDomain = this.config.axis[1][0].domain as any;
                const customDomainMin = customDomain[0];
                this.config.negativeValue = customDomainMin < 0;
                yAxis.domain([customDomainMin, customDomain[1]]);
            } else {
                yAxis.domain([
                    this.config.negativeValue ? d3.min(tempDataSource,
                        series.stacked ? stackMinYFieldAccessorFn : yFieldMinAccessorFn) : 0,
                    series.stacked ? d3.max(this.config.dataSource, posTotalAccessorFn) : this.getMaxDomainValue(yFieldAccessorFn),
                ]).nice();
            }
        }
    }

    setAxisLabelFilter(): void {
        if (this.config?.series[0].horizontal) {
            this.svg
                .select('g.axis--y')
                .selectAll('.tick text')
                .style('cursor', 'pointer')
                .on('click', (_: any, d: string) => this.applyAxisLabelFilter(d));
        } else {
            this.svg
                .select('g.axis--x')
                .selectAll('.tick text')
                .style('cursor', 'pointer')
                .on('click', (_: any, d: string) => this.applyAxisLabelFilter(d));
        }
    }

    getMaxDomainValue(accessorFn: any): string | number {
        const tempDataSource = [...(this.config?.dataSource ?? []), ...(this.config?.dataCompareSource || [])];
        const maxDomainValue = d3.max(tempDataSource, accessorFn) ?? '';
        return +maxDomainValue < 0 ? 0 : maxDomainValue;
    }

    calculateBarWidth(d: any, series: Series): number {
        const xField = series.xField[0];
        const xValue = this.x(d[xField]);
        if (series.horizontal) {
            return (this.config?.negativeValue ?
                Math.abs(xValue - this.x(0)) : (xValue));
        }
        return Math.min(this.ordinalScale!.bandwidth() - 2, 100);
    }

    calculateBarHeight(d: any, series: Series): number {
        const offset = this.getChartOffsets();
        const yField = series.yField[0];
        const yAxis = this.y || this.yAxisRight;
        if (series.horizontal) {
            return Math.min(this.ordinalScale!.bandwidth(), 100);
        }
        if (d[yField]) {
            return (this.config?.negativeValue ?
                Math.abs(yAxis(d[yField]) - yAxis(0)) : ((this.height - offset.y) - yAxis(d[yField])));
        }
        return (this.config?.negativeValue ?
            Math.abs(yAxis(d.total) - yAxis(0)) : ((this.height - offset.y) - yAxis(d.total)));
    }

    getStackedBarXAxisValue(d: any, series: Series): number {
        const xField = series.xField[0];
        const xValue = series.horizontal ? this.x(d[0]) : this.x(d.data[xField]);
        const width = this.ordinalScale!.bandwidth() - 2;
        const verticalChartWidth = width > 100 ? (xValue as number) + (width / 2) - 50 : xValue;
        return series.horizontal ? xValue : verticalChartWidth;
    }

    getStackedBarYAxisValue(d: any, series: Series): number {
        const yField = series.yField[0];
        const yAxis = this.y || this.yAxisRight;
        const width = this.ordinalScale!.bandwidth();
        const yValue = series.horizontal ? yAxis(d.data[yField]) : yAxis(d[1] && d[1] > d[0] ? d[1] : 0);
        if (series.horizontal) {
            return width > 100 ? (yValue as number) + (width / 2) - 50 : yValue;
        } else {
            return yValue;
        }
    }

    getXAxisValue(d: object, series: Series): number {
        const xField = this.getXField(d, series);
        const xValue = this.x(xField);
        const width = this.ordinalScale!.bandwidth() - 2;
        const verticalChartWidth = width > 100 ? (xValue as number) + (width / 2) - 50 : xValue;
        return series.horizontal ? this.x(Math.min(0, xField)) : verticalChartWidth;
    }

    getYAxisValue(d: object, series: Series): number {
        let yPos = 0;
        const yField = this.getYField(d, series);
        const yAxis = this.y || this.yAxisRight;
        const width = this.ordinalScale!.bandwidth();
        if (series.horizontal) {
            yPos = (width > 100 ? (yAxis(yField) as number) + (width / 2) - 50 : yAxis(yField));
        } else {
            yPos = yAxis(Math.max(yField, 0));
        }
        return yPos;
    }

    highlightBar(d: any): void {
        if (!this.config) {
            throw new Error('cannot highlightBar without config');
        }

        const series = this.config.series[0];
        const key = series.horizontal ? series.yField[0] : series.xField[0];
        const gBars = this.svg.selectAll('.bar');
        const barKey = this.convertSafeNameToId(d, key);
        const selBar = gBars.filter(`._${barKey}`);
        if (selBar.size() === 0) {
            return;
        }
        const disabledBars = gBars.filter('.disabled').size();
        if (disabledBars === 0 ||
            (disabledBars > 0 && !selBar.classed('enabled'))) {
            gBars.classed('disabled', true).classed('enabled', false);
            selBar.classed('disabled', false).classed('enabled', true);
            d3.select(this.svg.node().parentNode).selectAll('.legend-items')
                .classed('disabled', (n: any, index, elem: any) => {
                    elem[index].enabled = false;
                    return barKey !== this.convertSafeNameToId(n, key);
                })
                .classed('enabled', (n: any) => barKey === this.convertSafeNameToId(n, key));
            this.config.highlight!.data = d;
        } else {
            this.svg.selectAll('.bar')
                .classed('disabled', false).classed('enabled', false);
            d3.select(this.svg.node().parentNode).selectAll('.legend-items')
                .classed('disabled', false)
                .classed('enabled', false);
            this.config.highlight!.data = undefined;
        }
    }

    highlightStackedBar(d: { key: string }): void {
        const series = this.config?.series[0];
        const key = (series?.horizontal ? series?.yField[0] : series?.xField[0]) ?? '';
        const selectedBars = this.svg.selectAll('.bar rect').filter((bar: any) => bar.data[key] === d.key);
        const unselectedBars = this.svg.selectAll('.bar rect').filter((bar: any) => bar.data[key] !== d.key);

        if (selectedBars.filter('.disabled').size()) {
            selectedBars.classed('disabled', false);
            unselectedBars.classed('disabled', true);
            this.stackedChartUpdated = false;
        } else if (unselectedBars.filter('.disabled').size()) {
            unselectedBars.classed('disabled', false);
            this.stackedChartUpdated = true;
        } else {
            unselectedBars.classed('disabled', true);
            this.stackedChartUpdated = false;
        }
    }

    override getParentChartProperties(): ParentChartProperties {
        return {
            ...super.getParentChartProperties(),
            isStacked: !!this.stackData,
        };
    }

    protected createTooltip(event: any, data: any, info: Tooltip[], slicerLabel?: string, slicerField?: string): void {
        let html = '';

        if (this.config?.series[0].tooltipHTML) {
            html = this.config.series[0].tooltipHTML(data, slicerLabel, slicerField);
        } else {
            if (info && info.length) {
                html += '<div>';
                for (const item of info) {
                    html += item.name ? `<label>${item.name}: </label>` : '';
                    html += `<span> ${data[item.key!]}</span><br>`;
                }
                html += '</div>';
            } else {
                html += `<div><span> ${data.value}</span></div>`;
            }
        }
        d3.select('body').append('div').attr('class', 'toolTip bar')
            .style('left', `${(event.pageX as number) + 15}px`)
            .style('top', `${(event.pageY as number) + 15}px`)
            .style('opacity', 1)
            .style('position', 'fixed')
            .style('z-index', 5000)
            .html(html);
    }

    protected stackedBars(series: Series): void {
        const yAxis = this.y || this.yAxisRight;
        const showHoverLine = this.config?.highlightXValueOnHover || this.config?.highlightYValueOnHover;
        const isHorizontal = series.horizontal;
        let slicerLabel: string;
        const slicerField = this.config?.multiSeries?.field;
        this.appendRectArea();
        const selection = this.svg.selectAll('.layer')
            .data(this.stackData)
            .enter()
            .append('g')
            .attr('class', 'layer')
            .style('fill', (d: any, i: number) => this.getColor(d.key, i))
            .attr('class', (d: any) => `bar _${this.convertSafeNameToId(d)}`);

        selection
            .on('click', (event: MouseEvent, d: any) => {
                const gList = selection.nodes();
                const clickedIndex = gList.indexOf(event.currentTarget);
                if (!series.mirror) {
                    this.highlightBar(d);
                    this.onChartClicked(d.data ? d : { data: d }, !d3.select(gList[clickedIndex]).classed('enabled'));
                }
            })
            .on('mouseover', (_: any, d: any) => {
                if (series.stacked) {
                    slicerLabel = series.mirror ? this.dataSource?.find((data) => data.key === d.key)?.displayName : d.key;
                }
            });
        this.removeClipPath();
        this.appendClipPath();

        selection.selectAll('rect')
            .data((d: any) => d)
            .enter()
            .append('rect')
            .attr('x', (d: any) => this.getStackedBarXAxisValue(d, series))
            .attr('y', (d: any) => this.getStackedBarYAxisValue(d, series))
            .attr('height', (d: any) => isHorizontal ? Math.min(this.ordinalScale!.bandwidth() - 2, 100) :
                this.setHeight(yAxis, d[0], d[1]))
            .attr('width', (d: any) => isHorizontal ? this.getHorizontalBarWidth(d, this.x) :
                Math.min(this.ordinalScale!.bandwidth() - 2, 100))
            .attr('clip-path', () => `url(#${this.config?.parentSelector}-clip)`)
            .on('mousemove', (event: MouseEvent, d: any) => {
                if (showHoverLine) {
                    this.showHoverLines(event, series);
                }
                if (this.config?.showTooltip) {
                    d3.selectAll('.toolTip').remove();
                    this.createTooltip(event, d, this.config.tooltip ?? [], slicerLabel, slicerField);
                }
            })
            .on('mouseout', () => {
                if (showHoverLine) {
                    this.revertLinePosition(!series.horizontal, !!series.horizontal);
                }
                d3.selectAll('.toolTip').remove();
            })
            .on('click', (event: MouseEvent, d: any) => {
                if (series.mirror) {
                    const chartData = d.data ? d : { data: d };
                    chartData.data.key = chartData.data[`${series.xField}`];
                    this.onChartClicked(chartData, false);
                }
            });
        if (showHoverLine) {
            this.appendLine();
            this.svg.select('.rectdrawarea')
                .on('mousemove', (event: MouseEvent) => {
                    this.showHoverLines(event, series);
                })
                .on('mouseout', () => {
                    if (showHoverLine) {
                        this.revertLinePosition(!series.horizontal, !!series.horizontal);
                    }
                });
        }
    }

    protected override applyConfigs(): void {
        if (!this.config) {
            throw new Error('cannot applyConfigs without config');
        }

        const series = this.config.series[0];
        const stacked = series.stacked;
        const mirrored = series.mirror;
        let stackEnabled = false;
        if (this.config.showCurveValues && this.config.curveValues) {
            if (stacked) {
                if (mirrored ? true : this.isInsideLabel) {
                    stackEnabled = true;
                    this.addStackedLabel(this.stackData, stackEnabled, mirrored ? this.isInsideLabel : true);
                }
                if (this.config.curveValues.enableTotalLabel) {
                    stackEnabled = false;
                    this.addLabel(this.config.dataSource, stackEnabled, false);
                }
            } else {
                this.addLabel(this.config.dataSource, stackEnabled, this.isInsideLabel, this.config.dataCompareSource);
            }
        }
        this.createNegativeAxis();
        if (this.config.legend?.showCustom) {
            this.legendsService.setDefaults({
                svg: this.svg,
                config: this.config,
                dataSource: this.dataSource || this.config.dataSource,
                clickFn: this.highlightBar.bind(this),
                onChartClicked: !this.config.series[0].mirror ? this.onChartClicked : false,
            });
            this.legendsService.createCustomLegends();
        }
        if (this.config.highlight && this.config.highlight.data) {
            const selectedHighlight: Highlight = this.config.highlight;
            this.highlightBar(selectedHighlight.data);
        }
        const yAxisConfig = this.config.axis[1][0];
        if (this.config.series[0].mirror && (yAxisConfig.nTicks ?? 0) > 0) {
            this.updateMirroredBarAxisLabels();
        }
    }

    private getXField(d: any, series: Series): number {
        if (series.mirror || (series.stacked && d.data)) {
            return d.data[series.xField[0]];
        }

        return d[series.xField[0]];
    }

    private getYField(d: any, series: Series): number {
        if (series.stacked && d.data) {
            return d.data[series.yField[0]];
        }

        return d[series.yField[0]];
    }

    private getHorizontalBarWidth(d: any, axis: any): number {
        if (!isNaN(d[1]) && !isNaN(d[0])) {
            return Math.max(d[1] > d[0] ? (axis(d[1] || 0) - axis(d[0])) : (axis(d[0]) - axis(d[1] || 0)), 0);
        }
        return 0;
    }

    private setHeight(yAxis: (value: number) => number, value1: number, value2: number): number {
        if (isNaN(value1) || isNaN(value2)) {
            return 0;
        }
        let height = yAxis(value1) - yAxis(value2 || 0);
        height = height > 0 ? height : height * -1;
        return height || Math.max(height, 0);
    }

    private updateMirroredBarAxisLabels(): void {
        this.svg.select('g.axis--y')
            .selectAll('.tick text')
            .call(this.formatMirroredBarTickLabels, this);
    }

    private formatMirroredBarTickLabels(texts: any, chart: any): void {
        let axisMultiplier = chart.mirrorY2Axis ? -1 : 1;
        for (let text of texts._groups[0]) {
            text = d3.select(text);
            let textStr = text.text().replace(/,/g, '');
            if (textStr > 0 && !chart.mirrorY1Axis) {
                axisMultiplier = 1;
            }
            textStr = textStr * axisMultiplier;
            text.text(chart.config.formatter?.numberUnits !== '' ? chart.applyFormatter(textStr) : textStr);
        }
    }

    private applyAxisLabelFilter(d: string): void {
        if (this.stackData) {
            const slicer = (this.config?.series[0].horizontal ?
                this.config.series[0].yField[0] :
                this.config?.series[0].xField[0]) ?? '';
            this.highlightStackedBar({ key: d });
            const elt: Element = { key: d, slicer, data: { key: d } };
            if (d === 'others') {
                elt.data[slicer] = 'others';
                elt.data.children = this.config?.dataSource.find((ds) => ds[slicer] === 'others')?.children;
            }
            this.onChartClicked(elt, this.stackedChartUpdated);
        } else {
            const data = { ...this.dataSource?.find((datum) => datum.key === d)?.values[0], key: d };
            this.highlightBar(data);
            this.onChartClicked({ data }, !this.config?.highlight?.data);
        }
    }

    private showHoverLines(event: Event, series: Series): void {
        const enableAxis = { left: false, right: false };
        const mousePoint = d3.pointer(event);
        if (this.config?.axis[1][0].nTicks && this.config.axis[1][0].customClass !== 'hide') {
            enableAxis.left = true;
        }
        this.changeLinePosition(
            !series.horizontal && !!this.config?.highlightYValueOnHover,
            !!series.horizontal,
            mousePoint,
            enableAxis,
            series.mirror,
            this.mirrorY1Axis,
            this.mirrorY2Axis);
    }

    private addDataLabel(data: any[], insideLabel: boolean, stackEnabled: boolean, isCompareData: boolean = false): void {
        const series = this.config?.series[0]!;
        const isHorizontal = series?.horizontal;
        this.svg.selectAll('g.labels-group').remove();
        const isStackedBar = series?.stacked;
        const xField = (isStackedBar ? (isHorizontal ? 'total' : series.xField[0]) : series?.xField[0]) ?? '';
        const yField = (isStackedBar ? (isHorizontal ? series.yField[0] : 'total') : series?.yField[0]) ?? '';
        const barLabelDetails: BarLabelDetails[] = [];
        const isRegularBar = !isStackedBar;
        const isHorizontalStackedBar = isStackedBar && isHorizontal;

        const gp = this.svg.selectAll('.labels-group')
            .data(data)
            .enter()
            .append('g')
            .attr('class', 'labels-group ')
            .attr('clip-path', () => `url(#${this.config?.parentSelector}-clip)`);
        gp.select('text')
            .data(data)
            .enter()
            .append('text')
            .attr('dy', '.35em')
            .attr('pointer-events', 'none')
            .text((d: any) => this.applyFormatter(isHorizontal ? d[xField] : d[yField]))
            .classed('label', true)
            .attr('transform', (d: any, i: number, textList: SVGGraphicsElement[]) => {
                const pos: number[] = this.getLabelPosition(
                    d,
                    i,
                    textList,
                    stackEnabled,
                    insideLabel,
                    !!this.config?.dataCompareSource,
                    isCompareData);

                if (!insideLabel) {
                    const label = isHorizontal ? d[xField] : d[yField];
                    const textBox = textList[i].getBBox();
                    const outSidePosition = isHorizontal ? pos[0] : pos[1];
                    const isInside = this.findNotFitOutSide(!!isHorizontal, outSidePosition, textBox, label);

                    if(isHorizontalStackedBar || isRegularBar) {
                        if (isInside) {
                            pos[isHorizontal ? 0 : 1] =
                                this.calculateInsideLabelPosition(!!isHorizontal, label, outSidePosition, textBox, false);
                        }

                        const isCompareDataAndIsHorizontal = !!isCompareData && !!isHorizontal;
                        const maxHeight = this.getBarLabelMaxHeight(d, series!, isCompareDataAndIsHorizontal);
                        const maxWidth = this.getBarLabelMaxWidth(d, series!, isCompareData, !!isHorizontal, !!isHorizontalStackedBar);
                        barLabelDetails.push({
                            maxHeight,
                            maxWidth,
                            height: textBox.height,
                            width: textBox.width,
                            position: pos,
                            isInside,
                        });
                    }
                }

                return `translate(${pos}) rotate(${this.isVertical ? 270 : 0})`;
            })
            .text((d: any, i: number, textList: SVGGraphicsElement[]) => {
                const label = isHorizontal ? d[xField] : d[yField];
                const pos: number[] = this.getLabelPosition(d, i, textList, stackEnabled, insideLabel);
                const barWidth = isHorizontalStackedBar ?
                    barLabelDetails[i].maxWidth :
                    this.calculateBarWidth(d, series);
                const barHeight = isHorizontalStackedBar ?
                    barLabelDetails[i].maxHeight :
                    this.calculateBarHeight(d, series);
                const textBox = textList[i].getBBox();
                let updateInsideLabel = false;
                if (!insideLabel) {
                    const outSidePosition = isHorizontal ? pos[0] : pos[1];
                    if (this.findNotFitOutSide(!!isHorizontal, outSidePosition, textBox, label)) {
                        updateInsideLabel = true;
                    }
                }

                const isBarLabelFit = this.isBarLabelFit(barWidth, barHeight, textBox, !!isHorizontal, insideLabel, updateInsideLabel);
                const fitCheckFlag = (!insideLabel && (isHorizontalStackedBar || (isRegularBar && isBarLabelFit))) || isBarLabelFit;

                const textValue = d3.select(textList[i]).text();

                if ((isHorizontalStackedBar || isRegularBar) && !insideLabel) {
                    barLabelDetails[i].showBackground = fitCheckFlag;
                }

                return fitCheckFlag && Number(textValue) !== 0 ? textValue : '';
            })
            .style('fill', (d: any, i: number, textList: SVGGraphicsElement[]) => {
                const pos: number[] = this.getLabelPosition(d, i, textList, stackEnabled, insideLabel);
                if (!insideLabel) {
                    const label = isHorizontal ? d[xField] : d[yField];
                    const textBox = textList[i].getBBox();
                    const outSidePosition = isHorizontal ? pos[0] : pos[1];

                    if (this.findNotFitOutSide(!!isHorizontal, outSidePosition, textBox, label) && (isHorizontalStackedBar || isRegularBar)) {
                        return '#fff';
                    }

                    if (!this.findNotFitOutSide(!!isHorizontal, outSidePosition, textBox, label)) {
                        return false;
                    }
                }

                return 'var(--main-text-color);';
            });

        if ((isHorizontalStackedBar || isRegularBar) && !insideLabel) {
            gp.select('text')
                .data(data)
                .enter()
                .insert('rect', '.label')
                .attr('pointer-events', 'none')
                .attr('height', (d: any, i: number) => {
                    if (barLabelDetails[i].maxHeight >= barLabelDetails[i].height + 8) {
                        return barLabelDetails[i].height + 3.8;
                    }
                    if (barLabelDetails[i].maxHeight >= barLabelDetails[i].height + 4) {
                        return barLabelDetails[i].height + 0.1;
                    }

                    return barLabelDetails[i].height;
                })
                .attr('width', (d: any, i: number) => barLabelDetails[i].width + 12)
                .attr('rx', 2)
                .attr('ry', 2)
                .attr('transform', (d: any, i: number) => {
                    const pos = barLabelDetails[i].position;
                    pos[0] = isRegularBar && this.isVertical ? pos[0] - 9 : pos[0] - 6;
                    pos[1] = isRegularBar && this.isVertical ?
                        pos[1] + 6 :
                        (barLabelDetails[i].maxHeight >= barLabelDetails[i].height + 8 ? pos[1] - 9 : pos[1] - 7);

                    return `translate(${pos}) rotate(${isRegularBar && this.isVertical ? 270 : 0}) `;
                })
                .style('fill', (d: any, i: number) => {
                    const fieldValue = isHorizontal ? d[xField] : d[yField];
                    return barLabelDetails[i].showBackground ?
                        this.getFillColor(barLabelDetails[i], fieldValue) :
                        'transparent';
                });
        }
    }

    private getBarLabelMaxHeight(d: any, series: Series, isCompareDataAndIsHorizontal: boolean): number {
        return isCompareDataAndIsHorizontal ?
            this.calculateBarHeight(d, series) / 2 :
            this.calculateBarHeight(d, series);
    }

    private getBarLabelMaxWidth(
        d: any,
        series: Series,
        isCompareData: boolean,
        isHorizontal: boolean,
        isHorizontalStackedBar: boolean,
    ): number {
        return isHorizontalStackedBar ?
            this.calculateStackedWidth(true, [d.total < 0 ? d.total : 0, d.total < 0 ? 0 : d.total]) :
            isCompareData && !isHorizontal ?
                this.calculateBarWidth(d, series) / 2 :
                this.calculateBarWidth(d, series);
    }

    private isBarLabelFit(
        barWidth: number,
        barHeight: number,
        textBox: DOMRect,
        isHorizontal: boolean,
        insideLabel: boolean,
        updateInsideLabel: boolean,
    ): boolean {
        return isHorizontal ?
            this.checkHorizontalBarLabelFit(insideLabel || updateInsideLabel, barHeight, barWidth, textBox) :
            this.checkVerticalBarLabelFit(this.isVertical, insideLabel || updateInsideLabel, barHeight, barWidth, textBox);
    }

    private getFillColor(barLabelDetails: BarLabelDetails, fieldValue: number): string {
        if (this.shouldHaveBackground(barLabelDetails, fieldValue)) {
            return 'rgba(35, 31, 32, .54)';
        }

        return 'transparent';
    }

    private shouldHaveBackground(barLabelDetails: BarLabelDetails, fieldValue: number): boolean {
        return fieldValue !== 0 &&
            barLabelDetails.isInside &&
            (this.isVertical ?
                barLabelDetails.maxWidth > barLabelDetails.height + 10 && barLabelDetails.maxHeight > barLabelDetails.width + 16 :
                barLabelDetails.maxHeight >= barLabelDetails.height + 4 && barLabelDetails.maxWidth > barLabelDetails.width + 12);
    }

    private addLabel(data: any[], stackEnabled: boolean, insideLabel: boolean, dataCompare?: any[]): void {
        this.addDataLabel(data, insideLabel, stackEnabled);

        if (dataCompare) {
            this.addDataLabel(dataCompare, insideLabel, stackEnabled, true);
        }
    }

    private checkVerticalBarLabelFit(
        isVerticalLabel: boolean,
        insideLabel: boolean,
        barHeight: number,
        barWidth: number,
        textBox: DOMRect): boolean {
        if (isVerticalLabel) {
            if (insideLabel) {
                return (barHeight > textBox.width + this.textBoxBarHeightPadding) &&
                    (barWidth > textBox.height + this.textBoxBarWidthPadding);
            }
            return barWidth > textBox.height;
        }
        if (insideLabel) {
            return (barWidth > textBox.width + this.textBoxBarWidthPadding) &&
                (barHeight > textBox.height + this.textBoxBarHeightPadding);
        }
        return barWidth > textBox.width;
    }

    private checkHorizontalBarLabelFit(insideLabel: boolean, barHeight: number, barWidth: number, textBox: DOMRect): boolean {
        if (insideLabel) {
            return (barWidth > textBox.width + this.textBoxBarHeightPadding) &&
                (barHeight > textBox.height + this.textBoxBarWidthPadding);
        }
        return barHeight > textBox.height;
    }

    private addStackedLabel(data: any, stackEnabled: boolean, insideLabel: boolean): void {
        const yAxis = this.y || this.yAxisRight;
        const series = this.config?.series[0]!;
        const isHorizontal = series.horizontal;
        const mirrorAxisBarPadding = 5;
        this.svg.selectAll('g.stack-labels-group').remove();
        const gp = this.svg.selectAll('.stack-labels-group')
            .data(data)
            .enter()
            .append('g')
            .attr('class', 'stack-labels-group ')
            .attr('clip-path', () => `url(#${this.config?.parentSelector}-clip)`);
        gp.selectAll('text')
            .data((d: any) => d)
            .enter()
            .append('text')
            .attr('dy', '.35em')
            .attr('pointer-events', 'none')
            .text((d: any) => this.applyFormatter(d[0] >= 0 ? (d[1] - d[0]) : (d[0] - d[1])))
            .classed('stacked-label', true)
            .attr('transform', (d: any, i: number, textList: SVGGraphicsElement[]) => {
                const pos: number[] = this.getLabelPosition(d, i, textList, stackEnabled, insideLabel);
                if (series.mirror && !insideLabel) {
                    const textBox = textList[i].getBBox();
                    const stackedValue = (d[0] >= 0 ? (d[1] - d[0]) : (d[0] - d[1]));
                    const yPositionValue = this.getStackedBarYAxisValue(d, series);
                    const barHeight = this.calculateStackedHeight(!!isHorizontal, yAxis, d);
                    let updatedPosition;
                    if (!isNaN(stackedValue) || stackedValue < 0) {
                        updatedPosition = !this.isVertical ? pos[1] + textBox.height : pos[1];
                        pos[1] = updatedPosition > yAxis.range()[0] ? yPositionValue + barHeight - mirrorAxisBarPadding : pos[1];
                    } else {
                        updatedPosition = pos[1] - (this.isVertical ? textBox.width : textBox.height);
                        pos[1] = updatedPosition < 0 ? yPositionValue + (this.isVertical ? textBox.width : 0) + 10 : pos[1];
                    }
                }
                return `translate(${pos}) rotate(${this.isVertical ? 270 : 0}) `;
            })
            .text((d: any, i: number, textList: SVGGraphicsElement[]) => {
                const stackedValue = (d[0] >= 0 ? (d[1] - d[0]) : (d[0] - d[1]));
                const pos: number[] = this.getLabelPosition(d, i, textList, stackEnabled, insideLabel);
                const barWidth = this.calculateStackedWidth(!!isHorizontal, d);
                const barHeight = this.calculateStackedHeight(!!isHorizontal, yAxis, d);
                const textBox = textList[i].getBBox();
                let label = isNaN(stackedValue) ? 0 : stackedValue;
                let isInsideLabelchanged = false;
                let fitCheckFlag = false;
                let axisMultiplier = this.mirrorY2Axis ? -1 : 1;
                if (series.mirror) {
                    isInsideLabelchanged = !insideLabel && this.hasLabelPositionChanged(label, textBox, yAxis, pos, mirrorAxisBarPadding);
                    if (label > 0 && !this.mirrorY1Axis) {
                        axisMultiplier = 1;
                    }
                    label = label * axisMultiplier;
                }
                fitCheckFlag = isHorizontal ? this.checkHorizontalBarLabelFit(insideLabel || isInsideLabelchanged,
                    barHeight, barWidth, textBox) :
                    this.checkVerticalBarLabelFit(this.isVertical, insideLabel || isInsideLabelchanged,
                        barHeight, barWidth, textBox);
                return fitCheckFlag && Number(label) !== 0 ? this.applyFormatter(label) : '';
            })
            .style('fill', (d: any, i: number, textList: SVGGraphicsElement[]) => {
                if (series.mirror && !insideLabel) {
                    const pos: number[] = this.getLabelPosition(d, i, textList, stackEnabled, insideLabel);
                    const textBox = textList[i].getBBox();
                    const stackedValue = (d[0] >= 0 ? (d[1] - d[0]) : (d[0] - d[1]));
                    const label = isNaN(stackedValue) ? 0 : stackedValue;
                    return this.hasLabelPositionChanged(label, textBox, yAxis, pos, mirrorAxisBarPadding) ? '#fff' : false;
                }
                return insideLabel ? '#fff' : false;
            });
    }

    private hasLabelPositionChanged(label: number, textBox: DOMRect, yAxis: any, pos: number[], mirrorAxisBarPadding: number): boolean {
        if (label < 0) {
            return (!this.isVertical ? pos[1] + textBox.height : pos[1]) + mirrorAxisBarPadding > yAxis.range()[0];
        }
        return pos[1] - (this.isVertical ? textBox.width : textBox.height) < 0;
    }

    private getLabelPosition(
            d: any,
            i: number,
            textList: SVGGraphicsElement[],
            stackEnabled: boolean,
            insideLabel: boolean,
            inCompareMode: boolean = false,
            isCompareData: boolean = false): number[] {
        const yAxis = this.y || this.yAxisRight;
        const series = this.config?.series[0]!;
        const isHorizontal = series.horizontal;
        const isStacked = series.stacked;
        const totalField = d.total > 0 ? 'posTotal' : 'negTotal';
        const xField = isStacked ? (isHorizontal ? totalField : series.xField[0]) : series.xField[0];
        const yField = isStacked ? (isHorizontal ? series.yField[0] : totalField) : series.yField[0];
        let stackedValue = isStacked ? (d[0] >= 0 ? (d[1] - d[0]) : (d[0] - d[1])) : 0;
        stackedValue = isNaN(stackedValue) ? 0 : stackedValue;
        const textBox = textList[i].getBBox();
        const isVertical = this.config?.curveValues?.labelOrientation === 'vertical';
        let xLabel: number;
        let yLabel: number;
        if (stackEnabled) {
            const position =
                this.getStackedBarLabelPosition(!!isHorizontal, d, yAxis, stackedValue, series, isVertical, textBox, insideLabel);
            xLabel = position.xLabel;
            yLabel = position.yLabel;
        } else {
            const position = this.getBarLabelPosition(d, xField, yField, textBox, isVertical, insideLabel, yAxis);
            xLabel = position.xLabel;
            yLabel = position.yLabel;
        }
        const xColumnPos = this.getXAxisValue(d, series) + this.calculateBarWidth(d, series) / (inCompareMode ? 4 : 2);
        const xPos = inCompareMode ?
            (xColumnPos - 3) + (isCompareData ? this.calculateBarWidth(d, series) / 2 : 0) :
            xColumnPos;

        const yColumnPos = this.getYAxisValue(d, series) + this.calculateBarHeight(d, series) / (inCompareMode ? 4 : 2);
        const yPos = inCompareMode ?
            (yColumnPos - 3) + (isCompareData ? this.calculateBarHeight(d, series) / 2 : 0) :
            yColumnPos;

        return isHorizontal ? [xLabel, yPos] : [xPos, yLabel];
    }

    private calculateXPosition(xFieldValue: number, xValue: number, textBox: DOMRect, insideLabel: boolean): number {
        if (insideLabel) {
            return this.calculateInsideLabelPosition(true, xFieldValue, xValue, textBox, true);
        }
        const padding = 10;
        return xFieldValue < 0 ? xValue - textBox.width - padding : xValue + padding;
    }

    private calculateYPosition(yFieldValue: number, yValue: number, textBox: DOMRect, isInside: boolean): number {
        if (isInside) {
            return this.calculateInsideLabelPosition(false, yFieldValue, yValue, textBox, true);
        }
        return yFieldValue < 0 ? yValue + 10 : yValue - 10;
    }

    private calculateInsideLabelPosition(
        isXAxis: boolean,
        value: number,
        positionValue: number,
        textBox: DOMRect,
        isDirectInsideLabel: boolean): number {
        const padding = isDirectInsideLabel ? 10 : 20;

        if (isXAxis) {
            if (value < 0) {
                return isDirectInsideLabel ? positionValue + padding : positionValue + textBox.width + padding;
            }

            return positionValue - textBox.width - padding;
        }

        if (value < 0) {
            return this.isVertical ? positionValue - textBox.width - padding : positionValue - padding;
        }

        return this.isVertical ? positionValue + textBox.width + padding : positionValue + padding;
    }

    private findNotFitOutSide(isHorizontal: boolean, outSidePosition: number, textBox: DOMRect, fieldValue: number): boolean {
        let checkNotFitFlag = false;
        if (isHorizontal) {
            const updatedOuterPosition = fieldValue < 0 ? outSidePosition : outSidePosition + textBox.width;
            if (updatedOuterPosition < 0 || updatedOuterPosition > this.x.range()[1]) {
                checkNotFitFlag = true;
            }
        } else {
            const yAxis = this.y || this.yAxisRight;
            const textBoxWidth = this.isVertical ? textBox.width : 0;
            if (outSidePosition - textBoxWidth < 0) {
                checkNotFitFlag = true;
            }
            if (fieldValue < 0 && outSidePosition > yAxis.range()[0]) {
                checkNotFitFlag = true;
            }
        }
        return checkNotFitFlag;
    }

    private calculateStackedHeight(isHorizontal: boolean, yAxis: any, d: any): number {
        return isHorizontal ?
            Math.min(this.ordinalScale!.bandwidth() - 2, 100) :
            this.setHeight(yAxis, d[0], d[1]);
    }

    private calculateStackedWidth(isHorizontal: boolean, d: any): number {
        return isHorizontal ?
            Math.max((this.x(d[1] || 0) - this.x(d[0])), 0) :
            Math.min(this.ordinalScale!.bandwidth() - 2, 100);
    }

    private convertSafeNameToId(hash: any, propertyName?: string, defaultName = 'Blanks'): string {
        let name: string;

        if (safeStringFormatter(hash.key)) {
            name = safeStringFormatter(hash.key);
        } else if (propertyName && hash.hasOwnProperty(propertyName) && safeStringFormatter(hash[propertyName])) {
            name = safeStringFormatter(hash[propertyName]);
        } else {
            name = defaultName;
        }

        return this.convertNameToId(name);
    }

    private getStackedBarLabelPosition(
            isHorizontal: boolean,
            d: any,
            yAxis: any,
            stackedValue: number,
            series: Series,
            isVertical: boolean,
            textBox: DOMRect,
            insideLabel: boolean): { xLabel: number, yLabel: number } {
        const barWidth = this.calculateStackedWidth(isHorizontal, d);
        const barHeight = this.calculateStackedHeight(isHorizontal, yAxis, d);
        const xLabel = this.getStackedBarXAxisValue(d, series) + (barWidth / 2) - (textBox.width / 2);
        let yLabel = this.getStackedBarYAxisValue(d, series);
        if (isVertical) {
            if (insideLabel) {
                yLabel += (barHeight / 2) + (textBox.width / 2);
            } else {
                yLabel += stackedValue >= 0 ? -10 : barHeight + textBox.width + 10;
            }
        } else {
            if (insideLabel) {
                yLabel += (barHeight / 2);
            } else {
                yLabel += stackedValue >= 0 ? -10 : barHeight + 10;
            }
        }

        return { xLabel, yLabel };
    }

    private getBarLabelPosition(
            d: any,
            xField: string,
            yField: string,
            textBox: DOMRect,
            isVertical: boolean,
            insideLabel: boolean,
            yAxis: any): { xLabel: number, yLabel: number } {
        const xFieldValue = d[xField];
        const xValue = this.x(xFieldValue);
        let yValue = yAxis(d[yField]);
        const yFieldValue = d[yField];
        const verticalWidth = yFieldValue < 0 ? -1 * textBox.width : 0;
        yValue = isVertical ? yValue - verticalWidth : yValue;
        const xLabel = this.calculateXPosition(xFieldValue, xValue, textBox, insideLabel);
        const yLabel = this.calculateYPosition(yFieldValue, yValue, textBox, insideLabel);

        return { xLabel, yLabel };
    }
}
