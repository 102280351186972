import { ColorType } from '../../models/color-type';
import { LINE_CHART_COLOR_FILL } from './names';

export const baseColorConfig = [
    { label: 'Dark Blue Fill', values: ['#03506F'], displayValues: ['#03506F'], type: ColorType.SOLID },
    { label: 'Cyan Fill', values: ['#29AAE1'], displayValues: ['#29AAE1'], type: ColorType.SOLID },
    { label: 'Light Cyan Fill', values: ['#8ED8F8'], displayValues: ['#8ED8F8'], type: ColorType.SOLID },
    { label: 'Green Fill', values: ['#8CC540'], displayValues: ['#8CC540'], type: ColorType.SOLID },
    { label: 'Light Green Fill', values: ['#D0E4AA'], displayValues: ['#D0E4AA'], type: ColorType.SOLID },
    { label: 'Yellow Fill', values: ['#FFC940'], displayValues: ['#FFC940'], type: ColorType.SOLID },
    { label: 'Light Yellow Fill', values: ['#FFE3A6'], displayValues: ['#FFE3A6'], type: ColorType.SOLID },
    { label: 'Orange Fill', values: ['#F7941D'], displayValues: ['#F7941D'], type: ColorType.SOLID },
    { label: 'Light Orange Fill', values: ['#F9A980'], displayValues: ['#F9A980'], type: ColorType.SOLID },
    { label: 'Dark Red Fill', values: ['#BE202E'], displayValues: ['#BE202E'], type: ColorType.SOLID },
    { label: 'Red Fill', values: ['#EF4123'], displayValues: ['#EF4123'], type: ColorType.SOLID },
    { label: 'Dark GrayFill', values: ['#808285'], displayValues: ['#808285'], type: ColorType.SOLID },
    { label: 'Block Fill', values: ['#333333'], displayValues: ['#333333'], type: ColorType.SOLID },

    {
        label: 'Mixed Green-Yellow Family',
        values: ['#2A3C4D', '#EFED73'],
        displayValues: ['#2A3C4D', '#22566A', '#057280', '#008F8C', '#35AA8D', '#6DC485', '#AADB79', '#EFED73'],
        type: ColorType.MONOCHROMATIC,
    },
    {
        label: 'Dark Turquoise Family',
        values: ['#0F395C', '#DCEDD8'],
        displayValues: ['#0F395C', '#19526F', '#306C80', '#4C868F', '#6DA09E', '#91BAAF', '#B6D3C2', '#DCEDD8'],
        type: ColorType.MONOCHROMATIC,
    },
    {
        label: 'Dark Teal Family',
        values: ['#21313E', '#EAFAEA'],
        displayValues: ['#21313E', '#354B56', '#4D666E', '#678386', '#84A09E', '#A4BDB6', '#C6DBCF', '#EAFAEA'],
        type: ColorType.MONOCHROMATIC,
    },
    {
        label: 'Light Blue Family',
        values: ['#0096CF', '#B5E9FF'],
        displayValues: ['#0096CF', '#35A2D6', '#50AEDC', '#66B9E3', '#7BC5EA', '#8ED1F1', '#A2DDF8', '#B5E9FF'],
        type: ColorType.MONOCHROMATIC,
    },
    {
        label: 'Dark Blue Family',
        values: ['#10526E', '#B3E3FF'],
        displayValues: ['#10526E', '#2D6581', '#447995', '#5A8DA9', '#70A2BE', '#86B7D3', '#9CCDE9', '#B3E3FF'],
        type: ColorType.MONOCHROMATIC,
    },
    {
        label: 'Dark Green Family',
        values: ['#268224', '#EBF9D3'],
        displayValues: ['#268224', '#4B923B', '#69A352', '#85B46A', '#A0C483', '#B9D69C', '#D3E7B7', '#EBF9D3'],
        type: ColorType.MONOCHROMATIC,
    },
    {
        label: 'Light Green Family',
        values: ['#8FC44A', '#EBF9D3'],
        displayValues: ['#8FC44A', '#9DCC5F', '#ABD372', '#B8DB86', '#C5E299', '#D2EAAC', '#DFF1BF', '#EBF9D3'],
        type: ColorType.MONOCHROMATIC,
    },
    {
        label: 'Dark Yellow Family',
        values: ['#F7941E', '#FFF4D0'],
        displayValues: ['#F7941E', '#F7A439', '#F7B352', '#F7C16A', '#F8CF83', '#F9DC9C', '#FCE8B6', '#FFF4D0'],
        type: ColorType.MONOCHROMATIC,
    },
    {
        label: 'Middle Red Family',
        values: ['#BE1E2D', '#FFD6D0'],
        displayValues: ['#BE1E2D', '#CA4241', '#D65D57', '#E0766D', '#EA8F85', '#F2A69D', '#F9BEB6', '#FFD6D0'],
        type: ColorType.MONOCHROMATIC,
    },
    {
        label: 'Light Black Family',
        values: ['#58595B', '#F3F3F3'],
        displayValues: ['#58595B', '#6C6D6F', '#818284', '#979899', '#ADAEAF', '#C4C4C5', '#DBDBDC', '#F3F3F3'],
        type: ColorType.MONOCHROMATIC,
    },
    {
        label: 'Diverse Group',
        values: ['#26A9E0', '#A4C439', '#32859A', '#F1BF36', '#B3D1D6', '#F05C38',
            '#0E516E', '#628D33', '#B6CEAD', '#CEDC60', '#F6921E', '#4E666E', '#90CDBA', '#0D7075', '#7671A3', '#FFF17A',
            '#92BAAF', '#1BAEBD', '#053A65', '#C0DFD2', '#DCDFDE'],
        displayValues: ['#26A9E0', '#A4C439', '#32859A', '#F1BF36', '#B3D1D6', '#F05C38',
            '#0E516E', '#628D33', '#B6CEAD', '#CEDC60', '#F6921E', '#4E666E', '#90CDBA', '#0D7075', '#7671A3', '#FFF17A',
            '#92BAAF', '#1BAEBD', '#053A65', '#C0DFD2', '#DCDFDE'],
        type: ColorType.MULTICOLORED,
    },
    {
        label: 'Green Group',
        values: ['#34B34A', '#8FC73E', '#B2D235', '#E9E611', '#799A3D', '#A7B739',
            '#C8C64E', '#B3C292', '#47823D', '#6C995B', '#A2BA83', '#E3E5D8', '#12B24B', '#6FC068', '#ABD69B', '#DAE9C1',
            '#00A76D', '#1DB78D', '#8DCFAD', '#C1E1C1', '#00AE9C', '#4EC0AE', '#99D4BF', '#E3F2E7', '#3A6454', '#369283',
            '#74ADA0', '#A9C0B1', '#698867', '#749F84', '#A0BDA1', '#BDC8B2', '#656B47', '#818860', '#A1AB8C', '#B4B39B',
            '#BDAF33', '#D3C657', '#E9E083', '#E7E2A9'],
        displayValues: ['#34B34A', '#8FC73E', '#B2D235', '#E9E611', '#799A3D', '#A7B739',
            '#C8C64E', '#B3C292', '#47823D', '#6C995B', '#A2BA83', '#E3E5D8', '#12B24B', '#6FC068', '#ABD69B', '#DAE9C1',
            '#00A76D', '#1DB78D', '#8DCFAD', '#C1E1C1', '#00AE9C', '#4EC0AE', '#99D4BF', '#E3F2E7', '#3A6454', '#369283',
            '#74ADA0', '#A9C0B1', '#698867', '#749F84', '#A0BDA1', '#BDC8B2', '#656B47', '#818860', '#A1AB8C', '#B4B39B',
            '#BDAF33', '#D3C657', '#E9E083', '#E7E2A9'],
        type: ColorType.MULTICOLORED,
    },
    {
        label: 'Blue Group',
        values: ['#00ADED', '#44C7F4', '#9DDCF9', '#D4EDFC', '#0071BC', '#009EDB',
            '#92C5EA', '#C7DFF4', '#00AFAD', '#5BC4BE', '#A4DAD2', '#D7EDE6', '#0088B2', '#2A9FBC', '#70B9C6', '#A6C7C7',
            '#005BAB', '#3E85C6', '#95B5DF', '#D4DAD8', '#2A457A', '#5B75A7', '#7D98BB', '#3A96AA', '#7AB3BE', '#9EBCD0',
            '#CAE2E4', '#435671', '#507493', '#7898B2', '#9BAFC6', '#3589AA', '#72A1B4', '#95B7C5', '#B2CEDC', '#006B91',
            '#1894B8', '#83B7D0', '#C0D9E5', '#006BA6', '#2D89B9', '#92B7D6', '#B4D5E5', '#347487', '#689197', '#78A5AC',
            '#A7C1C0', '#33647A', '#517B92', '#7B97A3', '#9EB6C1'],
        displayValues: ['#00ADED', '#44C7F4', '#9DDCF9', '#D4EDFC', '#0071BC', '#009EDB',
            '#92C5EA', '#C7DFF4', '#00AFAD', '#5BC4BE', '#A4DAD2', '#D7EDE6', '#0088B2', '#2A9FBC', '#70B9C6', '#A6C7C7',
            '#005BAB', '#3E85C6', '#95B5DF', '#D4DAD8', '#2A457A', '#5B75A7', '#7D98BB', '#3A96AA', '#7AB3BE', '#9EBCD0',
            '#CAE2E4', '#435671', '#507493', '#7898B2', '#9BAFC6', '#3589AA', '#72A1B4', '#95B7C5', '#B2CEDC', '#006B91',
            '#1894B8', '#83B7D0', '#C0D9E5', '#006BA6', '#2D89B9', '#92B7D6', '#B4D5E5', '#347487', '#689197', '#78A5AC',
            '#A7C1C0', '#33647A', '#517B92', '#7B97A3', '#9EB6C1'],
        type: ColorType.MULTICOLORED,
    },
];

export const monochromeColorConfig = [
    {
        label: '#2A3C4D',
        values: ['#2A3C4D', '#22566A', '#057280', '#008F8C', '#35AA8D', '#6DC485', '#AADB79', '#EFED73'],
        displayValues: ['#2A3C4D', '#22566A', '#057280', '#008F8C', '#35AA8D', '#6DC485', '#AADB79', '#EFED73'],
        type: ColorType.MONOCHROMATIC,
    },
    {
        label: '#0F395C',
        values: ['#0F395C', '#19526F', '#306C80', '#4C868F', '#6DA09E', '#91BAAF', '#B6D3C2', '#DCEDD8'],
        displayValues: ['#0F395C', '#19526F', '#306C80', '#4C868F', '#6DA09E', '#91BAAF', '#B6D3C2', '#DCEDD8'],
        type: ColorType.MONOCHROMATIC,
    },
    {
        label: '#21313E',
        values: ['#21313E', '#354B56', '#4D666E', '#678386', '#84A09E', '#A4BDB6', '#C6DBCF', '#EAFAEA'],
        displayValues: ['#21313E', '#354B56', '#4D666E', '#678386', '#84A09E', '#A4BDB6', '#C6DBCF', '#EAFAEA'],
        type: ColorType.MONOCHROMATIC,
    },
    {
        label: '#0096CF',
        values: ['#0096CF', '#35A2D6', '#50AEDC', '#66B9E3', '#7BC5EA', '#8ED1F1', '#A2DDF8', '#B5E9FF'],
        displayValues: ['#0096CF', '#35A2D6', '#50AEDC', '#66B9E3', '#7BC5EA', '#8ED1F1', '#A2DDF8', '#B5E9FF'],
        type: ColorType.MONOCHROMATIC,
    },
    {
        label: '#10526E',
        values: ['#10526E', '#2D6581', '#447995', '#5A8DA9', '#70A2BE', '#86B7D3', '#9CCDE9', '#B3E3FF'],
        displayValues: ['#10526E', '#2D6581', '#447995', '#5A8DA9', '#70A2BE', '#86B7D3', '#9CCDE9', '#B3E3FF'],
        type: ColorType.MONOCHROMATIC,
    },
    {
        label: '#268224',
        values: ['#268224', '#4B923B', '#69A352', '#85B46A', '#A0C483', '#B9D69C', '#D3E7B7', '#EBF9D3'],
        displayValues: ['#268224', '#4B923B', '#69A352', '#85B46A', '#A0C483', '#B9D69C', '#D3E7B7', '#EBF9D3'],
        type: ColorType.MONOCHROMATIC,
    },
    {
        label: '#8FC44A',
        values: ['#8FC44A', '#9DCC5F', '#ABD372', '#B8DB86', '#C5E299', '#D2EAAC', '#DFF1BF', '#EBF9D3'],
        displayValues: ['#8FC44A', '#9DCC5F', '#ABD372', '#B8DB86', '#C5E299', '#D2EAAC', '#DFF1BF', '#EBF9D3'],
        type: ColorType.MONOCHROMATIC,
    },
    {
        label: '#F7941E',
        values: ['#F7941E', '#F7A439', '#F7B352', '#F7C16A', '#F8CF83', '#F9DC9C', '#FCE8B6', '#FFF4D0'],
        displayValues: ['#F7941E', '#F7A439', '#F7B352', '#F7C16A', '#F8CF83', '#F9DC9C', '#FCE8B6', '#FFF4D0'],
        type: ColorType.MONOCHROMATIC,
    },
    {
        label: '#BE1E2D',
        values: ['#BE1E2D', '#CA4241', '#D65D57', '#E0766D', '#EA8F85', '#F2A69D', '#F9BEB6', '#FFD6D0'],
        displayValues: ['#BE1E2D', '#CA4241', '#D65D57', '#E0766D', '#EA8F85', '#F2A69D', '#F9BEB6', '#FFD6D0'],
        type: ColorType.MONOCHROMATIC,
    },
    {
        label: '#58595B',
        values: ['#58595B', '#6C6D6F', '#818284', '#979899', '#ADAEAF', '#C4C4C5', '#DBDBDC', '#F3F3F3'],
        displayValues: ['#58595B', '#6C6D6F', '#818284', '#979899', '#ADAEAF', '#C4C4C5', '#DBDBDC', '#F3F3F3'],
        type: ColorType.MONOCHROMATIC,
    },
];

export const lightSolidColorConfig = [
    { label: 'Cyan Fill', values: ['#00AEEF'], displayValues: ['#00AEEF'], type: ColorType.SOLID },
    { label: 'Light Cyan Fill', values: ['#39859A'], displayValues: ['#39859A'], type: ColorType.SOLID },
    { label: 'Dark Blue Fill', values: ['#0E516E'], displayValues: ['#0E516E'], type: ColorType.SOLID },
    { label: 'Green Fill', values: ['#8FC44A'], displayValues: ['#8FC44A'], type: ColorType.SOLID },
    { label: 'Dark Green Fill', values: ['#628D33'], displayValues: ['#628D33'], type: ColorType.SOLID },
    { label: 'Yellow Fill', values: ['#F1BF36'], displayValues: ['#F1BF36'], type: ColorType.SOLID },
    { label: 'Orange Fill', values: ['#F7941E'], displayValues: ['#F7941E'], type: ColorType.SOLID },
    { label: 'Red Fill', values: ['#EF4136'], displayValues: ['#EF4136'], type: ColorType.SOLID },
    { label: 'Dark Red Fill', values: ['#BE1E2D'], displayValues: ['#BE1E2D'], type: ColorType.SOLID },
    {
        label: 'Light Gray Fill',
        values: ['#D1D2D3'],
        displayValues: ['#D1D2D3'],
        type: ColorType.SOLID,
    },
    {
        label: 'Dark Gray Fill',
        values: ['#A7A8AB'],
        displayValues: ['#A7A8AB'],
        type: ColorType.SOLID,
    },
    {
        label: 'Block Fill',
        values: ['#808184'],
        displayValues: ['#808184'],
        type: ColorType.SOLID,
    },
];

export const darkSolidColorConfig = [
    { label: 'Cyan Fill', values: ['#00AEEF'], displayValues: ['#00AEEF'], type: ColorType.SOLID },
    { label: 'Light Cyan Fill', values: ['#39859A'], displayValues: ['#39859A'], type: ColorType.SOLID },
    { label: 'Dark Blue Fill', values: ['#0E516E'], displayValues: ['#0E516E'], type: ColorType.SOLID },
    { label: 'Green Fill', values: ['#8FC44A'], displayValues: ['#8FC44A'], type: ColorType.SOLID },
    { label: 'Dark Green Fill', values: ['#628D33'], displayValues: ['#628D33'], type: ColorType.SOLID },
    { label: 'Yellow Fill', values: ['#F1BF36'], displayValues: ['#F1BF36'], type: ColorType.SOLID },
    { label: 'Orange Fill', values: ['#F7941E'], displayValues: ['#F7941E'], type: ColorType.SOLID },
    { label: 'Red Fill', values: ['#EF4136'], displayValues: ['#EF4136'], type: ColorType.SOLID },
    { label: 'Dark Red Fill', values: ['#BE1E2D'], displayValues: ['#BE1E2D'], type: ColorType.SOLID },
    {
        label: 'Light Gray Fill',
        values: ['#58595B'],
        displayValues: ['#58595B'],
        type: ColorType.SOLID,
    },
    {
        label: 'Dark Gray Fill',
        values: ['#808285'],
        displayValues: ['#808285'],
        type: ColorType.SOLID,
    },
    {
        label: 'Block Fill',
        values: ['#BCBEC0'],
        displayValues: ['#BCBEC0'],
        type: ColorType.SOLID,
    }
];

export const multiColorConfig = [
    {
        label: '#053A65',
        values: ['#053A65'],
        displayValues: ['#053A65'],
        type: ColorType.MULTICOLORED,
    },
    {
        label: '#0E516E',
        values: ['#0E516E'],
        displayValues: ['#0E516E'],
        type: ColorType.MULTICOLORED,
    },
    {
        label: '#0D7075',
        values: ['#0D7075'],
        displayValues: ['#0D7075'],
        type: ColorType.MULTICOLORED,
    },
    {
        label: '#32859A',
        values: ['#32859A'],
        displayValues: ['#32859A'],
        type: ColorType.MULTICOLORED,
    },
    {
        label: '#26A9E0',
        values: ['#26A9E0'],
        displayValues: ['#26A9E0'],
        type: ColorType.MULTICOLORED,
    },
    {
        label: '#1BAEBD',
        values: ['#1BAEBD'],
        displayValues: ['#1BAEBD'],
        type: ColorType.MULTICOLORED,
    },
    {
        label: '#92BAAF',
        values: ['#92BAAF'],
        displayValues: ['#92BAAF'],
        type: ColorType.MULTICOLORED,
    },
    {
        label: '#C0DFD2',
        values: ['#C0DFD2'],
        displayValues: ['#C0DFD2'],
        type: ColorType.MULTICOLORED,
    },
    {
        label: '#B6CEAD',
        values: ['#B6CEAD'],
        displayValues: ['#B6CEAD'],
        type: ColorType.MULTICOLORED,
    },
    {
        label: '#90CDBA',
        values: ['#90CDBA'],
        displayValues: ['#90CDBA'],
        type: ColorType.MULTICOLORED,
    },
    {
        label: '#CEDC60',
        values: ['#CEDC60'],
        displayValues: ['#CEDC60'],
        type: ColorType.MULTICOLORED,
    },
    {
        label: '#A4C439',
        values: ['#A4C439'],
        displayValues: ['#A4C439'],
        type: ColorType.MULTICOLORED,
    },
    {
        label: '#628D33',
        values: ['#628D33'],
        displayValues: ['#628D33'],
        type: ColorType.MULTICOLORED,
    },
    {
        label: '#4E666E',
        values: ['#4E666E'],
        displayValues: ['#4E666E'],
        type: ColorType.MULTICOLORED,
    },
    {
        label: '#7671A3',
        values: ['#7671A3'],
        displayValues: ['#7671A3'],
        type: ColorType.MULTICOLORED,
    },
    {
        label: '#B3D1D6',
        values: ['#B3D1D6'],
        displayValues: ['#B3D1D6'],
        type: ColorType.MULTICOLORED,
    },
    {
        label: '#DCDFDE',
        values: ['#DCDFDE'],
        displayValues: ['#DCDFDE'],
        type: ColorType.MULTICOLORED,
    },
    {
        label: '#FFF17A',
        values: ['#FFF17A'],
        displayValues: ['#FFF17A'],
        type: ColorType.MULTICOLORED,
    },
    {
        label: '#F1BF36',
        values: ['#F1BF36'],
        displayValues: ['#F1BF36'],
        type: ColorType.MULTICOLORED,
    },
    {
        label: '#F6921E',
        values: ['#F6921E'],
        displayValues: ['#F6921E'],
        type: ColorType.MULTICOLORED,
    },
    {
        label: '#F05C38',
        values: ['#F05C38'],
        displayValues: ['#F05C38'],
        type: ColorType.MULTICOLORED,
    },
];

export const mirroredBarAndLineColorConfig = [
    {
        label: '#053A65',
        values: ['#053A65'],
        displayValues: ['#053A65'],
        type: ColorType.SOLID,
    },
    {
        label: '#0E516E',
        values: ['#0E516E'],
        displayValues: ['#0E516E'],
        type: ColorType.SOLID,
    },
    {
        label: '#0D7075',
        values: ['#0D7075'],
        displayValues: ['#0D7075'],
        type: ColorType.SOLID,
    },
    {
        label: '#32859A',
        values: ['#32859A'],
        displayValues: ['#32859A'],
        type: ColorType.SOLID,
    },
    {
        label: '#26A9E0',
        values: ['#26A9E0'],
        displayValues: ['#26A9E0'],
        type: ColorType.SOLID,
    },
    {
        label: '#1BAEBD',
        values: ['#1BAEBD'],
        displayValues: ['#1BAEBD'],
        type: ColorType.SOLID,
    },
    {
        label: '#92BAAF',
        values: ['#92BAAF'],
        displayValues: ['#92BAAF'],
        type: ColorType.SOLID,
    },
    {
        label: '#C0DFD2',
        values: ['#C0DFD2'],
        displayValues: ['#C0DFD2'],
        type: ColorType.SOLID,
    },
    {
        label: '#B6CEAD',
        values: ['#B6CEAD'],
        displayValues: ['#B6CEAD'],
        type: ColorType.SOLID,
    },
    {
        label: '#90CDBA',
        values: ['#90CDBA'],
        displayValues: ['#90CDBA'],
        type: ColorType.SOLID,
    },
    {
        label: '#CEDC60',
        values: ['#CEDC60'],
        displayValues: ['#CEDC60'],
        type: ColorType.SOLID,
    },
    {
        label: '#A4C439',
        values: ['#A4C439'],
        displayValues: ['#A4C439'],
        type: ColorType.SOLID,
    },
    {
        label: '#628D33',
        values: ['#628D33'],
        displayValues: ['#628D33'],
        type: ColorType.SOLID,
    },
    {
        label: '#4E666E',
        values: ['#4E666E'],
        displayValues: ['#4E666E'],
        type: ColorType.SOLID,
    },
    {
        label: '#7671A3',
        values: ['#7671A3'],
        displayValues: ['#7671A3'],
        type: ColorType.SOLID,
    },
    {
        label: '#B3D1D6',
        values: ['#B3D1D6'],
        displayValues: ['#B3D1D6'],
        type: ColorType.SOLID,
    },
    {
        label: '#DCDFDE',
        values: ['#DCDFDE'],
        displayValues: ['#DCDFDE'],
        type: ColorType.SOLID,
    },
    {
        label: '#FFF17A',
        values: ['#FFF17A'],
        displayValues: ['#FFF17A'],
        type: ColorType.SOLID,
    },
    {
        label: '#F1BF36',
        values: ['#F1BF36'],
        displayValues: ['#F1BF36'],
        type: ColorType.SOLID,
    },
    {
        label: '#F6921E',
        values: ['#F6921E'],
        displayValues: ['#F6921E'],
        type: ColorType.SOLID,
    },
    {
        label: '#F05C38',
        values: ['#F05C38'],
        displayValues: ['#F05C38'],
        type: ColorType.SOLID,
    },
];

export const lineColorConfig = [
    {
        label: LINE_CHART_COLOR_FILL,
        values: [
            '#26A9E0', '#A4C439', '#32859A', '#F1BF36', '#B3D1D6', '#F05C38', '#0E516E',
            '#628D33', '#B6CEAD', '#CEDC60', '#F6921E', '#4E666E', '#90CDBA', '#0D7075',
            '#7671A3', '#FFF17A', '#92BAAF', '#1BAEBD', '#053A65', '#C0DFD2', '#DCDFDE',
        ],
        displayValues: [
            '#26A9E0', '#A4C439', '#32859A', '#F1BF36', '#B3D1D6', '#F05C38', '#0E516E',
            '#628D33', '#B6CEAD', '#CEDC60', '#F6921E', '#4E666E', '#90CDBA', '#0D7075',
            '#7671A3', '#FFF17A', '#92BAAF', '#1BAEBD', '#053A65', '#C0DFD2', '#DCDFDE',
        ],
        type: ColorType.SOLID,
    },
];
