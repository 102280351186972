import { NgModule } from '@angular/core';

import { OnResizeDirective } from './on-resize/on-resize.directive';

@NgModule({
    declarations: [
        OnResizeDirective,
    ],
    exports: [
        OnResizeDirective
    ]
})
export class ChartsDirectivesModule {}
