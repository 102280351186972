import { Inject, Injectable } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { ApiExecutorService, ApiServices } from '@ddv/http';
import { UserWithType, UserPreferences } from '@ddv/models';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserService {
    public readonly userPreferences$: Observable<UserPreferences>;

    private readonly userPreferences: Subject<UserPreferences> = new ReplaySubject(1);
    private clientCode = '';

    constructor(
        @Inject(ApiServices.ddvMW) private readonly ddvApiService: ApiExecutorService,
        private readonly currentState: CurrentStateService,
    ) {
        this.userPreferences$ = this.userPreferences.asObservable();

        this.currentState.clientCode$.subscribe((clientCode) => {
            this.clientCode = clientCode;
            this.fetchUserPreferenceData();
        });
    }

    private fetchUserPreferenceData(): void {
        this.ddvApiService.invokeServiceWithParams<Partial<UserPreferences>>(this.clientCode, 'dashboard/userpreference')
            .subscribe((userPreferences: Partial<UserPreferences>) => {
                userPreferences.fundsCodes = userPreferences.funds?.map((x) => x.fundCode);
                userPreferences.clientsCodes = userPreferences.clients?.map((x) => x.clientCode);
                this.userPreferences.next(new UserPreferences(userPreferences));
            });
    }

    updateUserPreferenceData(userPreference: UserPreferences): Observable<UserPreferences> {
        const payload: UserPreferences = { ...userPreference };

        payload.funds = payload.fundsCodes.map((fundCode) => ({ fundCode }));
        payload.clients = payload.clientsCodes.map((clientCode) => ({ clientCode }));
        return this.ddvApiService.invokeServiceWithBody<UserPreferences>(this.clientCode, 'dashboard/userpreference/update', 'POST', payload)
            .pipe(map((meh: UserPreferences): UserPreferences => {
                meh.fundsCodes = meh.funds?.map((x) => x.fundCode) ?? [];
                meh.clientsCodes = meh.clients?.map((x) => x.clientCode) ?? [];
                const updatedPreferences: UserPreferences = new UserPreferences(meh);
                this.userPreferences.next(updatedPreferences);
                return updatedPreferences;
            }));
    }

    // should be a "dashboard list" service
    openDashboard(dashboardId: string): Observable<void | string> {
        return this.ddvApiService.invokeService<void>(this.clientCode, `user/state/dashboards/open/${dashboardId}`, 'POST');
    }

    // should be a "dashboard list" service
    updateOpenDashboardOrder(dashboardIdsInOrder: string[]): Observable<void> {
        return this.ddvApiService.invokeServiceWithBody(this.clientCode, 'user/state/dashboards/set-order', 'POST', dashboardIdsInOrder);
    }

    // should be a "dashboard list" service
    getOpenDashboardOrderedIds(clientCode?: string): Observable<number[]> {
        return this.ddvApiService.invokeServiceWithParams(clientCode ?? this.clientCode, 'user/state/dashboards/get-order');
    }

    // should be in some kind of "sharing" service
    fetchUserListForPermissions(featureType?: 'DASHBOARD' | 'WIDGET', clientCode?: string): Observable<UserWithType[]> {
        const permissionsUrl = featureType === 'DASHBOARD' ? 'user/peers' : 'widgets/peers';
        return this.ddvApiService.invokeServiceWithParams(clientCode ?? this.clientCode, permissionsUrl);
    }
}
