import { FuzzyDate } from './fuzzy-date';

export class FuzzyDates {
    to: FuzzyDate[];
    from: FuzzyDate[];

    constructor(to?: FuzzyDate[], from?: FuzzyDate[]) {
        this.to = to ?? [];
        this.from = from ?? [];
    }
}

export { };
declare global {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface Array<T> {
        findByName(name?: string): FuzzyDate | undefined;
    }
}

if (!Array.prototype.findByName) {
    Array.prototype.findByName = function (name?: string): FuzzyDate | undefined {
        return this.find((fuzzyDate) => fuzzyDate.name.toLocaleUpperCase() === name?.toLocaleUpperCase());
    };
}
