<div
    [id]="widgetConfig!.id"
    class="ngPopup widget"
    [ngClass]="(widgetConfig!.styleClasses + ' ' + widgetConfig!.selectedClassName).trim()"
    [class.view-mode]="mode === 'view'"
    [style.display]="widgetConfig!.display"
    [style.width]="widgetConfig!.width+'px'"
    [style.height]="widgetConfig!.height+'px'"
    [style.top]="widgetConfig!.top+'px'"
    [style.left]="widgetConfig!.left+'px'"
    [style.position]="widgetConfig!.position"
    [style.z-index]="widgetConfig!.zIndex"
    mwlResizable
    [enableGhostResize]="canResize()"
    (resizeStart)="onResizeStart()"
    (resizing)="onResizing($event)"
    (resizeEnd)="onResizeEnd($event)"
    (mousedown)="onSelectWidget($event)"
>
    <div class="container widget-wrapper" #widget>
        <div
            class="titleBar widget-header"
            (dblclick)="onHeaderDoubleClick()"
            appWidgetDrag
            [isDraggable]="(isWidgetDraggable())"
            [dragImagePath]="widgetConfig!.dragImagePath ?? ''"
            (dragStart)="onDragStart()"
            (dragEnd)="onDragEnd($event)"
        >
            <span class="title widget-title">
                {{ widgetConfig!.title }}
                <app-master-widget-icon *ngIf="isMasterWidget()"></app-master-widget-icon>
                <app-unsubscribed-widget-icon *ngIf="isUnsubscribedWidget()"></app-unsubscribed-widget-icon>
            </span>

            <app-loader [isDataLoading]="showLoader" [isWidgetLoader]="true"></app-loader>

            <div class="widget-actions">
                <div
                    *ngFor="let menuOption of widgetConfig!.menuOptions"
                    class="menu-options {{'iconGroup '+menuOption.selector+((menuOption.disabled && !menuOption.iconName) ? ' disabled' : '')}}"
                    title="{{menuOption.title}}"
                >
                    <ng-container *ngIf="!menuOption.hidden && (!menuOption.styleClasses || menuOption.styleClasses.indexOf('stacked-area') === -1)">
                        <i
                            *ngIf="!menuOption.iconName; else ccIconTemplate"
                            id="{{menuOption.selector}}"
                            class="{{menuOption.styleClasses}}"
                            [ngClass]="{'readonly': menuOption.selector === 'closeBtn' && isWorkspaceReadonly || menuOption.selector === 'settingsOverridesBtn' && isWorkspaceReadonly && mode === 'edit'}"
                            (dblclick)="onMenuItemDoubleClick($event)"
                            (click)="getMenuItemClickHandler(menuOption, $event)"
                        >
                        </i>

                        <ng-template #ccIconTemplate>
                            <cc-icon
                                id="{{menuOption.selector}}"
                                class="cc-widget-header-icon"
                                [icon]="menuOption.iconName!"
                                [size]="menuOption.iconName === 'chevron-down2' ? 'medium' : 'small'"
                                [disabled]="menuOption.disabled || (menuOption.selector === 'closeBtn' && isWorkspaceReadonly || menuOption.selector === 'settingsOverridesBtn' && isWorkspaceReadonly && mode === 'edit')"
                                (dblclick)="onMenuItemDoubleClick($event)"
                                (click)="getMenuItemClickHandler(menuOption, $event)"
                            >
                            </cc-icon>
                        </ng-template>
                    </ng-container>

                    <ddv-stacked-area-chart-icon
                        *ngIf="!menuOption.hidden && menuOption.styleClasses && menuOption.styleClasses.indexOf('stacked-area') !== -1"
                        id="{{menuOption.selector}}"
                        class="{{menuOption.styleClasses}}"
                        [ngClass]="{'readonly': menuOption.selector === 'closeBtn' && isWorkspaceReadonly || menuOption.selector === 'settingsOverridesBtn' && isWorkspaceReadonly && mode === 'edit'}"
                        (dblclick)="onMenuItemDoubleClick($event)"
                        (click)="getMenuItemClickHandler(menuOption, $event)"
                    >
                    </ddv-stacked-area-chart-icon>
                </div>

                <div class="iconGroup menu-options" *ngIf="showExportOption">
                    <cc-icon class='cc-widget-header-icon' [icon]="'logout'" [size]="'small'" title="CSV Export" [disabled]="showLoader" (iconClicked)="exportWidget()"></cc-icon>
                </div>

                <div *ngIf="isGrid && areFiltersApplied" class="iconGroup">
                    <cc-icon
                        class="cc-widget-header-icon"
                        [icon]="'clear-filter'"
                        [size]="'small'"
                        title="Clear All Filters"
                        (iconClicked)="clearAllFilters()">
                    </cc-icon>
                </div>
            </div>
        </div>

        <div #widgetContentWrapper class="content" [style.display]="widgetContentDisplay">
            <div #widgetContent></div>
        </div>
    </div>

    <ng-container *ngIf="canResize()">
        <div class="resize-icon-right" mwlResizeHandle [resizeEdges]="{right: true}">
            <span class="icon-resize-arrow"></span>
        </div>

        <div class="resize-icon-corner" mwlResizeHandle [resizeEdges]="{right: true, bottom: true}">
            <span class="icon-resize-arrow"></span>
        </div>

        <div class="resize-icon-bottom" mwlResizeHandle [resizeEdges]="{bottom: true}">
            <span class="icon-resize-arrow"></span>
        </div>
    </ng-container>
</div>

<ng-template #removeWidgetTemplate>
    <div class="remove-message">{{ widgetRemovalMessage }}</div>
    <div class="remove-widget">{{ widgetConfig!.title }}</div>
</ng-template>
