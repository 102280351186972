import { animate, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CurrentStateService, DashboardModeAndId } from '@ddv/behaviors';
import { ModalDialogService, MultiSubscriptionComponent } from '@ddv/common-components';
import { DataFastnessService, MetadataService } from '@ddv/datasets';
import { UserService } from '@ddv/entitlements';
import { QueryParamsService } from '@ddv/filters';
import { ManagerService } from '@ddv/layout';
import {
    BoardWidgetDefinitionIds,
    Client,
    FILTER_TYPE,
    MANAGE_WIDGET_ID,
    MODE,
    CompareMode,
    DashboardPreference,
    DatasetDefinition,
    DatasetDefinitionDetails,
    FastnessResponse,
    QueryPeriodTypeName,
    DateRangeString,
    DdvDate,
    UserPreferences,
    DashboardFilter,
    FilterPreference,
    Fund,
    FuzzyDates,
    AppWidgetState,
    isTradeFileDetails,
    isTFLIncompleteFiles,
    NamedQuery,
} from '@ddv/models';
import { isNamedQuery } from '@ddv/named-queries';
import { ClientsService, FundsService, FuzzyDatesService } from '@ddv/reference-data';
import { deepClone } from '@ddv/utils';
import { DropdownOption } from '@hs/ui-core-common';
import { combineLatest, Observable, Subscription } from 'rxjs';

import { DashboardService } from '../../services/dashboard.service';
import { DdvWidgetService } from '../../services/ddv-widget.service';
import { WidgetQueryparamService } from '../../services/widget-queryparam.service';
import { DatasetFiltersModalComponent } from '../dataset-filters-modal/dataset-filters-modal.component';

const DATE_FORMAT = 'M/DD/YYYY';
const DEFAULT_SELECTED_CLIENT =
    document.location.href.includes('localhost') || document.location.href.includes('hsuat') ? 'UT58UAT' : 'MLTIPROD';

// this is used by both the views and manage-widgets features, so move to dashboard
@Component({
    selector: 'app-dashboard-filters',
    templateUrl: 'dashboard-filters.component.html',
    styleUrls: ['dashboard-filters.component.scss'],
    animations: [
        trigger(
            'enterLeaveAnimation',
            [
                transition(':enter', [style({ width: 0, opacity: 0 }), animate('0.2s ease-out', style({ width: '*', opacity: 1 }))]),
                transition(':leave', [style({ width: '*', opacity: 1 }), animate('0.2s ease-out', style({ width: 0, opacity: 0 }))]),
            ],
        ),
    ],
})
export class DashboardFiltersComponent extends MultiSubscriptionComponent implements OnChanges, OnInit, OnDestroy {
    @Input() reducer = 'queryParamReducer';
    @Input() filterType = 'dashboard';
    @Input() widgetId = 0;
    @Input() showRestore = true;
    @Input() isSubscribedToDashboardFilters = false;
    @Input() showActiveDateToggle = false;
    @Input() showActiveDateDialogToggle = false;
    @Input() isActiveDate = false;
    @Input() showHighlight = false;
    @Input() filterTitle: string | undefined;
    @Input() clientCode = '';
    @Input() isMultiClient = false;
    @Input() userSelectedDate: { dateFrom: string, dateTo: string } | undefined;
    @Input() filterPreference: FilterPreference | undefined;
    @Input() bwd: BoardWidgetDefinitionIds | undefined;
    @Input() showAdditionalFilters = true;
    @Input() isQueryTypeRecon = false;
    @Input() isHIDataAvailable = false;
    @Input() currentActiveDate: string | null | undefined;
    @Input() currentDashboardId: string | undefined;
    @Input() datasetDefinition: DatasetDefinition | NamedQuery | undefined;

    @Output() fundsApply = new EventEmitter<Fund[]>();
    @Output() clientsApply = new EventEmitter<Client[]>();
    @Output() restoreBtnClicked = new EventEmitter();
    @Output() refreshBtnClicked = new EventEmitter();
    @Output() filterApply = new EventEmitter<DashboardFilter[]>();
    @Output() dateFilterApply = new EventEmitter();
    @Output() clearHighlight = new EventEmitter();
    @Output() dashboardExport = new EventEmitter();

    @ViewChild('fastnessCheckTemplate') fastnessCheckTemplate: TemplateRef<string> | undefined;

    showFundsDashboardForm = false;
    showClientsDashboardForm = false;
    showRangeDatepicker = false;
    showActiveDatePickerDialog = false;
    showRestoreModal = false;
    isCompareDateChecked = false;
    isConfirmationPopupOpen = false;
    isCancelClicked = false;
    initPickerDates: DateRangeString = {};
    queryParams: FilterPreference = {};
    funds: Fund[] | undefined;
    selectedFundsCount = 0;
    clients: Client[] | undefined;
    clientOptions: DropdownOption[] = [];
    selectedClient: DropdownOption | undefined;
    selectedClientsCount = 0;
    isActiveDateSupported = false;
    isDateRangeSupported = false;
    isCalendarVisible = false;
    datasetIds: string[] | undefined;
    isSingleClientPicker = false;
    dashboardModeAndId: DashboardModeAndId | undefined;
    detailWidgetId = 0;
    userPreferences: UserPreferences | undefined;
    latestQueryParams: DashboardPreference | undefined;
    fuzzyDates: FuzzyDates = new FuzzyDates();
    // these two variables are only used in the template
    validateDate = DdvDate.isStringValidDate;
    dateFormat = DATE_FORMAT;

    protected showSingleClientPickerOnDetailWidget = false;

    private queryParamsObserver: Subscription | undefined;
    private urlQueryParams: FilterPreference = {};
    private initialLoad = true;

    constructor(
        private readonly modalService: ModalDialogService,
        private readonly manager: ManagerService,
        private readonly widgetQueryparamService: WidgetQueryparamService,
        private readonly fuzzyDatesService: FuzzyDatesService,
        private readonly cdRef: ChangeDetectorRef,
        private readonly queryParamsService: QueryParamsService,
        private readonly fundsService: FundsService,
        private readonly clientsService: ClientsService,
        private readonly dataFastnessService: DataFastnessService,
        private readonly dashboardService: DashboardService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly currentStateService: CurrentStateService,
        private readonly ddvWidgetService: DdvWidgetService,
        private readonly userService: UserService,
        private readonly metadataService: MetadataService,
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.isMultiClient) {
            this.subscribeTo(this.clientsService.clients(), (clients) => {
                this.clientOptions = clients.map((c) => ({ value: c, text: c }));
                this.clients = clients.map((c) => ({ clientId: c, clientName: c }));
            });
        } else {
            this.subscribeTo(this.fundsService.funds(), (funds: Fund[]) => this.funds = funds);
        }

        this.activatedRoute.queryParams?.subscribe((urlQueryParams) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let queryPeriodType: any;
            if (Object.keys(urlQueryParams).length) {
                const dates = urlQueryParams.dates ?
                    JSON.parse(decodeURIComponent(urlQueryParams.dates)) :
                    null;
                const funds = {
                    funds: urlQueryParams.funds ?
                        JSON.parse(decodeURIComponent(urlQueryParams.funds)) :
                        [],
                };
                const clients = {
                    clients: urlQueryParams.clients ?
                        JSON.parse(decodeURIComponent(urlQueryParams.clients)) :
                        [],
                };

                queryPeriodType = urlQueryParams.queryPeriodType ?
                    JSON.parse(decodeURIComponent(urlQueryParams.queryPeriodType)) :
                    null;

                this.urlQueryParams = { ...dates, ...funds, ...clients };
            }

            if (this.filterPreference) {
                this.setQueryParams(this.filterPreference);
            } else {
                if (this.filterType === FILTER_TYPE.DASHBOARD) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    let queryParamsSubject: Observable<any>;
                    if (this.reducer === 'queryParamReducer') {
                        queryParamsSubject = this.queryParamsService.dashboardQueryParams;
                    } else {
                        queryParamsSubject = this.queryParamsService.widgetQueryParams;
                    }

                    if (queryParamsSubject) {
                        this.queryParamsObserver = queryParamsSubject.subscribe((params: DashboardPreference) => {
                            if (params) {
                                params.isPreferenceChangedExplicitly = true;
                                this.latestQueryParams = params;

                                if (Object.keys(this.urlQueryParams).length && this.reducer === 'queryParamReducer') {
                                    switch (queryPeriodType) {
                                        case QueryPeriodTypeName.POINT_IN_TIME:
                                            params.endDate = this.urlQueryParams.endDate;
                                            break;
                                        case QueryPeriodTypeName.RANGE:
                                            params.startDate = this.urlQueryParams.startDate;
                                            params.endDate = this.urlQueryParams.endDate;
                                            break;
                                        case QueryPeriodTypeName.RANGE_WITH_ACTIVE_DATE:
                                            params.startDate = this.urlQueryParams.startDate;
                                            params.endDate = this.urlQueryParams.endDate;
                                            params.activeDate = this.urlQueryParams.activeDate;
                                    }
                                    Object.assign(params.clients!, this.urlQueryParams.clients);
                                    Object.assign(params.funds!, this.urlQueryParams.funds);

                                    this.urlQueryParams = {};
                                }

                                this.setQueryParams(params);
                            }
                        });
                    }
                } else {
                    const widgetFilterParams = this.widgetQueryparamService.widgetFilterParams.get(this.widgetId);
                    if (widgetFilterParams) {
                        this.setQueryParams(widgetFilterParams);

                        this.currentActiveDate = widgetFilterParams?.activeDate;
                    } else if (!window.location.hash.includes('widgets/edit/none')) {
                        this.currentActiveDate = null;
                    }
                }
            }
        });

        this.subscribeTo(this.fuzzyDatesService.fuzzyDates(), (fuzzyLists: FuzzyDates) => {
            this.fuzzyDates = { ...fuzzyLists };
        });

        this.subscribeTo(this.manager.isDateCompareActive$, (isDateCompareActive) => {
            this.isCompareDateChecked = isDateCompareActive;
        });

        this.setCalendarSupport();

        if (this.isMultiClient) {
            this.setMultiClient();
        }

        this.subscribeTo(this.dashboardService.widgetAddedToBoard, () => {
            this.setCalendarSupport();

            if (this.isMultiClient) {
                this.setMultiClient();
            }
        });

        this.subscribeTo(this.dashboardService.widgetRemovedFromBoard, () => {
            this.setCalendarSupport();

            if (this.isMultiClient) {
                this.setMultiClient();
            }
        });

        this.subscribeTo(combineLatest(
            [this.ddvWidgetService.detailWidgetId, this.dashboardService.datePickerInfo, this.currentStateService.dashboardModeAndId$]),
        ([widgetId, { isCalendarVisible, isDateRangeSupported, isActiveDateSupported }, dashboardModeAndId]) => {
            this.detailWidgetId = widgetId ?? 0;
            this.dashboardModeAndId = dashboardModeAndId;
            if (this.detailWidgetId) {
                this.isCalendarVisible = isCalendarVisible;
                this.isDateRangeSupported = isDateRangeSupported;
                this.isActiveDateSupported = isActiveDateSupported;
                if (this.isTFLDetailsOrIncompleteFiles(this.getCurrentWidgetDataset(this.detailWidgetId)?.queryType?.name ?? '')) {
                    this.setAndEmitSelectedClient();
                }

                if (this.dashboardModeAndId.mode === MODE.VIEW) {
                    this.setQueryParamsDatesForDetailWidget();
                }

                if (this.dashboardModeAndId.mode === 'edit') {
                    this.queryParams.startDate = this.latestQueryParams?.startDate;
                    this.queryParams.endDate = this.latestQueryParams?.endDate;
                    this.queryParams.activeDate = this.latestQueryParams?.activeDate;
                }
            }
        });

        this.subscribeTo(this.userService.userPreferences$, (userPreferences) => {
            this.userPreferences = userPreferences;
        });

        this.subscribeTo(
            combineLatest([this.metadataService.actionsState, this.ddvWidgetService.currentOpenedDetailWidgetId]),
            ([actionState, id]) => {
                const atLeastOnWidgetHasActions = Object.values(actionState).some((actions) => actions.length > 0);
                this.showSingleClientPickerOnDetailWidget = !!id && atLeastOnWidgetHasActions;
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.hasDashboardChanged(changes) || changes.datasetDefinition) {
            this.setCalendarSupport();

            if (this.isMultiClient) {
                this.setMultiClient();
            }
        }
    }

    setQueryParams(params: FilterPreference | DashboardPreference | undefined): void {
        if (!params) {
            return;
        }

        this.queryParams.startDate = params.startDate;
        this.queryParams.endDate = params.endDate;
        this.queryParams.activeDate = params.activeDate;

        if (this.detailWidgetId && this.dashboardModeAndId?.mode === 'view') {
            this.setQueryParamsDatesForDetailWidget();
        }

        if (this.funds && params.funds?.length && !(params.funds[0] instanceof Fund)) {
            const fundIds: string[] = params.funds.map((fund: { fundId: string, fundName: string }) => fund.fundId);
            this.queryParams.funds = this.funds.filter((f) => f.isIncludedInArrayOfIds(fundIds));
        } else {
            this.queryParams.funds = params.funds;
        }
        this.queryParams.clients = params.clients;
        // remove saved UDF filters
        // until we start fetching the data for UDF from Trebek
        this.queryParams.filters = params.filters?.filter((f) => !f.name?.startsWith('udf_'));
        this.queryParams.highlight = params.highlight;
        if (params.isComparing) {
            this.queryParams.isComparing = params.isComparing;
            this.queryParams.compareDates = params.compareDates;
            this.queryParams.comparing = params.comparing;
        }
        this.selectedFundsCount = this.queryParams.funds && this.funds ?
            this.queryParams.funds.filter((f) => this.funds?.findIndex((fo) => fo.sameAs(f)) !== -1).length :
            0;
        this.selectedClientsCount = this.queryParams.clients?.length ?? 0;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (!(this.cdRef as any).destroyed) {
            this.cdRef.detectChanges();
        }

        if (this.isMultiClient && !this.detailWidgetId) {
            this.setSelectedClient();
        }
    }

    getActiveDateRange(): string {
        const datePipe = new DatePipe('en-US');
        /* eslint-disable eqeqeq */
        this.isActiveDate = this.queryParams.activeDate !== null;
        return this.queryParams.activeDate === null ?
        /* eslint-enable eqeqeq */
            'RANGE' :
            (DdvDate.isStringValidDate(this.queryParams.activeDate) ?
                datePipe.transform(this.queryParams.activeDate, 'MMM dd yyyy') ?? '' :
                this.queryParams.activeDate ?
                    datePipe.transform(this.getDateFromFuzzy('from', this.queryParams.activeDate), 'MMM dd yyyy') ?? '' :
                    'USE DEFAULTS');
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();

        if (this.queryParamsObserver && this.filterType === FILTER_TYPE.DASHBOARD) {
            this.queryParamsObserver.unsubscribe();
        }
    }

    toggleFundsDashboardForm(): void {
        this.showFundsDashboardForm = !this.showFundsDashboardForm;
    }

    toggleClientsDashboardForm(): void {
        this.showClientsDashboardForm = !this.showClientsDashboardForm;
    }

    isSelectedActiveDateInRange(activeDateString: string): boolean {
        const startDate = this.getValidDateForPicker('from', this.queryParams.startDate ?? '');
        const endDate = this.getValidDateForPicker('to', this.queryParams.endDate ?? '');
        const activeDate = this.getValidDateForPicker('from', activeDateString);
        if (activeDate && startDate && endDate) {
            return DdvDate.fromUSFormat(activeDate).toMilliseconds() <= DdvDate.fromUSFormat(endDate).toMilliseconds() &&
                DdvDate.fromUSFormat(activeDate).toMilliseconds() >= DdvDate.fromUSFormat(startDate).toMilliseconds();
        }
        return false;
    }

    isActiveDateNotInRange(pickerDate: string | DateRangeString | undefined): boolean {
        if (pickerDate === undefined) {
            return true;
        }
        const startDate = this.getValidDateForPicker('from', typeof pickerDate === 'string' ? pickerDate : pickerDate.dateFrom ?? '');
        const endDate = this.getValidDateForPicker('to', typeof pickerDate === 'string' ? pickerDate : pickerDate.dateTo ?? '');
        const activeDate = this.getValidDateForPicker('from', this.queryParams.activeDate ?? '');
        if (activeDate && startDate && endDate) {
            return (DdvDate.fromUSFormat(activeDate).toMilliseconds() >= DdvDate.fromUSFormat(endDate).toMilliseconds() ||
                    DdvDate.fromUSFormat(activeDate).toMilliseconds() <= DdvDate.fromUSFormat(startDate).toMilliseconds());
        }
        return true;
    }

    toggleRangeDatepickers(): void {
        if (this.isCompareDateChecked) {
            return;
        }
        if (!this.showRangeDatepicker) {
            this.initPickerDates = {
                dateFrom: this.queryParams.startDate ?? '',
                dateTo: this.queryParams.endDate ?? '',
            };
        }
        this.showRangeDatepicker = !this.showRangeDatepicker;
    }

    toggleActiveDatePickerDialog(): void {
        if (this.isCompareDateChecked) {
            return;
        }
        this.showRangeDatepicker = false;
        this.showActiveDatePickerDialog = !this.showActiveDatePickerDialog;
    }

    checkPickerSelectedDates(selectedDate: string | DateRangeString | undefined): void {
        if (this.isQueryTypeRecon) {
            let dateFrom: string;
            let dateTo: string;
            if (selectedDate && (((selectedDate as DateRangeString)?.dateFrom && (selectedDate as DateRangeString)?.dateTo) ||
                typeof selectedDate === 'string')) {
                dateFrom = typeof selectedDate === 'string' ? selectedDate : selectedDate.dateFrom ?? '';
                dateTo = typeof selectedDate === 'string' ? selectedDate : selectedDate.dateTo ?? '';
            } else {
                const hsDefaults = this.dashboardService.getDefaultQueryParams(
                    [],
                    [],
                    this.manager.getExtraParametersForWorkspace()); // we don't care for funds and clients here
                dateFrom = this.userSelectedDate?.dateFrom ?? hsDefaults.startDate ?? '';
                dateTo = this.userSelectedDate?.dateTo ?? hsDefaults.endDate ?? '';
            }
            const queryType = 'rc2';
            const endDate = DdvDate.fromUSFormat(this.getValidDateForPicker('to', dateTo)).toReversePaddedDashFormat();
            this.dataFastnessService.checkDataForDateCache(this.clientCode, dateFrom, dateTo, queryType, this.fuzzyDates)
                .subscribe((result: FastnessResponse) => {
                    if (result[queryType]?.[endDate] && !result[queryType][endDate].fast) {
                        this.isConfirmationPopupOpen = true;
                        this.dataFastnessService.openCacheDialog(this.fastnessCheckTemplate)
                            .subscribe({
                                next: (action) => {
                                    this.isConfirmationPopupOpen = false;

                                    if (action === 'confirm') {
                                        this.applyPickerSelectedDates(selectedDate);
                                    } else {
                                        this.isCancelClicked = true;
                                    }
                                },
                                error: (error) => {
                                    console.error(`An error occured: ${error}`);
                                    this.isCancelClicked = true;
                                    this.isConfirmationPopupOpen = false;
                                },
                            });
                    } else {
                        this.applyPickerSelectedDates(selectedDate);
                    }
                });
        } else {
            this.applyPickerSelectedDates(selectedDate);
        }
    }

    closeRangeDatepicker(): void {
        if (this.isConfirmationPopupOpen || this.isCancelClicked) {
            this.isCancelClicked = false;
        } else {
            this.showRangeDatepicker = false;
        }
    }

    applyPickerSelectedDates(selectedDate: string | DateRangeString | undefined): void {
        const dateFrom = selectedDate != null ?
            (typeof selectedDate === 'string' ? selectedDate : selectedDate.dateFrom) :
            undefined;
        const dateTo = selectedDate != null ?
            (typeof selectedDate === 'string' ? selectedDate : selectedDate.dateTo) :
            undefined;
        const activeDate = !this.isActiveDate || !this.isActiveDateSupported ?
            undefined :
            (this.isActiveDateNotInRange(selectedDate) ?
                dateTo :
                this.queryParams.activeDate);
        this.dateFilterApply.emit({
            startDate: dateFrom,
            endDate: dateTo,
            activeDate,
        });
        this.currentActiveDate = activeDate;
        this.showRangeDatepicker = !this.showRangeDatepicker;
    }

    openFilterDialog(): void {
        const datasetFiltersModalDialogRef = this.modalService.open(DatasetFiltersModalComponent, { windowClass: 'finder-popup' });
        datasetFiltersModalDialogRef.componentInstance.widgetId = this.widgetId;
        datasetFiltersModalDialogRef.componentInstance.filterType = this.filterType;
        datasetFiltersModalDialogRef.componentInstance.dashboardId = this.manager.getWorkspace()?.id;
        datasetFiltersModalDialogRef.componentInstance.dashboardFilters = deepClone(this.queryParams.filters);
        datasetFiltersModalDialogRef.componentInstance.bwd = this.bwd;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        datasetFiltersModalDialogRef.componentInstance.filtersApplied.subscribe((dashboardFilters: any) => {
            this.filterApply.emit(dashboardFilters);
        });
        datasetFiltersModalDialogRef.result.then((closeData) => closeData, (dismissData) => dismissData);
    }

    applySelectedFunds(funds: Fund[]): void {
        this.showFundsDashboardForm = false;
        this.fundsApply.emit(funds);
    }

    applySelectedClients(clients: Client[] | DropdownOption): void {
        const clientsArray = Array.isArray(clients) ? clients : [{ clientId: clients.text, clientName: clients.text }];
        this.showClientsDashboardForm = false;
        this.clientsApply.emit(clientsArray);
    }

    private getValidDateForPicker(dateList: keyof FuzzyDates, date: string): string {
        if (!date) {
            return '';
        }
        return DdvDate.isStringValidDate(date) ? date : this.getDateFromFuzzy(dateList, date);
    }

    getDateFromFuzzy(dateList: keyof FuzzyDates, fuzzyDate: string): string {
        const option = this.fuzzyDates ? this.fuzzyDates?.[dateList].findByName(fuzzyDate) : undefined;
        return option?.formattedString ?? '';
    }

    restoreCurrentDashboard(): void {
        this.showRestoreModal = false;
        this.restoreBtnClicked.emit();
    }

    refreshCurrentDashboard(): void {
        this.refreshBtnClicked.emit();
    }

    updateIsActiveDate(isActiveDate: boolean): void {
        this.isActiveDate = isActiveDate;
        if (!isActiveDate) {
            this.emitActiveDate(null);
            return;
        }

        this.emitActiveDate(this.queryParams.endDate);
    }

    applyActiveDate(activeDate: string): void {
        let dateToEmit = activeDate;
        if (!dateToEmit && (this.queryParams.startDate || this.queryParams.endDate)) {
            dateToEmit = this.queryParams.endDate ?? this.queryParams.startDate ?? '';
        }

        if (this.isSelectedActiveDateInRange(dateToEmit)) {
            this.emitActiveDate(dateToEmit);
        }

        this.showActiveDatePickerDialog = false;
    }

    showSingleClientPicker(): boolean {
        return this.isSingleClientPicker || this.showSingleClientPickerOnDetailWidget;
    }

    private emitActiveDate(activeDate?: string | null): void {
        this.dateFilterApply.emit({
            activeDate,
            comparing: CompareMode.ORIGINAL,
            initialLoad: this.initialLoad,
        });
        this.currentActiveDate = activeDate;
        this.initialLoad = false;
    }

    private setCalendarSupport(): void {
        if (this.filterType === FILTER_TYPE.WIDGET) {
            this.setCalendarSupportUnsubscribedWidget();
        } else if (this.filterType === FILTER_TYPE.DASHBOARD) {
            this.setCalendarSupportDashboard();
        } else {
            this.setCalendarSupportCoreWidget();
        }

        if (!this.isCompareDateChecked) {
            if (this.isActiveDateSupported) {
                if (this.widgetId !== MANAGE_WIDGET_ID) {
                    // eslint-disable-next-line eqeqeq
                    const date = (this.currentActiveDate === null || this.isSelectedActiveDateInRange(this.currentActiveDate ?? '')) ?
                        this.currentActiveDate :
                        this.queryParams.endDate;
                    this.emitActiveDate(this.queryParams.activeDate === undefined ? this.queryParams.activeDate : date);
                }
            } else {
                if (this.isActiveDate) {
                    this.emitActiveDate(undefined);
                }
            }
        }
    }

    private hasDashboardChanged(changes: SimpleChanges): boolean {
        return changes.currentDashboardId && !changes.currentDashboardId.firstChange &&
            changes.currentDashboardId.previousValue !== changes.currentDashboardId.currentValue;
    }

    private getWidgetDatasetsForDashboard(): DatasetDefinitionDetails[] {
        return this.manager.getExtraParametersForWorkspace()?.widgets.map((w: AppWidgetState) => w.datasetDefinition) || [];
    }

    private getCurrentWidgetDataset(widgetId: number): DatasetDefinition | undefined {
        return this.manager.getWidgetById(widgetId)?.extraParameters?.preferences?.datasetDefinition;
    }

    private setMultiClient(): void {
        this.isSingleClientPicker = this.allowSingleClientPicker();
        this.setAndEmitSelectedClient();
    }

    private setAndEmitSelectedClient(): void {
        this.setSelectedClient();
        this.emitSelectedClient();
    }

    private setSelectedClient(): void {
        const clientId = this.queryParams.clients?.length === 1 ? this.queryParams.clients[0].clientId : DEFAULT_SELECTED_CLIENT;
        this.selectedClient = { value: clientId, text: clientId };
    }

    private emitSelectedClient(): void {
        if (this.showSingleClientPicker()) {
            this.clientsApply.emit([{ clientId: this.selectedClient?.value ?? '', clientName: this.selectedClient?.text ?? '' }]);
        }
    }

    private setCalendarSupportUnsubscribedWidget(): void {
        const widgetDsd = this.getCurrentWidgetDataset(this.widgetId);
        this.isActiveDateSupported = !widgetDsd?.queryPeriodType ||
            widgetDsd.queryPeriodType.name === QueryPeriodTypeName.RANGE_WITH_ACTIVE_DATE;
        this.isDateRangeSupported = !widgetDsd?.queryPeriodType ||
            widgetDsd.queryPeriodType.name === QueryPeriodTypeName.RANGE_WITH_ACTIVE_DATE ||
            widgetDsd.queryPeriodType.name === QueryPeriodTypeName.RANGE;
        this.isCalendarVisible = !widgetDsd?.queryPeriodType ||
            widgetDsd.queryPeriodType.name !== QueryPeriodTypeName.NO_CALENDAR;
    }

    private setCalendarSupportDashboard(): void {
        const widgetDatasets = this.getWidgetDatasetsForDashboard();
        this.isActiveDateSupported = !widgetDatasets.length || widgetDatasets.some((dsd) =>
            !dsd.queryPeriodType ||
            dsd.queryPeriodType.name === QueryPeriodTypeName.RANGE_WITH_ACTIVE_DATE);
        this.isDateRangeSupported = !widgetDatasets.length || widgetDatasets.some((dsd) =>
            !dsd.queryPeriodType ||
            dsd.queryPeriodType.name === QueryPeriodTypeName.RANGE_WITH_ACTIVE_DATE ||
            dsd.queryPeriodType.name === QueryPeriodTypeName.RANGE);
        this.isCalendarVisible = !widgetDatasets.length ||
            widgetDatasets.some((dsd) => !dsd.queryPeriodType || dsd.queryPeriodType.name !== QueryPeriodTypeName.NO_CALENDAR);
    }

    private setCalendarSupportCoreWidget(): void {
        if (isNamedQuery(this.datasetDefinition)) {
            this.isActiveDateSupported = !this.datasetDefinition?.periodType ||
                this.datasetDefinition.periodType === QueryPeriodTypeName.RANGE_WITH_ACTIVE_DATE;
            this.isDateRangeSupported = !this.datasetDefinition?.periodType ||
                this.datasetDefinition.periodType === QueryPeriodTypeName.RANGE_WITH_ACTIVE_DATE ||
                this.datasetDefinition.periodType === QueryPeriodTypeName.RANGE;
            this.isCalendarVisible = !this.datasetDefinition?.periodType ||
                this.datasetDefinition.periodType !== QueryPeriodTypeName.NO_CALENDAR;
        } else {
            this.isActiveDateSupported = !this.datasetDefinition?.queryPeriodType ||
                this.datasetDefinition.queryPeriodType.name === QueryPeriodTypeName.RANGE_WITH_ACTIVE_DATE;
            this.isDateRangeSupported = !this.datasetDefinition?.queryPeriodType ||
                this.datasetDefinition.queryPeriodType.name === QueryPeriodTypeName.RANGE_WITH_ACTIVE_DATE ||
                this.datasetDefinition.queryPeriodType.name === QueryPeriodTypeName.RANGE;
            this.isCalendarVisible = !this.datasetDefinition?.queryPeriodType ||
                this.datasetDefinition.queryPeriodType.name !== QueryPeriodTypeName.NO_CALENDAR;
        }
    }

    private setQueryParamsDatesForDetailWidget(): void {
        const hsDefaults = this.dashboardService.getDefaultQueryParams(
            [],
            [],
            this.manager.getExtraParametersForWorkspace());
        if (!this.queryParams.startDate) {
            this.queryParams.startDate = this.userPreferences?.startDate ?? hsDefaults.startDate;
        }
        if (!this.queryParams.endDate) {
            this.queryParams.endDate = this.userPreferences?.endDate ?? hsDefaults.endDate;
        }
        if (!this.queryParams.activeDate && this.getActiveDateRange() !== 'RANGE') {
            this.queryParams.activeDate = this.isActiveDateNotInRange(this.userPreferences?.endDate ?? hsDefaults.endDate ?? '') ?
                this.queryParams.endDate :
                this.queryParams.activeDate;
        }
    }

    private allowSingleClientPicker(): boolean {
        if (this.filterType === FILTER_TYPE.WIDGET) {
            return this.isTFLDetailsOrIncompleteFiles(this.getCurrentWidgetDataset(this.widgetId)?.queryType?.name ?? '');
        }
        if (this.filterType === FILTER_TYPE.DASHBOARD) {
            const widgetDatasets = this.getWidgetDatasetsForDashboard();
            return !!widgetDatasets.length && widgetDatasets.every((dsd) => this.isTFLDetailsOrIncompleteFiles(dsd.queryType.name));
        }

        const queryType = isNamedQuery(this.datasetDefinition) ?
            this.datasetDefinition?.type.name :
            this.datasetDefinition?.queryType?.name ?? '';

        return this.isTFLDetailsOrIncompleteFiles(queryType);
    }

    private isTFLDetailsOrIncompleteFiles(queryTypeName: string): boolean {
        return isTradeFileDetails(queryTypeName) || isTFLIncompleteFiles(queryTypeName);
    }
}
