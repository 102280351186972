import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable()
export class FilteredDataService {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public readonly filteredData$: Observable<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public readonly fullData$: Observable<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private readonly filteredData: Subject<any> = new ReplaySubject(1);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private readonly fullData: Subject<any> = new ReplaySubject(1);

    constructor() {
        this.filteredData$ = this.filteredData.asObservable();
        this.fullData$ = this.fullData.asObservable();
        this.filteredData.next({});
        this.fullData.next({});
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    updateFilteredData(newData: any): void {
        this.filteredData.pipe(first()).subscribe((existingData) => {
            const updatedData = { ...existingData, ...newData };
            this.filteredData.next(updatedData);
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    updateFullData(newData: any): void {
        this.fullData.pipe(first()).subscribe((existingData) => {
            const updatedData = { ...existingData, ...newData };
            this.fullData.next(updatedData);
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    clearFilteredData(newData: any = {}): void {
        this.filteredData.next({ ...newData });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    clearFullData(newData: any = {}): void {
        this.fullData.next({ ...newData });
    }
}
