import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { CurrentStateService, RealtimeActiveService } from '@ddv/behaviors';
import { ConfirmationPopupService, MultiSubscriptionComponent } from '@ddv/common-components';
import { CompareModeService, DatasetManagerService, MetadataService, WidgetDataSourceService } from '@ddv/datasets';
import { UserEntitlementService } from '@ddv/entitlements';
import { QueryParamsService } from '@ddv/filters';
import { ManagerService } from '@ddv/layout';
import {
    DashboardDetails,
    DashboardSnapshot,
    DateRangeString,
    DdvDate,
    FuzzyDates,
    DashboardPreference,
    MANAGE_WIDGET_ID,
    MenuOptionConfig,
    VisualizationMenuItem,
    Widget,
    WidgetAction,
    WidgetActionDetail,
    WidgetLifeCycleData,
    WidgetLifecycleEvent,
    WidgetOnBoard,
    WIDGET_LIFECYCLE_EVENT,
    WidgetActionDetailExtraParameters,
    WidgetLifecycleAction,
    AppWidgetState,
    WIDGET_REMOVAL_MESSAGE,
    DatasetMetadata,
    MetadataLookup,
} from '@ddv/models';
import { FuzzyDatesService } from '@ddv/reference-data';
import { SelectedWidgetRelayService } from '@ddv/visualizations';
import {
    ApplicationBaseWidgetComponent,
    LiveDemoWidgetComponent,
    SummaryWidgetComponent,
    VisualizationWidgetComponent,
    WidgetsService,
} from '@ddv/widgets';
import { ResizeEvent } from 'angular-resizable-element';
import * as d3 from 'd3';
import { combineLatest, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

import { DragEventConfig } from '../../directives/widget-drag.model';
import { DashboardService } from '../../services/dashboard.service';
import { DdvWidgetService } from '../../services/ddv-widget.service';

@Component({
    selector: 'app-widget-on-board',
    templateUrl: './widget-on-board.component.html',
    styleUrls: ['./widget-on-board.component.scss'],
})
export class WidgetOnBoardComponent
    extends MultiSubscriptionComponent
    implements AfterContentInit, AfterViewInit, OnInit, OnDestroy, OnChanges {
    @Input() widgetConfig: Widget | undefined;
    @Input() mode = '';
    @Input() dashboardId: string | number = '';
    @Input() isWorkspaceGlobal = false;
    @Input() isManagingWidget = false;
    @Input() useNewLegend = false;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() unselectAllWidgets = new EventEmitter<any>();
    @Output() workspaceStateChange = new EventEmitter<WIDGET_LIFECYCLE_EVENT>();

    @ViewChild('widget', { read: ViewContainerRef, static: true }) widgetContainerRef: ViewContainerRef | undefined;
    @ViewChild('widgetContent', { read: ViewContainerRef, static: true }) container: ViewContainerRef | undefined;
    @ViewChild('widgetContentWrapper') private readonly widgetContentWrapper: ElementRef<HTMLDivElement> | undefined;
    @ViewChild('removeWidgetTemplate') removeWidgetTemplate: TemplateRef<string> | undefined;
    componentRef: ComponentRef<ApplicationBaseWidgetComponent> | undefined;
    widgetContentDisplay = 'block';
    maximizedWidgetId = 0;
    maximizedWidgetItem: VisualizationMenuItem | undefined;
    currentDashboardQueryParams: DashboardPreference | undefined;
    isDataLoaded = false;
    isErrorOccurred = false;
    compareDates: DateRangeString | undefined;
    fuzzyDates: FuzzyDates | undefined;
    isWorkspaceReadonly = false;
    isWidgetReadOnly = false;
    haveGlobalEditPartial = false;
    showExportOption = false;
    widgetRemovalMessage = WIDGET_REMOVAL_MESSAGE;
    private isDetailCoreWidget = false;
    private inPresentationMode = false;
    private metadata: Map<number, MetadataLookup> = new Map();

    constructor(
        private readonly manager: ManagerService,
        private readonly myElement: ElementRef,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly confirmationService: ConfirmationPopupService,
        private readonly fuzzyDatesService: FuzzyDatesService,
        private readonly queryParamsService: QueryParamsService,
        private readonly dashboardService: DashboardService,
        private readonly userEntitlementsService: UserEntitlementService,
        private readonly ddvWidgetService: DdvWidgetService,
        private readonly widgetsService: WidgetsService,
        private readonly compareModeService: CompareModeService,
        private readonly currentStateService: CurrentStateService,
        private readonly datasetManagerService: DatasetManagerService,
        private readonly widgetDataSourceService: WidgetDataSourceService,
        private readonly realtimeActiveService: RealtimeActiveService,
        private readonly selectedWidgetRelayService: SelectedWidgetRelayService,
        private readonly metadataService: MetadataService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscribeTo(this.metadataService.metadataState, (state: DatasetMetadata) => {
            this.metadata = state.metadata;
            if (this.componentRef?.instance) {
                // this is only necessary because of dynamic components;
                this.componentRef.instance.updateFieldMetadata(this.metadata.get(this.widgetConfig?.id ?? 0) ?? {});
            }
        });

        this.subscribeTo(this.manager.getWidgetActionObservable(), (actionDetail) => this.triggerWidgetAction(actionDetail));

        this.subscribeTo(this.manager.maximizeWidgetAction$, ({ toBeMaximized, widgetId, item }) => {
            if (toBeMaximized && widgetId === this.widgetConfig?.id) {
                this.maximizedWidgetId = widgetId ?? 0;
                this.maximizedWidgetItem = item;
                this.onMaximize();

                const options = {
                    toBeMaximized: false,
                    widgetId: 0,
                    item: undefined,
                };

                setTimeout(() => this.manager.setMaximizeWidgetActionSubjectValue(options), 250);
            }
        });

        this.subscribeTo(this.fuzzyDatesService.fuzzyDates(), (fuzzyLists: FuzzyDates) => this.fuzzyDates = { ...fuzzyLists });

        this.subscribeTo(this.queryParamsService.dashboardQueryParams, (queryParams: DashboardPreference) => {
            this.currentDashboardQueryParams = queryParams;

            const widgetPrefs = this.getWidgetPreferences();
            if (widgetPrefs?.realtimeUpdates && widgetPrefs?.hideLoaderAfterFirstDataLoad) {
                widgetPrefs.hideLoaderAfterFirstDataLoad = false;
            }
        });

        this.userEntitlementsService.entitlementsForClientCode$
            .pipe(switchMap((entitlements) => {
                this.haveGlobalEditPartial = entitlements.haveGlobalEditPartial;
                this.isWorkspaceReadonly = this.isWorkspaceGlobal && this.haveGlobalEditPartial;
                return this.isManagingWidget ? this.selectedWidgetRelayService.isWidgetGlobal : of(false);
            }))
            .subscribe((isWidgetGlobal) => {
                this.isWidgetReadOnly = this.haveGlobalEditPartial && isWidgetGlobal;
            });

        this.subscribeTo(combineLatest([this.currentStateService.dashboardModeAndId$, this.compareModeService.widgetsCompareModeStatus]),
            ([dashboardModeAndId, widgetsData]) => {
                this.inPresentationMode = dashboardModeAndId.mode === 'view';
                this.showExportOption = this.widgetConfig?.id !== MANAGE_WIDGET_ID && this.inPresentationMode;
                const widgetCompareModeInfo = widgetsData.get(this.widgetConfig?.id ?? 0);
                if (widgetCompareModeInfo) {
                    const widgetPrefs = this.getWidgetPreferences();
                    if (widgetCompareModeInfo.isInCompareMode && this.inPresentationMode) {
                        if (this.isAdvancedGrid()) {
                            this.widgetLifeCycleCallBack(WIDGET_LIFECYCLE_EVENT.ADD_COMPARE_COLUMNS, null);
                        }
                    } else {
                        if (!this.inPresentationMode || !this.isAdvancedGrid()) {
                            this.compareModeService.clearCompareDataByWidgetId(this.widgetConfig?.id ?? 0);
                            this.compareModeService.clearWidgetsCompareModeStatusById(this.widgetConfig?.id ?? 0);
                        }

                        if (this.isAdvancedGrid()) {
                            this.widgetLifeCycleCallBack(WIDGET_LIFECYCLE_EVENT.HIDE_COMPARE_DATEPICKER, null);
                        } else {
                            if (widgetPrefs?.isSubscribedToDashboardFilters) {
                                this.queryParamsService.removeWidgetQueryParam(this.widgetConfig?.id ?? 0);
                            }
                            const uniqueKey = this.datasetManagerService.getUniqueKey(widgetPrefs);
                            this.widgetDataSourceService.restoreDataSource(uniqueKey);
                        }
                    }
                }
            });

        if (this.widgetConfig?.isDetailWidget) {
            this.maximizeWidget();
            this.widgetConfig.maximized = true;
            this.manager.emitIsWidgetMaximizedSubjectValue(true);
        }

        if (this.getWidgetPreferences()?.isDetailWidget) {
            this.ddvWidgetService.updateDetailWidgetId(this.getWidgetPreferences()?.coreWidgetId);
        }

        this.getWidgetPreferences()?.widgetFilters?.filters?.forEach((filter) => {
            if (filter.filterValuesType === 'number') {
                filter.values = filter.values?.map((value) => Number(value));
            }

            if (filter.filterValuesType === 'boolean') {
                filter.values = filter.values?.map((value) => {
                    if (value != null) {
                        return value === 'true';
                    } else {
                        return value;
                    }
                });
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isManagingWidget && this.componentRef) {
            this.componentRef.instance.isManagingWidget = this.isManagingWidget;
        }

        // this moronicness is because of all of the dynamic components
        if (changes.useNewLegend && this.componentRef) {
            this.componentRef.instance.toggleUseNewLegend(this.useNewLegend);
        }
    }

    ngAfterContentInit(): void {
        if (!this.container || !this.widgetConfig) {
            return;
        }

        this.container.clear();

        // as defined here: static getWidgetsForDashboard(): IApplicationWidgetConfiguration[]
        // widgetConfig.component will always be one of:
        //      VisualizationWidgetComponent
        //      SummaryComponent
        //      LiveDemoWidgetComponent
        // so instead of this dynamic component bullshit, lets just handle that natively in the template
        this.componentRef = this.container.createComponent(this.widgetConfig.component);
        const widgetComponent = this.componentRef.instance;
        widgetComponent.isManagingWidget = this.isManagingWidget;
        widgetComponent.dashboardId = this.dashboardId;
        widgetComponent.widgetOnBoard = !this.isManagingWidget ? this.getWidgetOnBoard() : undefined;
        widgetComponent.useNewLegend = this.useNewLegend;
        widgetComponent.fieldMetadata = this.metadata.get(this.widgetConfig?.id ?? 0) ?? {};

        this.isDataLoaded = widgetComponent instanceof LiveDemoWidgetComponent || widgetComponent.isDataLoaded;
        this.isErrorOccurred = widgetComponent.isErrorOccurred;

        if (widgetComponent.isDataLoadedToggleEvent) {
            widgetComponent.isDataLoadedToggleEvent.subscribe((isDataLoaded) => this.isDataLoaded = isDataLoaded);
        }

        this.widgetConfig.containerRef = this.widgetContainerRef;
        widgetComponent.id = this.widgetConfig.id;
        widgetComponent.selector = this.widgetConfig.selector;

        if (this.widgetConfig.maximized) {
            this.maximizeWidget();
        }

        if (widgetComponent instanceof VisualizationWidgetComponent || widgetComponent instanceof LiveDemoWidgetComponent) {
            widgetComponent.instanceLoadedObs.pipe(first((isLoaded) => isLoaded)).subscribe(() => {
                if (widgetComponent.widgetPrefs?.datasetDefinition?.conversableType && this.isAdvancedGrid()) {
                    this.widgetLifeCycleCallBack(WIDGET_LIFECYCLE_EVENT.ENTER_COMMENT_EDIT_SINGLE_MODE, undefined);
                }
            });
        }

        if (widgetComponent instanceof VisualizationWidgetComponent) {
            widgetComponent.detailWidgetClicked.subscribe(({ detailWidgetId, clientCode }) => {
                this.ddvWidgetService.openDetailWidgetInView(detailWidgetId, clientCode)
                    .subscribe(() => {
                        if (this.widgetConfig?.maximized) {
                            this.onCascade(false);
                            this.manager.emitIsWidgetMaximizedSubjectValue(true);
                        }
                    });
            });
        }

        if (widgetComponent instanceof LiveDemoWidgetComponent) {
            widgetComponent.detailWidgetSelectedEvent.subscribe((maximize: boolean) => {
                if (maximize) {
                    this.maximizeWidget();
                    if (this.widgetConfig) {
                        this.widgetConfig.maximized = true;
                    }
                    this.isDetailCoreWidget = true;
                }
            });
        }
    }

    ngAfterViewInit(): void {
        if (this.widgetConfig) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.widgetConfig.lifeCycleCallBack = this.widgetLifeCycleCallBack.bind(this) as any;
        }
        const extraParameters = this.manager.getExtraParametersForWorkspace();
        this.widgetLifeCycleCallBack(WIDGET_LIFECYCLE_EVENT.INIT_WIDGET, extraParameters);
        this.triggerWorkspaceStateChange(WIDGET_LIFECYCLE_EVENT.INIT_WIDGET);
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();

        // something funny is happening in tests, so we need this check or angular/karma/jasmine get really mad
        if (typeof this.componentRef?.destroy === 'function') {
            this.componentRef.destroy();
        }

        this.manager.setMaximizeWidgetActionSubjectValue({ toBeMaximized: false, widgetId: 0, item: undefined });

        if (this.isUnsubscribedWidget()) {
            this.manager.emitWhenDateComparerIsToggled(false);
        }
    }

    onMaximize(): void {
        this.widgetLifeCycleCallBack(WIDGET_LIFECYCLE_EVENT.ON_MAXIMIZE, undefined);
        this.maximizeWidget();
        if (this.widgetConfig) {
            this.widgetConfig.maximized = true;
        }
        this.widgetLifeCycleCallBack(WIDGET_LIFECYCLE_EVENT.AFTER_MAXIMIZE, undefined);
        this.triggerWorkspaceStateChange(WIDGET_LIFECYCLE_EVENT.AFTER_MAXIMIZE);
        this.manager.emitIsWidgetMaximizedSubjectValue(true);
    }

    get isGrid(): boolean {
        return this.isAdvancedGrid() || this.getWidgetPreferences()?.currentVisualization === 'SIMPLE_GRID';
    }

    get showLoader(): boolean {
        return !(this.isDataLoaded || this.isErrorOccurred);
    }

    get areFiltersApplied(): boolean {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (this.componentRef?.instance as any)?.areFiltersApplied();
    }

    getMenuItemClickHandler(menuOption: MenuOptionConfig, $event: MouseEvent): void {
        $event.stopPropagation();
        if (menuOption.disabled || this.isMenuOptionReadOnly(menuOption.selector)) {
            return;
        }
        const selector = menuOption.selector;
        if (selector === 'maxBtn') {
            this.onMaximize();
        } else if (selector === 'closeBtn') {
            this.onCloseButtonClicked($event);
        } else if (selector === 'cascadeBtn') {
            this.onCascadeButtonClicked($event);
        } else if (menuOption.callBack) {
            menuOption.callBack(selector);
        }
    }

    onDragStart(): void {
        if (this.isWidgetDraggable()) {
            if (Widget.hideContentWhileDrag) {
                this.widgetContentDisplay = 'none';
            }
            const layoutHandler = this.manager.getWorkspaceLayoutHandler();
            if (layoutHandler?.isCustomDragHandlingSupported) {
                const workspaceSize = this.manager.getCurrentWorkspaceSize();
                if (this.widgetConfig && workspaceSize) {
                    layoutHandler.onDragStart(this.widgetConfig, workspaceSize);
                }
            }
        }
    }

    onDragEnd(eventConfig: DragEventConfig): void {
        if (this.isWidgetDraggable() && this.widgetConfig) {
            const layoutHandler = this.manager.getWorkspaceLayoutHandler();
            this.widgetConfig.top = eventConfig.rectangle.top;
            this.widgetConfig.left = eventConfig.rectangle.left;
            if (layoutHandler?.isCustomDragHandlingSupported) {
                const workspaceSize = this.manager.getCurrentWorkspaceSize();
                if (workspaceSize) {
                    layoutHandler.onDragEnd(this.widgetConfig, workspaceSize);
                }
            }
        }
        this.widgetContentDisplay = 'block';
        this.widgetLifeCycleCallBack(WIDGET_LIFECYCLE_EVENT.AFTER_DRAG, undefined);
        this.triggerWorkspaceStateChange(WIDGET_LIFECYCLE_EVENT.AFTER_DRAG);
    }

    onResizeStart(): void {
        const layoutHandler = this.manager.getWorkspaceLayoutHandler();
        const workspaceSize = this.manager.getCurrentWorkspaceSize();
        if (this.widgetConfig && workspaceSize) {
            layoutHandler?.onWidgetResizeStart(this.widgetConfig, workspaceSize);
        }
    }

    onResizing(event: ResizeEvent): void {
        const layoutHandler = this.manager.getWorkspaceLayoutHandler();
        const workspaceSize = this.manager.getCurrentWorkspaceSize();
        if (this.canResize()) {
            this.setWidgetDimension(event);
        }
        if (this.widgetConfig && workspaceSize) {
            layoutHandler?.onWidgetResize(this.widgetConfig, workspaceSize);
        }
    }

    onResizeEnd(event: ResizeEvent): void {
        if (this.canResize()) {
            this.setWidgetDimension(event);
            const layoutHandler = this.manager.getWorkspaceLayoutHandler();
            const workspaceSize = this.manager.getCurrentWorkspaceSize();

            if (this.widgetConfig && workspaceSize) {
                layoutHandler?.onWidgetResizeEnd(this.widgetConfig, workspaceSize);
            }

            this.widgetLifeCycleCallBack(
                WIDGET_LIFECYCLE_EVENT.AFTER_RESIZE,
                this.widgetContentWrapper?.nativeElement.getBoundingClientRect());

            this.triggerWorkspaceStateChange(WIDGET_LIFECYCLE_EVENT.AFTER_RESIZE);
        }
    }

    onHeaderDoubleClick(): void {
        if (this.widgetConfig?.allowHeaderDoubleClick) {
            if (this.widgetConfig.maximized) {
                this.onCascade(true);
            } else {
                this.onMaximize();
            }
        }
    }

    onMenuItemDoubleClick(event: MouseEvent): void {
        event.stopPropagation();
    }

    onSelectWidget(event: MouseEvent): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((event as any)?.target.id === 'closeBtn' ||
            !this.isManagingWidget && this.mode === 'view' && !this.widgetConfig?.isSelectable) {
            return;
        }

        this.unselectAllWidgets.emit();
        this.widgetConfig?.bringInForeground();
        this.widgetLifeCycleCallBack(WIDGET_LIFECYCLE_EVENT.WIDGET_SELECTED, event);

        if (!this.widgetConfig?.allowHeaderDoubleClick) {
            this.toggleWidgetSelect();
        } else if (!this.isManagingWidget) {
            setTimeout(() => {
                if (event?.detail === 1) {
                    this.toggleWidgetSelect();
                } else if (this.widgetConfig?.maximized) {
                    this.manager.selectWidget(true, this.widgetConfig);
                }
            }, 300);
        }
    }

    unselectWidget(): void {
        this.widgetConfig?.bringInBackground();
    }

    isWidgetDraggable(): boolean {
        let isDraggable = this.widgetConfig?.isDraggable;
        if (this.widgetConfig?.maximized || this.isWorkspaceReadonly) {
            isDraggable = false;
        }
        return !!isDraggable;
    }

    canResize(): boolean {
        return !!this.widgetConfig?.isResizable && !this.widgetConfig.maximized && !this.isWorkspaceReadonly;
    }

    isUnsubscribedWidget(): boolean {
        return !!(this.getWidgetPreferences() && !this.getWidgetPreferences()?.isSubscribedToDashboardFilters);
    }

    isMasterWidget(): boolean {
        return !!this.getWidgetPreferences()?.isMaster;
    }

    getValidDate(date: string, dateList: keyof FuzzyDates): string {
        return date && (DdvDate.isStringValidDate(date) ? date : this.getDateFromFuzzy(dateList, date));
    }

    clearAllFilters(): void {
        this.widgetsService.clearAllFilters(this.widgetConfig?.id ?? 0);
    }

    exportWidget(): void {
        if (this.componentRef?.instance instanceof VisualizationWidgetComponent ||
            this.componentRef?.instance instanceof SummaryWidgetComponent
        ) {
            this.componentRef.instance.exportWidget();
        }
    }

    // this probably should be being passed in as an input
    private getWidgetOnBoard(): WidgetOnBoard | undefined {
        const snapshot: DashboardDetails | DashboardSnapshot | undefined = this.dashboardService.getDashboardSnapshotById(this.dashboardId);
        const widgetSnapshot = (snapshot as DashboardDetails | undefined)?.widgetOnBoards?.find((widget) => {
            return widget.id === this.widgetConfig?.id;
        });
        return widgetSnapshot;
    }

    private maximizeWidget(): void {
        if (!this.widgetConfig) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const parentBoundingRectangle = this.getWidgetContainerRectangle() as any;
        this.widgetConfig.copyWidgetStateToTemp();
        this.widgetConfig.top = 0;
        this.widgetConfig.left = 0;
        this.widgetConfig.bringInForeground();
        const padding = {
            x: (this.getWidgetPropertyValue('padding-left') * 1 + this.getWidgetPropertyValue('padding-right') * 1),
            y: (this.getWidgetPropertyValue('padding-top') * 1 + this.getWidgetPropertyValue('padding-bottom') * 1),
        };
        this.widgetConfig.width = parentBoundingRectangle.width - padding.x - 2;
        this.widgetConfig.height = parentBoundingRectangle.height - padding.y - 2;
        if (this.widgetConfig.allowCascade) {
            this.widgetConfig.hideMenuIcons(['maxBtn']);
            this.widgetConfig.showMenuIcons(['cascadeBtn']);
        }
    }

    private onCascadeButtonClicked(event: MouseEvent): void {
        if (this.isDetailCoreWidget) {
            this.widgetLifeCycleCallBack(WIDGET_LIFECYCLE_EVENT.ON_CASCADE);
        } else if (this.widgetConfig?.isDetailWidget) {
            this.closeDetailWidget(event);
        } else {
            this.onCascade(true);
        }
    }

    private onCloseButtonClicked($event: MouseEvent): void {
        const confirmDialogOptions = {
            message: this.removeWidgetTemplate,
            confirmButtonText: 'Remove',
            denyButtonText: 'Cancel',
        };
        this.confirmationService.showConfirmationPopup(confirmDialogOptions).subscribe({
            next: (action) => {
                if (action === 'confirm') {
                    this.onClose($event);
                }
            },
        });
    }

    private onClose(event: MouseEvent): void {
        if (this.widgetConfig?.isDetailWidget) {
            this.closeDetailWidget(event);
        } else {
            this.closeWidget(event);
        }
    }

    private onCascade(emitIsMaximizedValue: boolean): void {
        if (!this.widgetConfig) {
            return;
        }

        if (this.widgetConfig.resizeDimensions) {
            this.setWidgetResizeDimensions();
        } else {
            this.widgetConfig.copyTempStateToWidget();
        }
        this.widgetConfig.bringInBackground();
        this.widgetConfig.maximized = false;
        this.widgetConfig.hideMenuIcons(['cascadeBtn']);
        this.widgetConfig.showMenuIcons(['maxBtn']);
        this.widgetLifeCycleCallBack(WIDGET_LIFECYCLE_EVENT.AFTER_CASCADE, undefined);
        this.triggerWorkspaceStateChange(WIDGET_LIFECYCLE_EVENT.AFTER_CASCADE);

        if (emitIsMaximizedValue) {
            this.manager.emitIsWidgetMaximizedSubjectValue(false);
        }

        this.manager.selectWidget(false, undefined);

        if (this.maximizedWidgetItem && this.maximizedWidgetId === this.widgetConfig.id) {
            const options = {
                toBeMaximized: false,
                widgetId: this.maximizedWidgetId,
                item: this.maximizedWidgetItem,
            };

            this.manager.setMaximizeWidgetActionSubjectValue(options);

            this.maximizedWidgetId = 0;
            this.maximizedWidgetItem = undefined;
        }
    }

    private toggleWidgetSelect(): void {
        if (this.widgetConfig?.selectedClassName) {
            this.manager.selectWidget(true, this.widgetConfig);
        } else {
            this.manager.selectWidget(false, undefined);
        }
    }

    private setWidgetDimension(event: ResizeEvent): void {
        if (!this.widgetConfig) {
            return;
        }

        if (this.hasDimentionChanged(event.edges)) {
            const parentElement = this.myElement.nativeElement.parentElement.parentElement;
            const parentBoundingRect = parentElement.getBoundingClientRect();
            let width = this.widgetConfig.minWidth;
            let height = this.widgetConfig.minHeight;

            if (this.widgetConfig.minWidth <= (event.rectangle.width ?? 0)) {
                width = event.rectangle.width ?? 0;
            }
            if (this.widgetConfig.minHeight <= (event.rectangle.height ?? 0)) {
                height = event.rectangle.height ?? 0;
            }
            const workspaceScrollBehaviour = this.manager.getWorkspaceScrollBehaviour();
            let allowInfiniteVScroll = false;
            let allowInfiniteHScroll = false;

            if (workspaceScrollBehaviour) {
                allowInfiniteVScroll = workspaceScrollBehaviour.allowInfiniteVScroll;
                allowInfiniteHScroll = workspaceScrollBehaviour.allowInfiniteHScroll;
            }

            if (!allowInfiniteVScroll) {
                if (event.rectangle.bottom > parentBoundingRect.bottom) {
                    height = parentBoundingRect.bottom - event.rectangle.top;
                }
            }
            if (!allowInfiniteHScroll) {
                if (event.rectangle.right > parentBoundingRect.right) {
                    width = parentBoundingRect.right - event.rectangle.left;
                }
            }

            this.widgetConfig.width = width;
            this.widgetConfig.height = height;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private hasDimentionChanged(edges: any): boolean {
        if (hasProp(edges,'right') && !hasProp(edges, 'bottom') && edges.right) {
            return true;
        } else if (hasProp(edges, 'bottom') && !hasProp(edges, 'right') && edges.bottom) {
            return true;
        } else if (hasProp(edges, 'bottom') && hasProp(edges, 'right') && (edges.right || edges.bottom)) {
            return true;
        } else {
            return false;
        }

        function hasProp(thing: undefined, prop: string): boolean {
            return Object.prototype.hasOwnProperty.call(thing, prop);
        }
    }

    private getWidgetContainerRectangle(): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const holderRef = this.getWidgetHolderReference() as any;
        return holderRef.parentNode.parentNode.parentElement.getBoundingClientRect();
    }

    private getWidgetHolderReference(): void {
        return this.myElement.nativeElement.querySelector('div.ngPopup');
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getWidgetPropertyValue(property: string, isNaN?: boolean): any {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const holderRef = this.getWidgetHolderReference() as any;
        return isNaN ? (window.getComputedStyle(holderRef, null).getPropertyValue(property)) :
            (window.getComputedStyle(holderRef, null).getPropertyValue(property)).replace(/\D+/g, '');
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private widgetLifeCycleCallBack(eventName: WidgetLifecycleEvent, data?: any): void {
        if (data === WIDGET_LIFECYCLE_EVENT.LOADING_DATA) {
            const widgetPrefs = this.getWidgetPreferences();
            this.isDataLoaded = !!(widgetPrefs?.realtimeUpdates ? widgetPrefs.hideLoaderAfterFirstDataLoad : false);
        } else if (data === WIDGET_LIFECYCLE_EVENT.HIDE_CANCEL || this.isLifeCycleError(data) || this.isWidgetReset(data)) {
            this.isDataLoaded = true;
        } else if (this.isDetailWidgetClosed(data) && this.ddvWidgetService.isDetailWidgetLastOpened(this.widgetConfig?.id ?? 0)) {
            this.maximizeWidget();
            if (this.widgetConfig) {
                this.widgetConfig.maximized = true;
            }
            this.manager.emitIsWidgetMaximizedSubjectValue(true);
        }

        d3.select('body').selectAll('.toolTip').remove();
        const changeDetectionEvents: WidgetLifecycleEvent[] = [
            WIDGET_LIFECYCLE_EVENT.AFTER_MAXIMIZE,
            WIDGET_LIFECYCLE_EVENT.AFTER_CASCADE,
            WIDGET_LIFECYCLE_EVENT.AFTER_RESIZE,
            WIDGET_LIFECYCLE_EVENT.AFTER_DRAG,
        ];
        const triggerChangeDetection = changeDetectionEvents.indexOf(eventName) !== -1;

        if (triggerChangeDetection) {
            // When we update the widget model width and height and trigger the callback to application then
            // What happens is the callback reaches to application before the updated width and height get applied in dom.
            // In order for change detection and dom update to happen before callback trigger, we are
            // Manually triggering change detection and then triggering the callback
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (!(this.changeDetectorRef as any).destroyed) {
                this.changeDetectorRef.detectChanges();
            }
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.componentRef?.instance.widgetLifeCycleCallBack(eventName as any, data);
        this.componentRef?.instance.widgetLifeCyclePostProcess(eventName, data);
    }

    private triggerWorkspaceStateChange(eventName?: WIDGET_LIFECYCLE_EVENT): void {
        this.workspaceStateChange.emit(eventName);
    }

    private triggerWidgetAction(actionDetail: WidgetActionDetail): void {
        const { widgetId, action, extraParams } = actionDetail;

        if (widgetId === this.widgetConfig?.id) {
            if (action === WidgetAction.MAXIMIZE) {
                this.onMaximize();
                return;
            }
            if (action === WidgetAction.RESTORE) {
                this.onCascade(true);
                return;
            }
            if (action === WidgetAction.CLOSE) {
                this.closeWidget();
                return;
            }
            if (action === WidgetAction.RESIZE && this.widgetConfig.isResizable && extraParams) {
                this.onWidgetResize(extraParams);
                return;
            }
            if (action === WidgetAction.MOVE && this.widgetConfig.isDraggable && extraParams) {
                this.onWidgetMove(extraParams);
            }
        }
    }

    private onWidgetResize(params: WidgetActionDetailExtraParameters): void {
        if (!this.widgetConfig) {
            return;
        }

        this.widgetConfig.height = typeof params?.height !== 'undefined' ? params.height : this.widgetConfig.height;
        this.widgetConfig.width = typeof params?.width !== 'undefined' ? params.width : this.widgetConfig.width;

        const layoutHandler = this.manager.getWorkspaceLayoutHandler();
        const currentWorkspaceSize = this.manager.getCurrentWorkspaceSize();
        if (currentWorkspaceSize) {
            layoutHandler?.onWidgetResizeEnd(this.widgetConfig, currentWorkspaceSize);
        }
        this.widgetLifeCycleCallBack(WIDGET_LIFECYCLE_EVENT.AFTER_RESIZE, undefined);
    }

    private onWidgetMove(params: WidgetActionDetailExtraParameters): void {
        if (!this.widgetConfig) {
            return;
        }
        this.widgetConfig.left = typeof params?.left !== 'undefined' ? params.left : this.widgetConfig.left;
        this.widgetConfig.top = typeof params?.top !== 'undefined' ? params.top : this.widgetConfig.top;

        const layoutHandler = this.manager.getWorkspaceLayoutHandler();
        const currentWorkspaceSize = this.manager.getCurrentWorkspaceSize();
        if (currentWorkspaceSize) {
            layoutHandler?.onDragEnd(this.widgetConfig, currentWorkspaceSize);
        }
        this.widgetLifeCycleCallBack(WIDGET_LIFECYCLE_EVENT.AFTER_DRAG, undefined);
    }

    private closeWidget(event?: MouseEvent): void {
        if (event) {
            event.stopPropagation();
        }

        this.dashboardService
            .removeWidgetFromBoard(this.manager.getCurrentDashboardId() ?? 0, this.widgetConfig?.id ?? 0)
            .subscribe(() => this.removeWidget());
    }

    private closeDetailWidget(event: MouseEvent): void {
        event.stopPropagation();
        this.manager.updateIsDetailWidgetOpened(false);
        this.ddvWidgetService.removeLastOpenedDetailWidgetFromList();
        this.removeWidget();
        this.ddvWidgetService.setPreviousViewLevelQueryParams();
    }

    private removeWidget(): void {
        this.widgetLifeCycleCallBack(WIDGET_LIFECYCLE_EVENT.ON_CLOSE);
        this.manager.removeWidgetById(this.widgetConfig?.id ?? 0);

        const currentDashboardWidgets = this.manager.getWorkspace()?.extraParameters?.widgets ?? [];
        const index = currentDashboardWidgets.findIndex((widget) => widget.id === this.widgetConfig?.id);
        currentDashboardWidgets.splice(index, 1);

        this.componentRef?.destroy();
        const widgetPopUpNode = this.myElement.nativeElement.querySelector('div.ngPopup');
        widgetPopUpNode.parentNode.removeChild(widgetPopUpNode);
        this.triggerWorkspaceStateChange(WIDGET_LIFECYCLE_EVENT.ON_CLOSE);
        const layoutHandler = this.manager.getWorkspaceLayoutHandler();
        layoutHandler?.onWidgetClose(this.widgetConfig?.id ?? 0);
        this.manager.selectWidget(false, undefined);

        const widgets = this.manager.getWorkspace()?.extraParameters?.widgets ?? [];
        this.realtimeActiveService.updateRealtimeActive(widgets);

        this.dashboardService.notifyForWidgetRemovedFromDashboard();
        if (this.widgetConfig?.isDetailWidget) {
            this.manager.emitIsWidgetMaximizedSubjectValue(false);
            this.manager.sendMessageToAllWidgetsOnWorkspace(
                this.widgetConfig.id,
                WIDGET_LIFECYCLE_EVENT.DETAIL_WIDGET_CLOSED);
        }
    }

    private getDateFromFuzzy(dateList: keyof FuzzyDates, fuzzyDate: string): string {
        const option = this.fuzzyDates?.[dateList].findByName(fuzzyDate);
        return option ? DdvDate.fromISOFormat(option.value).toUSPaddedFormat() : '';
    }

    private getWidgetPreferences(): AppWidgetState | undefined {
        return this.widgetConfig?.extraParameters?.preferences;
    }

    private setWidgetResizeDimensions(): void {
        if (!this.widgetConfig) {
            return;
        }

        const { resizeDimensions } = this.widgetConfig;
        this.widgetConfig.top = resizeDimensions?.top ?? 0;
        this.widgetConfig.left = resizeDimensions?.left ?? 0;
        this.widgetConfig.height = resizeDimensions?.height ?? 0;
        this.widgetConfig.width = resizeDimensions?.width ?? 0;
    }

    private isAdvancedGrid(): boolean {
        return this.getWidgetPreferences()?.currentVisualization === 'ADVANCED_GRID';
    }

    private isMenuOptionReadOnly(selector: string): boolean {
        return (selector === 'closeBtn' || selector === 'settingsOverridesBtn') && this.isWorkspaceReadonly && this.mode === 'edit';
    }

    private isLifeCycleError(data: WidgetLifeCycleData): boolean {
        return (data as WidgetLifecycleAction)?.action === WIDGET_LIFECYCLE_EVENT.ERROR_OCCURRED;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private isWidgetReset(data: any): boolean {
        return data === WIDGET_LIFECYCLE_EVENT.RESET_WIDGET ||
            (data as WidgetLifecycleAction)?.action === WIDGET_LIFECYCLE_EVENT.RESET_WIDGET;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private isDetailWidgetClosed(data: any): boolean {
        return !!(data === WIDGET_LIFECYCLE_EVENT.DETAIL_WIDGET_CLOSED && this.widgetConfig?.isDetailWidget);
    }
}
