import { Injectable } from '@angular/core';

import { ChartSettings } from '../models/chart-settings';
import { BarChartService } from './bar-chart.service';
import { BaseChartService } from './base-chart.service';
import { LineChartService } from './line-chart.service';

@Injectable()
export class MultiSeriesChartService extends BaseChartService {
    private readonly barChartService: BarChartService;
    private readonly lineChartService: LineChartService;

    constructor() {
        super();
        this.barChartService = new BarChartService();
        this.lineChartService = new LineChartService();
    }

    drawMultiSeriesChart(config: ChartSettings): void {
        this.config = config;
        this.initSvg();
        this.initAxis();
        this.createOrdinal();
        this.drawAxis();
        this.drawMultiSeries();
    }

    override drawMultiSeries(): void {
        let seriesLength = this.config?.series.length ?? 0;
        while (seriesLength > 0) {
            if (this.config?.series[seriesLength - 1].type === 'bar') {
                this.barChartService.updateChartProperties(this);
                this.barChartService.drawBars(this.config.series[seriesLength - 1], this.config.dataSource);
            }
            if (this.config?.series[seriesLength - 1].type === 'line') {
                this.lineChartService.updateChartProperties(this);
                this.lineChartService.prepareData(this.config);
                this.lineChartService.drawLine(this.config.series[seriesLength - 1]);
            }
            seriesLength--;
        }
    }
}
