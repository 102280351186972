import { Client } from '../client';
import { Fund } from '../fund';
import { dummyQueryType, QueryPeriodType, QueryType, QueryTypeName } from '../named-queries/query-type';
import { VisualizationTypeReference } from '../visualizations/visualization-type-reference';
import { DatasetDefinition } from './dataset-definition';

export interface DateRange {
    from: string;
    to: string;
}

export interface Query {
    timeseriesRange: {
        granularity: string;
        startDate: string;
        endDate: string;
    };
    includeFunds: { code: string }[];
    includeLinks: boolean;
    options: { name: string, selection: string }[];
    additionalFilters: { columnId: string, operation: string, choices: (string | number)[] }[];
    groupBy: { columnId: string, sortDirection: string }[];
    selectedColumns: { columnId: string }[];
    preFetchAndFlattenAllData: boolean;
    includeMetadata: boolean;
    acknowledged?: boolean;
    rootFMPath?: string;
    conversationType?: string;
    enableUDFRetrieval?: boolean;
    includeManuallyReleased?: boolean; // this is only relevant for net-settlement breaks
}

export interface FastnessQuery {
    timeseriesRange: {
        granularity: string;
        startDate: string;
        endDate: string;
    };
    queryTypes: string[];
}

export interface FastnessResponse {
    [key: string]: {
        [key: string]: { fast: boolean };
    };
}

export class DatasetFetchRuntimeParams {
    public constructor(
        public readonly uniqueId: string,
        public readonly timeseriesGranularity: string,
        public readonly dateRange: DateRange,
        public readonly fundCodeList: string[],
        public readonly includeLinks = true,
        public readonly includeComments = true,
        public readonly clientCodeList: Client[] = [],
    ) {}

    convertFundCodesToDescriptors(): { code: string }[] {
        return this.fundCodeList.map((code) => ({ code }));
    }
}

export class DatasetDefinitionDetails implements DatasetDefinition {
    conversableType = '';
    queryType: QueryType = dummyQueryType;
    queryTemplate = '';
    queryPeriodType?: QueryPeriodType;
    datasetDefinitionVisualizations: { id: number, visualizationMaster: VisualizationTypeReference }[] = [];
    isGlobal = false;
    isHSSupported = false;
    isQueryTemplateStacked = false;
    id: number | undefined;
    name = '';
    dataType = '';
    showDuplicateRowsWarning = false;

    public constructor(init?: Partial<DatasetDefinitionDetails>) {
        Object.assign(this, init);

        if (this.queryTemplate) {
            this.setIsQueryTemplateStacked();
        }
    }

    getRootDir(): string {
        const dummyParams = new DatasetFetchRuntimeParams('', '', { from: '', to: '' }, []);
        return this.populateQueryTemplate(dummyParams).rootFMPath ?? 'NO ROOT FM PATH';
    }

    populateQueryTemplate(params: DatasetFetchRuntimeParams, fundOptions?: Fund[]): Query {
        this.nullSafeReplace('timeseriesRange.from', params.dateRange.from);
        this.nullSafeReplace('timeseriesRange.to', params.dateRange.to);
        this.nullSafeReplace('timeseriesRange.granularity', params.timeseriesGranularity?.toLowerCase());

        this.nullSafeReplace('includeLinks', params.includeLinks);

        this.nullSafeReplace('fundCodeList', JSON.stringify(params.convertFundCodesToDescriptors()));

        this.queryTemplate = this.queryTemplate.split('@@UNION@@')[0];
        const query = JSON.parse(this.queryTemplate) as Query;

        if (query?.timeseriesRange?.granularity) {
            query.timeseriesRange.granularity = query.timeseriesRange.granularity.toLowerCase();
        }

        if (query.includeFunds?.length && fundOptions?.length) {
            query.includeFunds = query.includeFunds.filter((f) => this.shouldFundBeIncluded(fundOptions, f.code, this.queryType.name));
        }

        return query;
    }

    populateStackedQueryTemplate(params: DatasetFetchRuntimeParams, fundOptions?: Fund[]): Query[] {
        this.checkForNullSafeReplace('timeseriesRange.from', params.dateRange.from);
        this.checkForNullSafeReplace('timeseriesRange.to', params.dateRange.to);
        this.checkForNullSafeReplace('timeseriesRange.granularity', params.timeseriesGranularity?.toLowerCase());

        this.checkForNullSafeReplace('includeLinks', params.includeLinks);

        this.checkForNullSafeReplace('fundCodeList', JSON.stringify(params.convertFundCodesToDescriptors()));

        const queryTemplateArray = this.queryTemplate.split('@@UNION@@');
        const queries: Query[] = [];

        queryTemplateArray.forEach((queryTemplate) => {
            const query = JSON.parse(queryTemplate) as Query;

            if (query?.timeseriesRange?.granularity) {
                query.timeseriesRange.granularity = query.timeseriesRange.granularity.toLowerCase();
            }

            if (query.includeFunds?.length && fundOptions?.length) {
                query.includeFunds = query.includeFunds.filter((f) => this.shouldFundBeIncluded(fundOptions, f.code, this.queryType.name));
            }

            queries.push(query);
        });

        return queries;
    }

    queryEndpoint(clientCode: string): string {
        return this.queryType.dataEndpoint.replace('{clientCode}', clientCode);
    }

    metadataEndpoint(clientCode: string): string {
        return this.queryType.metadataEndpoint.replace('{clientCode}', clientCode);
    }

    private shouldFundBeIncluded(funds: Fund[], fundCode: string, queryType: string): boolean {
        const fund = funds.find((f) => f.fundId === fundCode);
        return !!fund && (queryType === QueryTypeName.INVESTOR ? fund.isHiOrBoth : fund.isHsMainOrBoth);
    }

    private checkForNullSafeReplace(key: string, value?: string | null | boolean): void {
        if (value == null || value === 'null') {
            return;
        }

        while (this.queryTemplate.indexOf(`@@${key}@@`) !== -1) {
            this.nullSafeReplace(key, value);
        }
    }

    private nullSafeReplace(key: string, value?: string | null | boolean): void {
        if (value == null || value === 'null') {
            return;
        }

        this.queryTemplate = this.queryTemplate.replace(`@@${key}@@`, `${value}`);
    }

    private setIsQueryTemplateStacked(): void {
        this.isQueryTemplateStacked = this.queryTemplate.indexOf('@@UNION@@') !== -1;
    }
}
