import { Docked } from '@ddv/models';
import { Axis } from './models/axis';
import { ChartSettings } from './models/chart-settings';
import { GridLines } from './models/grid-lines';
import { Series } from './models/series';

export class DefaultAxis implements Axis {
    type = 'numeric';
    position = 'bottom';
    hide = false;
    label = '';
    labelStyle = '';
    field = ['date'];
    nTicks = 5;
    autoTicks = true;
    domain = [];
}

export class DefaultSeries implements Series {
    type = 'line';
    xField = ['name'];
    yField = ['value'];
    radius = {};
    stacked = false;
    title = 'title';
    style = {
        stroke: '#666666',
        fillOpacity: 0.8,
    };
    discreteColors = [];
    fill = '#b0c4de';
}

export class DefaultGridLines implements GridLines {
    x = {
        nTicks: 5,
        hide: false,
        showLabels: true,
        autoTicks: true,
    };
    y = {
        nTicks: 5,
        hide: false,
        showLabels: true,
        autoTicks: true,
    };
}

// TODO: break this down by visualization type
export class DefaultChartSettings implements ChartSettings {
    title = 'Chart';
    minHeight = 100;
    minWidth = 900;
    selector = '';
    margin = { top: 0, right: 0, bottom: 0, left: 0 };
    dataSource = [];
    data = [];
    legend = {
        docked: 'right' as Docked,
        inline: true,
        showCustom: false,
        type: 'rect',
        nav: {},
        width: 0.25,
    };
    showTooltip = true;
    showMultipleSeries = false;
    enableDrilldown = false;
    highlightSlice = false;
    highlight = {
        text: {
            size: 12,
            x: 12,
            y: 12,
        },
    };
    showGridLines = false;
    showBrush = false;
    showCurveValues = false;
    showLabels = false;
    showInflectionPoints = false;
    label = {
        projectedLines: true,
    };
    brushSize = 40;
    donutThreshold = {
        min: 6,
        max: 50,
    };
    sortTableBy = 'SLICER';
    tableSortDirection = 'ASC';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    series: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    axis: any;

    constructor(config: ChartSettings) {
        completeAssign(this, config);

        for (let index = 0; index < config.series.length; index++) {
            this.series[index] = completeAssign(new DefaultSeries(), this.series[index]);
        }

        for (let i = 0; i < config.axis.length; i++) {
            for (let j = 0; j < config.axis[i].length; j++) {
                this.axis[i][j] = completeAssign(new DefaultAxis(), this.axis[i][j]);
            }
        }
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function completeAssign(target: any, source: any): any {
    const targetObject = Object(target);

    if (source === undefined || source == null) {
        return targetObject;
    }

    if (source instanceof Object) {
        for (const nextKey in source) {
            if (Object.prototype.hasOwnProperty.call(source, nextKey)) {
                if (targetObject[nextKey] instanceof Object || targetObject[nextKey] instanceof Array) {
                    completeAssign(targetObject[nextKey], source[nextKey]);
                } else {
                    targetObject[nextKey] = source[nextKey];
                }
            }
        }
    } else {
        for (let ind = 0; ind < source.length; ++ind) {
            if (targetObject[ind] instanceof Object || targetObject[ind] instanceof Array) {
                completeAssign(targetObject[ind], source[ind]);
            } else {
                targetObject[ind] = source[ind];
            }
        }
    }

    return targetObject;
}
