import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BehaviorsModule } from '@ddv/behaviors';
import { ChartsModule } from '@ddv/charts';
import { DataGridModule } from '@ddv/data-grid';
import { AdvancedDatagridModule } from '@hs/ui-core-datagrid-advanced';
import { CcPresentationModule } from '@hs/ui-core-presentation';

import { BigWTableGridConfigService } from './big-w-table/big-w-table-grid-config.service';
import { BigWTableComponent } from './big-w-table/big-w-table.component';
import { BigWTableService } from './big-w-table/big-w-table.service';
import { ChartsSharedService } from './charts-shared.service';
import { ChartDataTableComponent } from './visualization-component/chart-data-table/chart-data-table.component';
import { TableToggleComponent } from './visualization-component/table-toggle/table-toggle.component';
import { VisualizationComponent } from './visualization-component/visualization.component';
import { VisualizationWrapperComponent } from './visualization-wrapper.component';
import { VisualizationWrapperService } from './visualization-wrapper.service';

@NgModule({
    imports: [
        AdvancedDatagridModule,
        BehaviorsModule,
        ChartsModule,
        CommonModule,
        DataGridModule,
        CcPresentationModule,
        DataGridModule,
    ],
    declarations: [
        BigWTableComponent,
        ChartDataTableComponent,
        TableToggleComponent,
        VisualizationComponent,
        VisualizationWrapperComponent,
    ],
    exports: [
        VisualizationComponent,
        VisualizationWrapperComponent,
    ],
    providers: [
        BigWTableGridConfigService,
        BigWTableService,
        ChartsSharedService,
        VisualizationWrapperService,
    ],
})
export class VisualizationWrapperModule {}
