import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BehaviorsModule } from '@ddv/behaviors';
import { CrosstalkModule } from '@ddv/crosstalk';
import { DataGridModule } from '@ddv/data-grid';
import { DatasetsModule } from '@ddv/datasets';
import { ErrorHandlingModule } from '@ddv/error-handling';
import { CcDropdownsModule } from '@hs/ui-core-dropdowns';
import { CcInputsModule } from '@hs/ui-core-inputs';
import { CcPresentationModule } from '@hs/ui-core-presentation';

import {
    CheckboxCellRendererComponent,
} from './cell-renderers/checkbox-cell-renderer/checkbox-cell-renderer.component';
import {
    ColumnConditionCellRendererComponent,
} from './cell-renderers/column-condition-cell-renderer/column-condition-cell-renderer.component';
import {
    DatetimeCellRendererComponent,
} from './cell-renderers/datetime-cell-renderer/datetime-cell-renderer.component';
import { URLCellRendererComponent } from './cell-renderers/url-cell-renderer/url-cell-renderer.component';
import { ActionIconsComponent } from './components/action-icons/action-icons.component';
import { ActionIconComponent } from './components/actions-handler/action-icon/action-icon.component';
import { ActionsHandlerComponent } from './components/actions-handler/actions-handler.component';
import { AdvancedGridVisualizationComponent } from './components/advanced-grid-visualization/advanced-grid-visualization.component';
import { CustomColumnFilterComponent } from './components/custom-column-filter/custom-column-filter.component';
import { GridColumnSearchComponent } from './components/grid-column-search/grid-column-search.component';
import { GroupTooltipComponent } from './components/group-tooltip/group-tooltip.component';
import { SearchBoxComponent } from './components/search-box/search-box.component';
import { SimpleGridVisualizationComponent } from './components/simple-grid-visualization/simple-grid-visualization.component';
import { UploadModalComponent } from './components/upload-modal/upload-modal.component';
import { CheckboxHeaderComponent } from './headers/checkbox-header/checkbox-header.component';
import { AdvancedGridConfigService } from './services/advanced-grid-config.service';
import { ColumnRankingService } from './services/column-ranking.service';
import { ColumnTransformService } from './services/column-transform.service';
import { DashboardDetailsRelayService } from './services/dashboard-details-relay.service';
import { GrouperCommentService } from './services/grouper-comment.service';
import { SimpleGridConfigService } from './services/simple-grid-config.service';
import { TradeFileDetailsService } from './services/trade-file-details.service';
import { UserGridColumnOverridesService } from './services/user-grid-column-overrides.service';

@NgModule({
    imports: [
        BehaviorsModule,
        CcDropdownsModule,
        CcInputsModule,
        CommonModule,
        CrosstalkModule,
        DataGridModule,
        DatasetsModule,
        FormsModule,
        ErrorHandlingModule,
        CcPresentationModule,
    ],
    declarations: [
        ActionIconsComponent,
        ActionsHandlerComponent,
        ActionIconComponent,
        AdvancedGridVisualizationComponent,
        CheckboxCellRendererComponent,
        CheckboxHeaderComponent,
        ColumnConditionCellRendererComponent,
        CustomColumnFilterComponent,
        DatetimeCellRendererComponent,
        GridColumnSearchComponent,
        GroupTooltipComponent,
        SearchBoxComponent,
        SimpleGridVisualizationComponent,
        UploadModalComponent,
        URLCellRendererComponent,
    ],
    exports: [
        AdvancedGridVisualizationComponent,
        SimpleGridVisualizationComponent,
    ],
    providers: [
        AdvancedGridConfigService,
        ColumnRankingService,
        ColumnTransformService,
        DashboardDetailsRelayService,
        GrouperCommentService,
        SimpleGridConfigService,
        TradeFileDetailsService,
        UserGridColumnOverridesService,
    ],
})
export class GridVisualizationsModule {}
