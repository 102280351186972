<div class="viz-widget">
    <app-widget-header
        #widgetHeaderComponent
        [headers]="headers"
        [dashboardId]="dashboardId"
        [widgetId]="id"
        [widgetOnBoard]="widgetOnBoard"
        [userPreferences]="userPreference"
        (updateFilter)="onFilterChanged($event)"
        (visualizationChanged)="onVisualizationChanged($event)"
        (widgetSaved)="onWidgetSaved($event)"
        (settingsChanged)="onWidgetSettingOverridesChanged($event.updatedWidget, $event.changes)"
        (headerMenuSelect)="onHeaderMenuSelect($event)">
    </app-widget-header>

    <div *ngIf="shouldDisplayMessage()" class="no-data">
        <span>{{getErrorMessage()}}</span>
    </div>

    <div class="no-data hi-error" *ngIf="isErrorOccurred && displayHIErrorMessage">
        <span>{{getHIErrorMessage()}}</span>
        <span>{{getHIAdditionalMessage()}}</span>
    </div>

    <div class="no-data" [class.error]="isErrorOccurred" *ngIf="isErrorOccurred && !displayHIErrorMessage">
        <span class="icon-notification-warning" (click)="showErrorMessage()"></span>
    </div>

    <div *ngIf="shouldDisplayVisualization()" #vizContent>
    </div>
</div>

<ng-template #fastnessCheckTemplate>
    <div class="fastness-title">Date Not Cached.</div>
    <div class="fastness-message">Data for this date is not cached. Do you wish to continue?</div>
</ng-template>
