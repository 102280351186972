import {
    ColDef,
    ColGroupDef,
    Column,
    ColumnEvent,
    ColumnEverythingChangedEvent,
    ColumnMovedEvent,
    ColumnPinnedEvent,
    ColumnPivotModeChangedEvent,
    ColumnRowGroupChangedEvent,
    ColumnState,
    ColumnValueChangedEvent,
    ColumnVisibleEvent,
    FilterChangedEvent,
    HeaderValueGetterParams,
    IRowNode,
    MenuItemDef,
    SortChangedEvent,
    ValueSetterParams,
} from '@ag-grid-community/core';
import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { CurrentStateService, JSONStorage, LocalStorageProviderService } from '@ddv/behaviors';
import { AlertService, AlertType, ConfirmationPopupService } from '@ddv/common-components';
import {
    ConversationEvent,
    CrosstalkModalService,
    CrosstalkRealtimeConversationService,
    CrosstalkRealtimeEvents,
    CrosstalkService,
    CrosstalkUpdateType,
    hasDuplicates,
    PostRealtimeCommentUpdateNotificationService,
} from '@ddv/crosstalk';
import {
    CustomColDef,
    CustomSelectionChangedEvent,
    GetMainMenuItemsParams,
    GridState,
    RowGroupActionService,
    RowSelectionEventModel,
} from '@ddv/data-grid';
import { DatasetDefinitionsService } from '@ddv/dataset-definitions';
import {
    ActionHandlerService,
    DatasetManagerService,
    MetadataService,
    splitEndpointIntoClientAndRoute,
    WidgetDataSourceService,
} from '@ddv/datasets';
import { UserEntitlementService } from '@ddv/entitlements';
import { ClientDatasetFilterService, QueryParamsService } from '@ddv/filters';
import { ManagerService } from '@ddv/layout';
import {
    Action,
    ActionHandler,
    ActionHandlerBody,
    AUTO_GROUP_COLUMN_ID,
    COMPARE_GROUP_ID_SUFFIX,
    CompareColumnID,
    CompareColumnName,
    ConfigItem,
    crosstalkCheckboxFieldId,
    CrosstalkFields,
    DashboardPreference,
    DATASET_KEY,
    DataUpdateBody,
    EDITABLE_CHECKBOX_FIELD,
    ExportFilteredData,
    RowGroupOpenedEvent,
    TFL_DETAILS_AUTO_GROUP_COLUMN_ID,
    TRANSACTION_TYPE_FIELD,
    TrebekConversationFields,
    trebekRealTimeCommentFields,
    UserDefinedField,
    WIDGET_KEY,
    WIDGET_LIFECYCLE_EVENT,
    WidgetAction,
    WidgetFilterParams,
    WidgetLifeCycleData,
    WidgetLifecycleEvent,
    FilterPreference,
    RuntimeParameter,
} from '@ddv/models';
import { NamedQueriesService } from '@ddv/named-queries';
import { FuzzyDatesService } from '@ddv/reference-data';
import { deepCompare, initialCapitalization } from '@ddv/utils';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { SelectedWidgetRelayService } from '../../../base/selected-widget-relay.service';
import { BaseGridVisualizationComponent } from '../../base-grid-visualization.component';
import { ColumnSearch } from '../../models/column-search';
import { LocalConfigurations } from '../../models/local-configurations';
import { AdvancedGridConfigService } from '../../services/advanced-grid-config.service';
import { GrouperCommentService } from '../../services/grouper-comment.service';
import { TradeFileDetailsService } from '../../services/trade-file-details.service';
import { UserGridColumnOverridesService } from '../../services/user-grid-column-overrides.service';

const FILE_HISTORY_ID = 'file_history_id';

@Component({
    selector: 'app-advanced-grid-visualization',
    templateUrl: './advanced-grid-visualization.component.html',
    styleUrls: ['../../grid.component.scss'],
})
export class AdvancedGridVisualizationComponent extends BaseGridVisualizationComponent implements OnInit, OnDestroy {
    @Output() selectionChangedEvent = new EventEmitter<CustomSelectionChangedEvent>();
    searchColumns: ColumnSearch[] = [];
    isWidgetMaximized = false;
    isToolPanelShowing = false;
    displayToolPanelOnLoad = false;
    isToolPanelFlaggedForHidden = false;
    columnSearchText = '';
    quickSearchPlaceholder = 'Row Search';
    crosstalkColumnsVisible = false;
    bulkCommentingOn = false;
    bulkRowsNumber = 0;
    enableCompareMode = false;
    enableGroupEdit = false;
    params: DashboardPreference | WidgetFilterParams | undefined;
    @ViewChild('bulkEditTemplate') bulkEditTemplate: TemplateRef<string> | undefined;

    protected actionIconsAllowed = false;
    protected actions: Action[] | undefined;
    protected actionHandlerFilterParameter: RuntimeParameter | undefined;

    private clientCode = '';
    private realtimeConversationUpdates$: Observable<ConversationEvent[]> | undefined;
    private readonly compareColIds: string[] = [];
    private readonly fieldsForComparing: string[] = [];
    private readonly enableForColumns: string[] = [];
    private isInitialGrouperCommentLoad = true;
    private fetchUpdatedConversationsSubscription: Subscription | undefined;
    private fetchGrouperCommentsSubscription: Subscription | undefined;
    private readonly storage: JSONStorage;
    private selectedRows: unknown[] = [];

    constructor(
        alertService: AlertService,
        clientDatasetFilterService: ClientDatasetFilterService,
        elementRef: ElementRef,
        crosstalkModalService: CrosstalkModalService,
        protected override readonly gridConfigService: AdvancedGridConfigService,
        manager: ManagerService,
        metadataService: MetadataService,
        crosstalkService: CrosstalkService,
        currentStateService: CurrentStateService,
        private readonly datasourceService: WidgetDataSourceService,
        protected override readonly datasetManagerService: DatasetManagerService,
        private readonly crosstalkRealtimeConversationService: CrosstalkRealtimeConversationService,
        userEntitlementService: UserEntitlementService,
        localStorageProvider: LocalStorageProviderService,
        fuzzyDatesService: FuzzyDatesService,
        private readonly datasetDefinitionsService: DatasetDefinitionsService,
        private readonly queryParamsService: QueryParamsService,
        private readonly grouperCommentsService: GrouperCommentService,
        private readonly tradeFileDetailsService: TradeFileDetailsService,
        userGridColumnOverridesService: UserGridColumnOverridesService,
        private readonly rowGroupActionService: RowGroupActionService,
        private readonly postRealtimeUpdateNotificationService: PostRealtimeCommentUpdateNotificationService,
        selectedWidgetRelayService: SelectedWidgetRelayService,
        private readonly confirmationService: ConfirmationPopupService,
        private readonly namedQueryService: NamedQueriesService,
        private readonly actionHandlerService: ActionHandlerService,
    ) {
        super(alertService,
            clientDatasetFilterService,
            elementRef,
            crosstalkModalService,
            gridConfigService,
            metadataService,
            crosstalkService,
            fuzzyDatesService,
            userEntitlementService,
            userGridColumnOverridesService,
            currentStateService,
            manager,
            selectedWidgetRelayService,
            datasetManagerService);

        this.storage = localStorageProvider.provide();
    }

    override ngOnInit(): void {
        this.displayToolPanelOnLoad = this.isWidgetMaximized = !!this.manager.getWidgetState(this.widgetId)?.maximized;

        const widgetPrefs = this.getWidgetPreferences();
        if (widgetPrefs) {
            this.enableCompareMode = !!widgetPrefs.enableCompareMode;
            this.enableGroupEdit = !!this.getConversableType();

            if (this.isSubscribedToDashboardFilters()) {
                this.subscribeTo(this.queryParamsService.dashboardQueryParams, (params) => {
                    this.params = params;
                    this.gridConfigService.clearEditedCellsOriginalValue();
                });
            } else {
                this.subscribeTo(this.queryParamsService.widgetQueryParams, (params) => {
                    this.params = params;
                    this.gridConfigService.clearEditedCellsOriginalValue();
                });
            }
        }

        this.subscribeTo(this.manager.maximizeWidgetAction$, ({ toBeMaximized }) => {
            this.isToolPanelFlaggedForHidden = !!toBeMaximized;
        });

        this.subscribeTo(this.currentStateService.clientCode$, (clientCode) => this.clientCode = clientCode);

        super.ngOnInit();

        this.actionIconsAllowed = !this.isManageWidgetMode() && (this.isTFLDetails || this.isTFLIncompleteFiles);

        this.isGridReadyObs?.subscribe((isGridReady) => {
            if (isGridReady && this.isCrosstalkGrid && !this.widgetPrefs?.isDetailWidget) {
                this.setColumnFilterAndSort();
            }
        });

        this.selectedRows = [];
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.unsubscribeFromConversationChanges();
        if (this.fetchGrouperCommentsSubscription) {
            this.fetchGrouperCommentsSubscription?.unsubscribe();
        }
    }

    override onActionsChanged(actions: Action[]): void {
        this.actions = actions;

        if (this.actions?.some((action) => action.type === 'each')) {
            this.dataGridComponent?.enableSelectedRowCount();
        }

        // this is hacky way to see if the datasetId is for a dataset definition vs. a named query
        if (!isNaN(Number(this.datasetId))) {
            return;
        }

        // this is probably a bad pattern to reach up and call the named query service
        // the runtime parameters that are relevant should probably be being passed down from parent to child
        if (typeof this.datasetId === 'string') {
            this.namedQueryService.fetchNamedQuery(`${this.datasetId}`).subscribe((namedQuery) => {
                this.parseNamedQueryParametersForActionsHandlerFilter(namedQuery.parameters);
            });
        }
    }

    widgetLifeCycleCallBack(eventName: WIDGET_LIFECYCLE_EVENT.DATA_UPDATE, data: DataUpdateBody): void;
    widgetLifeCycleCallBack(eventName: WIDGET_LIFECYCLE_EVENT.INTER_WIDGET_COMMUNICATION, data: WidgetLifecycleEvent): void;
    widgetLifeCycleCallBack(eventName: WidgetLifecycleEvent, data: WidgetLifeCycleData): void;
    widgetLifeCycleCallBack(
        eventName: WidgetLifecycleEvent | WIDGET_LIFECYCLE_EVENT.DATA_UPDATE | WIDGET_LIFECYCLE_EVENT.INTER_WIDGET_COMMUNICATION,
        data: DataUpdateBody | WidgetLifecycleEvent | WidgetLifeCycleData,
    ): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        super.widgetLifeCycleCallBack(eventName, data as any);

        const columnDefinitions = this.getColumnDefinitions();
        const columnGroups = this.initialColumnState
            .filter((state) => state.rowGroup != null && state.rowGroupIndex != null)
            .sort((a, b) => a.rowGroupIndex! > b.rowGroupIndex! ? 1 : -1)
            .map((column) => column.colId);

        switch (eventName) {
            case WIDGET_LIFECYCLE_EVENT.DATA_UPDATE:
                this.loadConversationMetadata();
                this.reorderColumns();
                if (this.isCrosstalkGrid && !this.widgetPrefs?.isDetailWidget) {
                    this.setColumnFilterAndSort();
                }
                if (this.detailWidgetOpened) {
                    this.dataGridComponent?.refreshHeader();
                    this.detailWidgetOpened = false;
                }
                break;
            case WIDGET_LIFECYCLE_EVENT.ENTER_COMMENT_EDIT_SINGLE_MODE:
                this.enterCommentMode(false);
                break;
            case WIDGET_LIFECYCLE_EVENT.HIDE_COMPARE_DATEPICKER:
                this.enableForColumns.forEach((colId) => this.toggleCompareMode(colId, false));
                break;
            case WIDGET_LIFECYCLE_EVENT.ADD_COMPARE_COLUMNS:
                if (!this.compareColIds.length) {
                    this.enableForColumns.forEach((colId) => this.toggleCompareMode(colId));
                    this.setColumnFilterAndSort();
                }
                break;
            case WIDGET_LIFECYCLE_EVENT.MASTER_FILTERS_REMOVED:
                if (this.isMasterWidget()) {
                    this.deselectAllSelected();
                }
                break;
            case WIDGET_LIFECYCLE_EVENT.GROUP_COLUMNS_ORDER_CHANGED:
                // The below row triggers columnDefs re-evaluation
                // otherwise the attachments column remains empty
                // in case initial group columns count is the same as the overrides group columns count
                // (e.g. the user just reordered the group columns)
                // https://github.com/ag-grid/ag-grid/issues/2771
                this.dataGridComponent?.setColumnDefs([]);
                this.dataGridComponent?.setColumnDefs(columnDefinitions);
                this.pinColumnGroup();
                break;
            case WIDGET_LIFECYCLE_EVENT.VIEW_RESTORED:
                this.dataGridComponent?.setRowGroupColumns(columnGroups);

                if (!this.isInViewMode) {
                    this.restoreColumnDefinitionsInViewEditMode();
                }
                this.deselectAllSelected();
                break;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    override widgetLifeCyclePostProcess(eventName: WIDGET_LIFECYCLE_EVENT, data: any): void {
        switch (eventName) {
            case WIDGET_LIFECYCLE_EVENT.AFTER_MAXIMIZE:
                this.isWidgetMaximized = true;
                this.setToolPanelVisibility(true);
                break;
            case WIDGET_LIFECYCLE_EVENT.AFTER_CASCADE:
                this.setToolPanelVisibility(false);
                this.isWidgetMaximized = false;
                break;
            default:
                super.widgetLifeCyclePostProcess(eventName, data);
        }
    }

    onColumnFilterChanged(text: string): void {
        this.columnSearchText = text;
        this.dataGridComponent?.searchByColumnHeader(text);
    }

    setToolPanelVisibility(visible: boolean): void {
        if (!this.isWidgetMaximized) {
            this.manager.triggerWidgetAction(this.widgetId, WidgetAction.MAXIMIZE);
            return;
        }

        let showToolPanel = visible;
        if (this.isToolPanelFlaggedForHidden) {
            showToolPanel = false;
        }

        this.dataGridComponent?.showToolPanel(showToolPanel);
        this.isToolPanelShowing = !!this.dataGridComponent?.isToolPanelShowing();
    }

    toggleToolpanel(): void {
        this.setToolPanelVisibility(!this.dataGridComponent?.isToolPanelShowing());
    }

    setColumnVisibility(event: ColumnVisibleEvent): void {
        const columnsMap: Record<string, boolean> = {};
        const nonDynamicColumns = this.filterOutDynamicColumns();
        event.columns?.filter((c) => nonDynamicColumns.includes(c.getColId()))
            .forEach((c) => {
                Object.assign(columnsMap, { [c.getColId()]: c.isVisible() });
            });

        this.searchColumns.forEach((searchColumn) => {
            if (Object.prototype.hasOwnProperty.call(columnsMap, searchColumn.colId)) {
                searchColumn.visible = columnsMap[searchColumn.colId];
            }
        });

        if (event.source === 'toolPanelUi' && this.isInViewMode && !this.compareColIds.length) {
            this.userGridColumnOverridesService.updateColumnVisibility(this.widgetId, columnsMap);
        }
        this.gridStateUpdated.emit(this.getGridStateEvent('COLUMNS'));
    }

    override onGridReady(): void {
        this.pinColumnGroup();
        this.pinColumns();
        this.reorderColumns();
        super.onGridReady();
        if (this.isCrosstalkGrid && this.vizData) {
            this.loadConversationMetadata();
        }
    }

    override onReady(): void {
        super.onReady();
        this.setSearchColumns();
        if (this.displayToolPanelOnLoad) {
            this.displayToolPanelOnLoad = false;
            this.setToolPanelVisibility(true);
        }
    }

    onColumnPivotModeChanged(event: ColumnPivotModeChangedEvent | ColumnEvent): void {
        const gridState = this.getGridState();
        const columnsState = gridState?.columnState ?? [];
        const visibleColumns: string[] = [];
        const isPivotMode = event.api?.isPivotMode() ?? false;
        const columns = event.api.getColumns();

        if ((event as ColumnEvent)?.source === 'toolPanelUi' && event?.type === 'columnValueChanged' &&
            this.isInViewMode && this.dataGridComponent?.isPivotMode()) {
            const nonDynamicColumns = this.filterOutDynamicColumns();
            const filteredColumns = columns?.filter((c) => nonDynamicColumns.includes(c.getColId())) ?? [];

            this.userGridColumnOverridesService.updateAggFuncInPivotMode(
                this.widgetId,
                this.preferences?.configs?.values ?? [],
                filteredColumns,
            );
        }

        if (this.isCrosstalkGrid && !this.isManageWidgetMode()) {
            this.setCheckboxColumnPosition(columnsState, 'ConfigItem');
        }

        columnsState.forEach((columnState) => {
            if (isPivotMode) {
                if (columnState.aggregationType || (columnState.rowGroupIndex != null)) {
                    visibleColumns.push(columnState.colId);
                }
            } else {
                if (!columnState.hide) {
                    visibleColumns.push(columnState.colId);
                }
            }
        });

        this.searchColumns.forEach((searchColumn) => {
            searchColumn.visible = visibleColumns.some((colId) => colId === searchColumn.colId);
        });

        this.setGridAggregateRow();

        if (!isPivotMode) {
            if (!this.conversableType) {
                return;
            }

            const rowGroups = this.dataGridComponent?.getAllNodes().filter((node) => node.group) ?? [];
            if (rowGroups.length) {
                this.grouperCommentsService.loadGroupCommentFromCache(
                    rowGroups,
                    this.clientCode,
                    this.dataGridComponent,
                    this.conversableType,
                );
                this.dataGridComponent?.onFilterChanged();
            }
        }

        if (this.hasAutoGroupAndCrosstalkCheckboxColumns()) {
            this.userGridColumnOverridesService.moveAutoGroupColumnAfterCheckboxColumn(this.dataGridComponent, this.isInViewMode);
        }

        const allNumberUDFs = this.getAllNumberUDFs(columns);
        if (allNumberUDFs.length) {
            allNumberUDFs.forEach((c) => {
                c.getColDef().editable = (params): boolean =>
                    !(event.type === 'columnPivotModeChanged' && isPivotMode || params.node.allChildrenCount);
            });
        }

        this.gridStateUpdated.emit(this.getGridStateEvent('COLUMNS'));
    }

    override onColumnEverythingChanged(event: ColumnEverythingChangedEvent): void {
        super.onColumnEverythingChanged(event);

        if (this.hasAutoGroupAndCrosstalkCheckboxColumns()) {
            this.userGridColumnOverridesService.moveAutoGroupColumnAfterCheckboxColumn(this.dataGridComponent, this.isInViewMode);
        }

        if (this.crosstalkColumnsVisible) {
            this.setCrosstalkColumnVisibility(this.crosstalkColumnsVisible);
        }
    }

    onValueColumnChanged(event: ColumnValueChangedEvent): void {
        this.onColumnPivotModeChanged(event);
    }

    loadGrouperComments(): void {
        const allNodes = this.dataGridComponent?.getAllNodes() ?? [];
        const rowGroups = allNodes?.filter((row) => row.group);

        if (rowGroups?.length) {
            const uniqueKey = this.getUniqueKey();
            if (!this.conversableType) {
                return;
            }

            if (this.fetchGrouperCommentsSubscription) {
                this.fetchGrouperCommentsSubscription.unsubscribe();
            }

            this.fetchGrouperCommentsSubscription = this.grouperCommentsService.fetchGroupComments(
                rowGroups,
                this.clientCode,
                uniqueKey,
                this.conversableType,
            ).subscribe((groups) => {
                this.dataGridComponent?.refreshCells({ rowNodes: groups, force: true });
                this.unsubscribeFromConversationChanges();

                // This is intentional. A race condition occurs which makes multiple websocket connections
                setTimeout(() => {
                    this.subscribeToConversationChanges(
                        allNodes.map((node) => node.data?.conversationId));

                    this.rowGroupActionService.emitWhenFilterOrSortIsApplied(!!rowGroups.length, 'FILTER');
                }, 150);
            });
        }
    }

    onRowGroupColumnChanged(event: ColumnRowGroupChangedEvent): void {
        if (this.shouldUpdateGroupColumnOverride(event)) {
            this.userGridColumnOverridesService.updateGroupColumn(this.widgetId, event);
        }

        // The below is keeping the current grouping when toggling compare mode
        this.preferences?.configs?.values.forEach((value) => {
            const colDef = this.gridState?.columnState.find((c) => c.colId === value.colId);
            if (colDef) {
                value.rowGroupIndex = colDef.rowGroupIndex;
            }
        });

        if (this.crosstalkColumnsVisible && event.columns?.length) {
            this.addCrosstalkCommentCounterColumn();
            this.loadGrouperComments();
        } else if (this.crosstalkColumnsVisible) {
            this.removeCrosstalkCommentCounterColumn();
        }

        if (this.dataGridComponent?.getRowGroupColumns()?.length) {
            const overrides = this.userGridColumnOverridesService.getCurrentGridColumnOverrides(this.widgetId, this.visualizationId);
            const groupColumns = this.dataGridComponent?.getRowGroupColumns() ?? [];
            const groupWidth = this.getGroupColumnWidth(overrides, groupColumns)!;
            this.dataGridComponent?.setColumnWidth(AUTO_GROUP_COLUMN_ID, groupWidth);
        }

        this.onColumnPivotModeChanged(event);
    }

    onRowGroupOpened(event: RowGroupOpenedEvent): void {
        this.rowGroupActionService.emitWhenRowGroupIsOpenedOrClosed(event);
    }

    override onRowSelected(event: RowSelectionEventModel): void {
        super.onRowSelected(event);

        const selectingFirstRowWhileInEditMode = (
            !this.bulkCommentingOn &&
            this.conversableType &&
            this.getSelectedNodes().length > 0);

        if (selectingFirstRowWhileInEditMode) {
            this.enterCommentMode(true);
        }

        this.dataGridComponent?.refreshCells({ rowNodes: [event.selectedRow] });

        this.selectedRows.push(event.selectedRowData);
    }

    override onRowUnselected(event: RowSelectionEventModel): void {
        super.onRowUnselected(event);

        const deselectingLastSelectedRowWhileInBulkMode = this.bulkCommentingOn && this.getSelectedNodes().length === 0;

        if (deselectingLastSelectedRowWhileInBulkMode) {
            this.enterCommentMode(false);
        }

        this.dataGridComponent?.refreshCells({ rowNodes: [event.selectedRow] });

        this.selectedRows = this.selectedRows.filter((row) => row !== event.selectedRowData);
    }

    onSelectionChanged(data: CustomSelectionChangedEvent): void {
        this.selectionChangedEvent.emit(data);
    }

    onDisplayedColumnsChangedEvent(): void {
        this.gridStateUpdated.emit(this.getGridStateEvent('COLUMNS'));
    }

    showDataGridColumn(column: ColumnSearch): void {
        const isPivotMode = this.getGridState()?.pivotMode;
        if (isPivotMode) {
            this.dataGridComponent?.setColumnVisibleInPivotMode(column.colId, column.visible);
        } else {
            this.dataGridComponent?.setColumnVisible(column.colId, column.visible);
        }
    }

    handleAcknowledge(): void {
        const rowsToAcknowledge = this.getRowsForAction();

        if (!rowsToAcknowledge.length) {
            this.alertService.warn('Please select a trade to acknowledge');
            return;
        }

        const acknowledgeAction = this.getAcknowledgeAction();
        this.createAction(acknowledgeAction, rowsToAcknowledge);
    }

    handleResubmit(): void {
        const rowsToResubmit = this.getRowsForAction();
        if (rowsToResubmit.length) {
            const reprocessAction = this.actionState?.[this.datasetId].find((action) => action.name === 'reprocess');
            this.createAction(reprocessAction, rowsToResubmit);
        } else {
            this.alertService.warn('Please select a trade to resubmit');
        }
    }

    override getExportFilteredData(isCsv = false, onlySelectedRows = false): ExportFilteredData {
        return {
            data: this.gridConfigService.getExportFilteredData(this.dataGridComponent, this.preferences, isCsv, onlySelectedRows),
            summary: {},
        };
    }

    override getExportFullData(): ExportFilteredData {
        return { data: this.gridConfigService.getExportFullData(this.dataGridComponent), summary: {} };
    }

    override getGridState(): GridState | undefined {
        return this.dataGridComponent?.getState();
    }

    override setGridState(state: GridState): void {
        if (state) {
            this.dataGridComponent?.setState(state);
        }
    }

    onPivotColumnChanged(): void {
        this.setGridAggregateRow();
    }

    override onFilterChanged(event: FilterChangedEvent): void {
        super.onFilterChanged(event);

        const groupNodes = this.dataGridComponent?.getAllNodes().filter((node) => node.group);
        if (this.isManageWidgetMode() || event.source === 'columnFilter') {
            this.rowGroupActionService.emitWhenFilterOrSortIsApplied(!!groupNodes?.length, 'FILTER');
        }
    }

    override onSortChanged(event: SortChangedEvent): void {
        super.onSortChanged(event);
        const groupNodes = this.dataGridComponent?.getAllNodes().filter((node) => node.group);
        this.rowGroupActionService.emitWhenFilterOrSortIsApplied(!!groupNodes?.length, 'SORT');
    }

    override setupGrid(): void {
        super.setupGrid();

        const columnDefinitions = this.gridConfiguration?.columnDefinitions;
        const localStorageConfigs: LocalConfigurations = this.storage.access(this.localConfigName) ?? {};
        const storedPins = localStorageConfigs.pinnedValues ? { ...localStorageConfigs.pinnedValues } : {};

        columnDefinitions?.forEach((column: ColDef) => {
            if (column.filter === 'agNumberColumnFilter' && column.field !== CrosstalkFields.CommentCounter) {
                this.fieldsForComparing.push(column.field!);
                this.enableForColumns.push(column.colId!);
            }
        });
        if (this.fieldsForComparing.length && this.gridConfiguration) {
            this.gridConfiguration.fieldsForComparing = this.fieldsForComparing;
        }

        // Ensure that default pinned columns are stored unless overridden
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        columnDefinitions?.forEach((config: any) => {
            if (config.pinned && !Object.prototype.hasOwnProperty.call(storedPins, config.colId)) {
                storedPins[config.colId] = config.pinned;
            }
        });

        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete storedPins[crosstalkCheckboxFieldId];
        if (this.localConfigName) {
            this.storage.store(this.localConfigName, { ...localStorageConfigs, pinnedValues: storedPins });
        }

        // Replace default pin statuses with local settings
        Object.keys(storedPins).forEach((column) => {
            columnDefinitions?.forEach((config) => {
                if ((config as ColDef).colId === column) {
                    (config as ColDef).pinned = storedPins[column];
                }
            });
        });

        if (this.isWidgetReadOnly && this.gridConfiguration?.gridOptions) {
            this.gridConfiguration.gridOptions.functionsReadOnly = true;
        }

        if (this.gridConfiguration?.gridOptions) {
            this.gridConfiguration.gridOptions.getContextMenuItems = (): (string | MenuItemDef)[] => {
                return [
                    'copy',
                    'copyWithHeaders',
                    'paste',
                    this.getToolPanelConfig(),
                    'export',
                ];
            };
        }

        if (this.gridConfiguration?.gridOptions) {
            this.gridConfiguration.gridOptions.quickFilterText = this.rowSearchText;
            this.gridConfiguration.gridOptions.getMainMenuItems = (params: GetMainMenuItemsParams): (string | MenuItemDef)[] => {
                const defaultItems = [...params.defaultItems, this.getToolPanelConfig()];

                const tpIndex = defaultItems.indexOf('toolPanel');
                if (tpIndex > -1) {
                    defaultItems.splice(tpIndex, 1);
                }

                const autoSizeThisIndex = defaultItems.indexOf('autoSizeThis');
                if (autoSizeThisIndex > -1) {
                    const columnId = params.column.getColId();
                    defaultItems[autoSizeThisIndex] = this.getAutoSizeThisConfig(columnId);
                }

                const autoSizeAllIndex = defaultItems.indexOf('autoSizeAll');
                if (autoSizeAllIndex > -1) {
                    defaultItems[autoSizeAllIndex] = this.getAutoSizeAllConfig();
                }

                return defaultItems;
            };

            this.gridConfiguration.gridOptions.onColumnPinned = (params): void => this.onColumnPinned(params);
        }

        this.removeCheckboxesFromTFLDetailsGroupRows();

        this.removeCheckboxesFromCrosstalkRowGroupHeaders();
    }

    isRowGroupEnabled(columnConfigs: ConfigItem[]): boolean {
        return columnConfigs.some((columnConfig) => columnConfig.canPivotOn);
    }

    setCrosstalkColumnVisibility(visible: boolean): void {
        this.crosstalkColumnsVisible = visible;
        if (this.dataGridComponent?.isRowGroupingOn()) {
            this.addCrosstalkCommentCounterColumn();
            if (this.isInitialGrouperCommentLoad && !this.widgetPrefs?.isDetailWidget) {
                this.setColumnFilterAndSort();
                this.isInitialGrouperCommentLoad = false;
            }
        } else {
            this.removeCrosstalkCommentCounterColumn();
        }
    }

    subscribeToConversationChanges(conversationIds: string[]): void {
        const ids = conversationIds.filter((id) => !!id);   // Filter out IDs that aren't real

        if (!this.realtimeConversationUpdates$ && ids.length) {
            this.realtimeConversationUpdates$ = this.crosstalkRealtimeConversationService.subscribeTo(ids);

            if (!this.realtimeConversationUpdates$) {
                return;
            }

            this.realtimeConversationUpdates$
                .pipe(
                    map((event) => {
                        const latestEventsByConversation = new Map<string, ConversationEvent>();
                        for (const commentAdded of event) {
                            latestEventsByConversation.set(commentAdded.conversationId, commentAdded);
                        }
                        return latestEventsByConversation;
                    }),
                )
                .subscribe((latestEventsByConversation) => {
                    if (this.dataGridComponent?.grid?.api) {
                        const uniqueConversationIds: string[] = [];

                        this.dataGridComponent.grid.api.forEachNode((node) => {
                            const { data } = node;
                            if (data) {
                                const conversationId = 'conversationId';
                                const event = latestEventsByConversation.get(data[conversationId]);

                                if (event?.name === CrosstalkRealtimeEvents.LAST_COMMENT_CHANGED && !event.comment) {
                                    const updateColumn = this.calculateColumnToUpdate(event.isHedgeServComment);
                                    data[updateColumn] = '';
                                    data[`${updateColumn}Author`] = '';
                                    data[`${updateColumn}Created`] = '';

                                    if (!uniqueConversationIds.includes(conversationId)) {
                                        this.updateDataSourceService(updateColumn, event, 'comment');
                                        uniqueConversationIds.push(conversationId);
                                    }
                                } else if (event?.name === CrosstalkRealtimeEvents.LAST_COMMENT_CHANGED) {
                                    const updateColumn = this.calculateColumnToUpdate(event.comment.isHedgeServComment);
                                    data[updateColumn] = event.comment.message;
                                    data[`${updateColumn}Author`] = event.comment.createdBy;
                                    data[`${updateColumn}Created`] = event.comment.created;

                                    if (!uniqueConversationIds.includes(conversationId)) {
                                        this.updateDataSourceService(updateColumn, event, 'comment');
                                        uniqueConversationIds.push(conversationId);
                                    }
                                }

                                if (event?.name === CrosstalkRealtimeEvents.LAST_ATTACHMENTS_CHANGED) {
                                    data[CrosstalkFields.Attachments] = event.lastAttachments?.length ? 'Attachments' : 'No Attachments';
                                    data.links[CrosstalkFields.Attachments] = {
                                        ...data.links[CrosstalkFields.Attachments],
                                        lastAttachments: event.lastAttachments,
                                    };
                                }

                                if (event?.name === CrosstalkRealtimeEvents.USER_DEFINED_FIELDS_CHANGED) {
                                    event.userDefinedFields.forEach((udf: UserDefinedField) => {
                                        data[`udf_${udf.name}`] = {
                                            ...data[`udf_${udf.name}`],
                                            conversationId: event.conversationId,
                                            value: udf.value,
                                        };

                                        const updateData = event.userDefinedFields
                                            .find((field: UserDefinedField) => field.name === udf.name);
                                        updateData.conversationId = event.conversationId;
                                        this.updateDataSourceService(`udf_${udf.name}`, updateData, 'userDefinedField');
                                    });
                                }

                                if (event) {
                                    this.grouperCommentsService.updateGroupCommentCache(event);

                                    this.dataGridComponent?.refreshCells({ rowNodes: [node] });
                                }
                            }
                        });
                        this.postRealtimeUpdateNotificationService.emitWhenCommentIsUpdatedInRealTime();
                        this.dataGridComponent.onFilterChanged();
                    }
                });
        }
    }

    unsubscribeFromConversationChanges(): void {
        if (this.realtimeConversationUpdates$) {
            this.crosstalkRealtimeConversationService.unsubscribeFrom(this.realtimeConversationUpdates$);
            this.realtimeConversationUpdates$ = undefined;
        }
    }

    onCellValueChanged(event: ValueSetterParams): void {
        if (trebekRealTimeCommentFields.includes(event.colDef.field!)) {
            this.handleCrosstalkCommentChange(event);
        }

        if ((event.colDef as CustomColDef).isUserDefinedField) {
            this.handleUserDefinedFieldChange(event);
        }
    }

    onColumnMoved(event: ColumnMovedEvent, columns: ColumnState[] = []): void {
        const columnIds: string[] = columns.map((c) => c.colId);

        if (event?.source === 'uiColumnMoved' && this.isInViewMode && !this.compareColIds.length) {
            const nonDynamicColumns = this.filterOutDynamicColumns();
            const columnOrder = columns.filter((c) => nonDynamicColumns.includes(c.colId)).map((c) => c.colId);

            if (this.dataGridComponent?.grid.api?.isPivotMode()) {
                const ind = columnOrder.findIndex((col) => col === event.column?.getColId());
                if (ind !== -1) {
                    columnOrder.splice(event.toIndex!, 0, columnOrder.splice(ind, 1)[0]);
                }
            }

            this.tempColumnOrder = [...columnOrder];
        }

        this.setReorderedColumns(columnIds);
    }

    override deselectAllSelected(clickOutside = false): void {
        if (clickOutside && (this.isMasterWidget() || this.isTFLDetails)) {
            return;
        }

        if (!this.getEditingCells().length) {
            super.deselectAllSelected();
        }
    }

    // Only public so it can be directly called from tests :(
    updateDataSourceService(
        updateColumn: string,
        updateData: Partial<ConversationEvent> | UserDefinedField,
        updateType: CrosstalkUpdateType,
    ): void {
        const uniqueKey = this.getUniqueKey();
        this.datasourceService.updateDataSource(uniqueKey, updateColumn, updateData, updateType);
    }

    onGridFilterChanged(): void {
        if (this.crosstalkColumnsVisible) {
            this.crosstalkService.onGridFilterChanged();
        }

        if (this.isTFLDetails) {
            this.tradeFileDetailsService.onGridFilterChanged();
        }
    }

    getVisibleRowsData(exportType: string): IRowNode[] {
        const visibleRowsData: IRowNode[] = [];
        if (exportType === WIDGET_LIFECYCLE_EVENT.EXPORT_FULL_DATA) {
            this.dataGridComponent?.grid.api?.forEachNode((rowDatum: IRowNode) => {
                if (!rowDatum.group) {
                    visibleRowsData.push(rowDatum.data);
                }
            });
        } else if (exportType === WIDGET_LIFECYCLE_EVENT.EXPORT_FILTERED_ADVANCED_GRID_DATA_TO_CSV) {
            this.dataGridComponent?.grid.api?.forEachNodeAfterFilterAndSort((rowDatum: IRowNode) => {
                if (!rowDatum.group) {
                    visibleRowsData.push(rowDatum.data);
                }
            });
        } else if (exportType === WIDGET_LIFECYCLE_EVENT.EXPORT_FILTERED_DATA) {
            this.dataGridComponent?.grid.api?.forEachNodeAfterFilterAndSort((rowDatum: IRowNode) => {
                visibleRowsData.push(rowDatum.data);
            });
        }
        return visibleRowsData;
    }

    protected onActionHandlerFilterActiveChange(isActive: boolean): void {
        if (!this.actionHandlerFilterParameter) {
            return;
        }

        this.queryParamsService.dispatchUpdatedQueryParams({ [this.actionHandlerFilterParameter.name]: isActive });
    }

    protected handleAction(actionName: string): void {
        const action = this.actions?.find((a) => a.name === actionName);
        if (!action) {
            return;
        }

        // this is utter garbage.  the implication is that this.clientCode is a lie
        // that it is the clientCode for the parent dashboard (because it comes from the stupid CurrentStateService
        // and not the actual client code that was drilled into
        // this is the best way to get the real client code for this widget
        const clientCode = (this.params as DashboardPreference).clients?.[0]?.clientId;
        if (!clientCode) {
            return;
        }

        this.actionHandlerService.handleAction(clientCode, action.handler, this.selectedRows).subscribe({
            next: () => {
                this.alertService.success(`${initialCapitalization(action.name)} completed successfully`);
                this.refreshOnActionSuccess();
            },
            error: (e) => {
                const error = e as Error;
                this.alertService.error(`${initialCapitalization(action.name)} failed`, error.message);
            },
        });
    }

    private getAllNumberUDFs(columns: Column[] | null): Column[] {
        return columns?.filter((c) => {
            const colDef: CustomColDef = c.getColDef();
            return colDef.isUserDefinedField && colDef.userDefinedFieldType === 'decimal';
        }) ?? [];
    }

    private parseNamedQueryParametersForActionsHandlerFilter(parameters: RuntimeParameter[] | undefined): void {
        // this isn't really going to work long term but the thinking for now is...
        // we hardcode the "date" and "fund" parameters to be pulled up to the query params bar at the top
        // therefore the "other" parameter must be the one to display down here
        // we are further refining which "parameter" to use by checking the "parameter" type to be boolean and are taking the first one
        this.actionHandlerFilterParameter = parameters
            ?.filter((param) => param.type !== 'date' && param.type !== 'date-range' && param.type !== 'fund-list')
            .find((param) => param.type === 'boolean');
    }

    private restoreColumnDefinitionsInViewEditMode(): void {
        this.initialColumnState.forEach((state) => state.agFilterOnViewEditMode = null);
        this.setColumnFilterAndSort();
        this.dataGridComponent?.applyColumnState({ state: this.initialColumnState, applyOrder: true });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getRowsForAction(): any[] {
        const dataRows = this.dataGridComponent?.getDataRows() ?? [];
        if (this.isGroupedByAllocationGroupId()) {
            const checkedRows = dataRows.filter((row) => row[EDITABLE_CHECKBOX_FIELD]);
            const trades = checkedRows.filter((row) => !row[TFL_DETAILS_AUTO_GROUP_COLUMN_ID]);
            const allocations = dataRows.filter((row) =>
                row[TFL_DETAILS_AUTO_GROUP_COLUMN_ID] && !row[TRANSACTION_TYPE_FIELD]);
            const allocationGroupIds = checkedRows.map((row) => row[TFL_DETAILS_AUTO_GROUP_COLUMN_ID]);
            const rowsSharingAllocationGroupId = allocations
                .filter((row) => allocationGroupIds.includes(row[TFL_DETAILS_AUTO_GROUP_COLUMN_ID]));
            return [...trades, ...checkedRows, ...rowsSharingAllocationGroupId];
        }

        return dataRows.filter((row) => row[EDITABLE_CHECKBOX_FIELD]);
    }

    private handleCrosstalkCommentChange(event: ValueSetterParams): void {
        if (this.isBulkEdit()) {
            const selectedRows = this.getSelectedNodes();
            const conversationIdList: string[] = selectedRows.map((node) => node.data.conversationId);

            const editedCellNotInSelectedRows = !selectedRows.some((node) => node === event.node);
            if (editedCellNotInSelectedRows) {
                return;
            }

            this.bulkRowsNumber = conversationIdList.length;

            const confirmDialogOptions = {
                message: this.bulkEditTemplate,
                confirmButtonText: 'Upload',
                denyButtonText: 'Cancel',
            };

            this.confirmationService.showConfirmationPopup(confirmDialogOptions).subscribe({
                next: (action) => {
                    if (action === 'confirm') {
                        this.addCrosstalkCommentToManyConversations(this.conversableType, conversationIdList, event.newValue, selectedRows);
                    } else if (event.colDef.field && event.node) {
                        event.data[event.colDef.field] = event.oldValue;
                        this.dataGridComponent?.refreshCells({ rowNodes: [event.node] });
                    }
                },
            });
        } else {
            const conversationId: string = event.data.conversationId;
            this.addCrosstalkCommentToConversation(conversationId, event);
        }
    }

    private handleUserDefinedFieldChange(event: ValueSetterParams): void {
        const isNumberUdfInGroup = !!event.api.getRowGroupColumns().length &&
            (event.colDef as CustomColDef).userDefinedFieldType === 'decimal';
        const isNumberUDFValueChangedInGroup = isNumberUdfInGroup && event.oldValue === event.newValue;
        if (!this.isBulkEdit() && deepCompare(event.newValue?.value, event?.oldValue?.value) && isNumberUDFValueChangedInGroup) {
            return;
        }

        if (this.isBulkEdit()) {
            const selectedRows = this.getSelectedNodes();
            const conversationIdList = selectedRows.map((node) => node.data.conversationId);
            const udfValue = isNumberUdfInGroup ? event.newValue : event.newValue.value;
            const userDefinedFields = { [event.colDef.field?.substring(4) as string]: udfValue };

            const editedCellNotInSelectedRows = !selectedRows.some((node) => node === event.node);
            if (editedCellNotInSelectedRows) {
                return;
            }

            if (this.conversableType) {
                this.crosstalkService.bulkUpdateUserDefinedFields(
                    this.clientCode,
                    this.conversableType,
                    conversationIdList,
                    userDefinedFields,
                ).subscribe((response) => {
                    const getUserDefinedField = (conversationId: string): UserDefinedField | undefined => {
                        return response[conversationId].find((udf) => `udf_${udf.name}` === event.colDef.field);
                    };
                    const updateColumn = event.colDef.field!;

                    for (const { data } of selectedRows) {
                        const conversationId = data.conversationId;
                        const userDefinedField = getUserDefinedField(conversationId);
                        if (userDefinedField) {
                            data[updateColumn].value = userDefinedField.value;

                            data[`${updateColumn}_commenter`].value = userDefinedField.createdBy;
                            data[`${updateColumn}_commentTS`].value = userDefinedField.created;
                        }
                    }

                    this.dataGridComponent?.resetSelectedRows();
                    this.dataGridComponent?.refreshCells({ rowNodes: selectedRows });
                    this.dataGridComponent?.refreshHeader();

                    conversationIdList.forEach((conversationId) => {
                        const userDefinedField = getUserDefinedField(conversationId);
                        const updateData = { conversationId, ...userDefinedField };
                        this.updateDataSourceService(updateColumn, updateData, 'userDefinedField');
                    });
                });
            }
        } else {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const data: Record<string, any> = {};
            const conversationId: string | undefined = event.data.conversationId ?? undefined;
            const field = event.colDef.field!;
            if (!!event.api.getRowGroupColumns().length && (event.colDef as CustomColDef).userDefinedFieldType === 'decimal') {
                const updatedData: UserDefinedField = event.data[field];
                data[updatedData.name] = updatedData.value;
            } else {
                data[event.newValue.name] = event.newValue.value;
            }

            if (conversationId) {
                this.crosstalkService.updateEditedUserDefinedFields(conversationId, this.clientCode, data)
                    .subscribe((response) => {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        if ((response as any).error) {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            return this.alertService.error((response as any).error);
                        }

                        const updatedUdf: UserDefinedField | undefined = response.find((r) => r.name === event.newValue.name);
                        // Checking for _commenter && _commentTS is necessary because Crosstalk does not send those fields
                        // when we fetch grouper comments
                        if (updatedUdf && event.data[`${event.colDef.field}_commenter`] && event.data[`${event.colDef.field}_commentTS`]) {
                            event.data[`${event.colDef.field}_commenter`].value = updatedUdf.createdBy;
                            event.data[`${event.colDef.field}_commentTS`].value = updatedUdf.created;
                        }

                        if (event.node) {
                            this.dataGridComponent?.refreshCells({ rowNodes: [event.node], force: true });
                        }
                    });
            }
        }
    }

    private addCrosstalkCommentCounterColumn(): void {
        const commentCounterColumn = this.preferences?.configs?.values.find((value) => value.name === CrosstalkFields.CommentCounter);
        if (commentCounterColumn && !this.dataGridComponent?.isColumnVisible(commentCounterColumn.colId)) {
            this.dataGridComponent?.setColumnVisible(commentCounterColumn.colId, true);
        }
    }

    private removeCrosstalkCommentCounterColumn(): void {
        const commentCounterColumn = this.preferences?.configs?.values.find((value) => value.name === CrosstalkFields.CommentCounter);
        if (commentCounterColumn && this.dataGridComponent?.isColumnVisible(commentCounterColumn.colId)) {
            this.dataGridComponent?.setColumnVisible(commentCounterColumn.colId, false);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private createAction(action: Action | undefined, dataRows: any[]): void {
        if (!action) {
            return;
        }

        const { clientCode, route } = splitEndpointIntoClientAndRoute(action.handler.url);
        const method = action.handler.method;
        const columnId = action.handler.body.items!.properties.id;
        const tradeHistoryIdsSet = new Set();

        dataRows.forEach((row) => {
            if (action.name === 'reprocess') {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const item: Record<string, any> = {};
                for (const [key, value] of Object.entries(action.handler.body.items!.properties)) {
                    item[key] = row[value] || '';
                }
                tradeHistoryIdsSet.add(JSON.stringify(item));
            } else if (this.isTFLIncompleteFiles) {
                tradeHistoryIdsSet.add(row[columnId]);
            } else {
                tradeHistoryIdsSet.add(JSON.stringify({ id: row[columnId] }));
            }
        });

        const tradeHistoryIdsList = this.isTFLIncompleteFiles ?
            { fileIds: [...tradeHistoryIdsSet] } :
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            [...tradeHistoryIdsSet].map((item: any) => JSON.parse(item));

        this.gridConfigService.handleAction(clientCode, route, method, tradeHistoryIdsList)
            .subscribe({
                next: (response) => {
                    if (action.name === 'acknowledge') {
                        this.refreshOnActionSuccess();
                    }

                    if (response.success) {
                        this.alertService.success('Success');
                    } else {
                        this.alertService.error('Failed');
                    }
                },
                error: () => this.alertService.error('Failed'),
            });
    }

    private getAcknowledgeAction(): Action | undefined {
        if (this.isTFLDetails) {
            return this.actionState?.[this.datasetId].find((action) => action.name === 'acknowledge');
        }

        const method = 'POST';
        const clientCode = (this.params as DashboardPreference).clients?.[0].clientId;
        const url = `/rest/${clientCode}/tfl/files/acknowledge`;
        const body: ActionHandlerBody = {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    id: FILE_HISTORY_ID,
                },
            },
        };
        const handler: ActionHandler = { method, url, body };

        return { name: 'acknowledge', type: 'each', handler };
    }

    private addCompareColumns(target: ConfigItem | ColDef, fields: string[]): ConfigItem[] {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newColumns: any[] = [];
        const friendlyNames = ['label', 'headerName'];
        (target as ColDef).columnGroupShow = 'open';
        (target as ColDef).headerClass = 'in-compare-group';
        const headerValueGetter = (params: HeaderValueGetterParams): string => params.location === 'columnDrop' ?
            `${(target as ColDef).headerName} ${params.colDef.headerName!}` :
            params.colDef.headerName!;
        [CompareColumnID.COMPARE, CompareColumnID.DIFF].forEach((mode) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const column: Record<string, any> = { ...target };
            fields.forEach((field) => {
                if (field === 'headerName') {
                    column[field] = mode === CompareColumnID.COMPARE ? CompareColumnName.COMPARISON : CompareColumnName.DIFF;
                    (column as ColDef).headerValueGetter = headerValueGetter;
                } else {
                    column[field] += friendlyNames.includes(field) ? (mode === CompareColumnID.COMPARE ? ' Compare' : ' DIFF') : mode;
                }
            });

            if ((column as ConfigItem).linkType === 'ddvwidget') {
                (column as ConfigItem).linkType = undefined;
            }

            newColumns.push(column);
        });

        return newColumns;
    }

    private replaceColumnWithCompareGroup(
        columns: (ColDef | ColGroupDef)[],
        index: number,
        colsToRemove?: number,
        groupState?: boolean): void {
        const headerValueGetter = (params: HeaderValueGetterParams): string => params.location === 'columnDrop' ? params.colDef.headerName! : 'Original';
        columns.splice(index, colsToRemove ?? 1, {
            headerName: columns[index].headerName,
            marryChildren: true,
            resizable: true,
            groupId: `${(columns[index] as ColDef).colId}${COMPARE_GROUP_ID_SUFFIX}`,
            colId: `${(columns[index] as ColDef).colId}${COMPARE_GROUP_ID_SUFFIX}`,
            openByDefault: groupState,
            children: [
                { ...columns[index], headerValueGetter, headerClass: 'in-compare-group' },
                // see comment in DataGridComponent.setState for API/width/null
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ...(this.addCompareColumns(columns[index], ['headerName', 'colId', 'field']) as any),
            ],
        });
    }

    private restoreOriginalColumnFromCompareGroup(columns: (ColDef | ColGroupDef)[], index: number, colId: string): void {
        const original = (columns[index] as ColGroupDef).children.find((child) => (child as ColDef).colId === colId);
        columns.splice(index, 1, { ...original, headerValueGetter: undefined, headerName: columns[index].headerName, headerClass: '' });
    }

    private reorderColumns(colId?: string): void {
        const gridState = this.getGridState();

        if (gridState?.columnState) {
            const columns = this.preferences?.configs?.values ?? [];

            if (colId) {
                const compareInd = columns.findIndex((c) => c.colId === colId + CompareColumnID.COMPARE);

                if (compareInd === -1) {
                    const addAtIndex = columns.findIndex((c) => c.colId === colId);
                    columns.splice(addAtIndex + 1, 0, ...this.addCompareColumns(columns[addAtIndex], ['label', 'colId', 'value', 'name']));
                } else {
                    const indexComparison = columns.findIndex((c) => c.colId === colId + CompareColumnID.COMPARE);
                    if (indexComparison !== -1) {
                        columns.splice(indexComparison, 1);
                    }
                    const indexDiff = columns.findIndex((c) => c.colId === colId + CompareColumnID.DIFF);
                    if (indexDiff !== -1) {
                        columns.splice(indexDiff, 1);
                    }
                }
            }

            // see comment in DataGridComponent.setState for API/width/null
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            columns.forEach((cd: any) => {
                // see comment in DataGridComponent.setState for API/width/null
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                this.updateColumnPropertyFromState('width', cd, gridState.columnState as any);
            });

            if (this.isCrosstalkGrid && !this.isManageWidgetMode()) {
                this.setCheckboxColumnPosition(columns, 'ConfigItem');
            }

            const newColumnState = this.gridConfigService.orderColumnsInColumnState([...gridState.columnState], columns);
            gridState.columnState = [...newColumnState];
            this.setGridState(gridState);
        }
    }

    private setSearchColumns(): void {
        if (this.dataGridComponent) {
            const allColumns = this.dataGridComponent.getAllColumns();
            if (allColumns) {
                this.searchColumns = allColumns
                    .filter((column) => !column.getColDef().suppressColumnsToolPanel)
                    .map((column) => ({
                        colId: column.getColId(),
                        visible: column.isVisible(),
                        colName: column.getColDef().headerName!,
                    }));
            }
        }
    }

    private removeCheckboxesFromCrosstalkRowGroupHeaders(): void {
        if (this.conversableType && this.gridConfiguration?.gridOptions) {
            this.gridConfiguration.gridOptions.rowSelection = {
                ...this.gridConfiguration.gridOptions.rowSelection!,
                isRowSelectable: (node: IRowNode): boolean => !node.group,
            };
        }
    }

    private toggleCompareMode(colId: string, showCompareColumns = true): void {
        if (!showCompareColumns && !this.compareColIds.length) {
            return;
        }

        const columns = this.gridConfiguration?.columnDefinitions as ColDef[] ?? [];

        const overrides = this.userGridColumnOverridesService.getCurrentGridColumnOverrides(this.widgetId, this.visualizationId);
        const hasColumnWidthOverrides = overrides.some((o) => o.columnWidth != null);

        columns.forEach((column) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const colDef = this.gridState?.columnState.find((col) => col.colId === column.colId) as any;
            if (colDef) {
                if (column.rowGroup !== colDef.rowGroup || column.rowGroupIndex !== colDef.rowGroupIndex) {
                    column.rowGroup = colDef.rowGroup;
                    column.rowGroupIndex = colDef.rowGroupIndex;
                }

                if (column.hide !== colDef.hide) {
                    column.hide = colDef.hide;
                }

                if (column.pivot !== colDef.pivot || column.pivotIndex !== colDef.pivotIndex) {
                    column.pivot = colDef.pivot;
                    column.pivotIndex = colDef.pivotIndex;
                }

                if (hasColumnWidthOverrides && column.colId !== AUTO_GROUP_COLUMN_ID) {
                    column.width = this.getColumnWidth(overrides, column.colId!) as number;
                }
            }
        });

        const ind = this.compareColIds.indexOf(colId);

        if (ind === -1) {
            this.compareColIds.push(colId);
            const pos = columns.findIndex((c) => c.colId === colId);

            if (pos !== -1) {
                this.replaceColumnWithCompareGroup(columns, pos);
            }
        } else {
            this.compareColIds.splice(ind, 1);
            const indRemove = columns.findIndex((c) => c.colId === colId + COMPARE_GROUP_ID_SUFFIX);
            if (indRemove !== -1) {
                this.restoreOriginalColumnFromCompareGroup(columns, indRemove, colId);
            }
        }

        if (!showCompareColumns) {
            // We have to do this again after compare mode has been turned off
            // as VALUE columns ids are transformed during compare mode to colId__compare-group
            // which is not present in gridState and can't be matched earlier
            columns.forEach((column) => {
                const colDef = this.gridState?.columnState.find((col) => col.colId === column.colId);
                if (colDef) {
                    if (column.hide !== colDef.hide) {
                        column.hide = colDef.hide;
                    }
                }
            });
        }

        const columnDefinitions = this.getColumnDefinitions();

        // Adding/removing the checkbox columns on enter/exit compare mode
        this.toggleCrosstalkCheckboxColumn(columns, columnDefinitions, showCompareColumns);
        this.toggleEditableCheckboxColumn(columns, columnDefinitions, showCompareColumns);

        // Trigger columnDefs re-evaluation
        // otherwise we get the columns in wrong order in pivot mode
        // when a column is dragged to the 'Column Labels' section in sidebar
        // https://github.com/ag-grid/ag-grid/issues/2771
        this.dataGridComponent?.setColumnDefs([]);
        this.dataGridComponent?.setColumnDefs(columns);
        this.reorderColumns(colId);

        if (hasColumnWidthOverrides && this.dataGridComponent?.isRowGroupingOn()) {
            const groupColumns = this.dataGridComponent?.getRowGroupColumns() ?? [];
            const groupWidth = this.getGroupColumnWidth(overrides, groupColumns)!;
            this.dataGridComponent?.setColumnWidth(AUTO_GROUP_COLUMN_ID, groupWidth);
        }
    }

    private getToolPanelConfig(): MenuItemDef {
        return {
            name: 'Tool Panel',
            action: (): void => this.toggleToolpanel(),
            checked: this.isToolPanelShowing,
        };
    }

    private getAutoSizeThisConfig(columnId: string): MenuItemDef {
        return {
            name: 'Autosize This Column',
            action: (): void => this.dataGridComponent?.autoSizeColumn(columnId),
        };
    }

    private getAutoSizeAllConfig(): MenuItemDef {
        return {
            name: 'Autosize All Columns',
            action: (): void => this.dataGridComponent?.autoSizeColumns(),
        };
    }

    private loadConversationMetadata(): void {
        const gridState = this.getGridState();
        if (gridState?.columnState && !!this.conversableType) {
            const widgetPrefs = this.getWidgetPreferences();
            if (!widgetPrefs) {
                return;
            }

            if (this.fetchUpdatedConversationsSubscription) {
                this.fetchUpdatedConversationsSubscription.unsubscribe();
            }

            this.fetchUpdatedConversationsSubscription = this.datasetManagerService
                .fetchUpdatedConversations(widgetPrefs, this.vizData).subscribe((updatedData) => {
                    const conversationIds = updatedData.map((item) => item.conversationId);

                    if (hasDuplicates(conversationIds)) {
                        const widgetConfig = this.manager.getWidgetById(this.widgetId);
                        const title = widgetConfig?.title ??
                            (widgetPrefs.displayNameType === 'CUSTOM' ? widgetPrefs.customDisplayName : widgetPrefs.name);

                        const dsd = widgetPrefs.datasetDefinition;
                        if (!dsd) {
                            return;
                        }

                        if (widgetPrefs.datasetDefinition?.namedQueryId) {
                            // only the code in this if should remain
                            // once we switch to named queries entirely
                            this.subscribeTo(this.namedQueryService.fetchNamedQuery(widgetPrefs.datasetDefinition.namedQueryId),
                                (namedQuery) => {
                                    return namedQuery.crosstalkOptions?.showDuplicateRowsWarning && this.alertService
                                        .alert(AlertType.Info, `${namedQuery.name} on ${title} contains duplicate data rows.`);
                                });
                        } else {
                            this.subscribeTo(this.datasetDefinitionsService.fetchDatasetDefinitionDetails(Number(dsd.id)),
                                (datasetDefinition) => {
                                    return datasetDefinition.showDuplicateRowsWarning && this.alertService
                                        .alert(AlertType.Info, `${dsd.name} on ${title} contains duplicate data rows.`);
                                });
                        }
                    }

                    this.unsubscribeFromConversationChanges();
                    this.subscribeToConversationChanges(conversationIds);

                    this.updateDataSource({ data: updatedData, widgetPrefs });

                    this.setCrosstalkColumnVisibility(true);
                    this.loadGrouperComments();

                    if (this.isGridReady && !this.dataGridComponent?.isRowGroupingOn()) {
                        this.setColumnFilterAndSort();
                    }
                });
        }
    }

    private enterCommentMode(bulkCommentMode: boolean): void {
        this.bulkCommentingOn = bulkCommentMode;

        const gridState = this.gridState;
        if (!gridState) {
            return console.error('cannot enterCommentMode without a gridState');
        }

        const columnDefinitions = this.getColumnDefinitions();

        const checkboxColumn = gridState.columnState.find((c) => c.colId === crosstalkCheckboxFieldId);
        if (!checkboxColumn?.pinned && this.isCrosstalkGrid && !this.isManageWidgetMode()) {
            this.setCheckboxColumnPosition(columnDefinitions, 'ColDef');
        }

        columnDefinitions.forEach((cd: ColDef) => {
            // see comment in DataGridComponent.setState for API/width/null
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.updateColumnPropertyFromState('hide', cd, gridState.columnState as any);
            // see comment in DataGridComponent.setState for API/width/null
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.updateColumnPropertyFromState('width', cd, gridState.columnState as any);
        });

        if (!this.bulkCommentingOn) {
            this.dataGridComponent?.resetSelectedRows();
        }

        this.dataGridComponent?.setColumnDefs(columnDefinitions);
    }

    private addCrosstalkCommentToManyConversations(
        conversableType: string | undefined,
        conversationIdList: string[],
        newValue: string,
        selectedRows: IRowNode[],
    ): void {
        if (!conversableType) {
            return console.error('cannot addCrosstalkCommentToManyConversations without a conversable type');
        }

        this.crosstalkService.addCommentToManyConversations(this.clientCode, conversableType, conversationIdList, newValue)
            .subscribe((response) => {
                const updateColumn = this.calculateColumnToUpdate(response.comment.isHedgeServComment);

                for (const { data } of selectedRows) {
                    data[updateColumn] = response.comment.message;
                    data[`${updateColumn}Author`] = response.comment.createdBy;
                    data[`${updateColumn}Created`] = response.comment.created;
                }

                this.dataGridComponent?.resetSelectedRows();
                this.dataGridComponent?.refreshCells({ rowNodes: selectedRows });
                this.dataGridComponent?.refreshHeader();

                conversationIdList.forEach((conversationId) => {
                    this.updateDataSourceService(updateColumn, { conversationId, comment: response.comment }, 'comment');
                });
            });
    }

    private addCrosstalkCommentToConversation(conversationId: string, event: ValueSetterParams): void {
        forkJoin([this.crosstalkService.addCommentToConversation(this.clientCode, conversationId, event.newValue)])
            .subscribe(([addedComment]) => {
                const updateColumn = this.calculateColumnToUpdate(addedComment.isHedgeServComment);

                event.data[updateColumn] = addedComment.message;
                event.data[`${updateColumn}Author`] = addedComment.createdBy;
                event.data[`${updateColumn}Created`] = addedComment.created;

                if (event.node) {
                    this.dataGridComponent?.refreshCells({ rowNodes: [event.node], force: true });
                }

                this.updateDataSourceService(updateColumn, { conversationId, comment: addedComment }, 'comment');
            });
    }

    private isBulkEdit(): boolean {
        return this.bulkCommentingOn && this.getSelectedNodes().length > 0;
    }

    private getUniqueKey(): string {
        return this.isSubscribedToDashboardFilters() ? `${DATASET_KEY}${this.datasetId}` : `${WIDGET_KEY}${this.widgetId}`;
    }

    private onColumnPinned(params: ColumnPinnedEvent | undefined): void {
        const colId = params?.column?.getColId();

        if (colId && colId !== crosstalkCheckboxFieldId) {
            const colConfig = this.preferences?.configs?.values.find((config: ConfigItem) => config.colId === colId);
            if (colConfig) {
                colConfig.pinned = params?.pinned;
            }
            const triggeredByUser = params?.source === 'columnMenu';
            this.gridStateUpdated.emit(this.getGridStateEvent('PINNED', triggeredByUser));

            const newConfigs: LocalConfigurations = { ...this.storage.access(this.localConfigName) ?? {} };
            // Set removed pins explicitly to null so that pre-configured pins that are removed can be detected
            newConfigs.pinnedValues = { ...newConfigs.pinnedValues ?? {}, [colId]: params?.pinned ?? null };
            this.storage.store(this.localConfigName, newConfigs);
        }
    }

    private pinColumnGroup(): void {
        const localStorageConfigs: LocalConfigurations = this.storage.access(this.localConfigName) ?? {};
        const pinnedValues = localStorageConfigs.pinnedValues;

        if (pinnedValues && Object.prototype.hasOwnProperty.call(pinnedValues, AUTO_GROUP_COLUMN_ID)) {
            this.dataGridComponent?.setColumnPinned(AUTO_GROUP_COLUMN_ID, pinnedValues[AUTO_GROUP_COLUMN_ID]);
        }
    }

    private pinColumns(): void {
        const localStorageConfigs: LocalConfigurations = this.storage.access(this.localConfigName) ?? {};
        const pinnedValues = localStorageConfigs.pinnedValues;

        if (pinnedValues) {
            const pinnedColumnsIds = Object.keys(pinnedValues);
            pinnedColumnsIds.forEach((columnId) => {
                const column = this.preferences?.configs?.values.find((v) => v.colId === columnId);
                if (column && column.pinned !== pinnedValues[columnId]) {
                    column.pinned = pinnedValues[columnId];
                }
            });
        }
    }

    private refreshOnActionSuccess(): void {
        if (this.isSubscribedToDashboardFilters()) {
            this.queryParamsService.dispatchNewQueryParams({
                ...this.params as DashboardPreference,
                isPreferenceChangedOnRefresh: true,
            });
        } else {
            this.queryParamsService.addWidgetQueryParam(this.widgetId, this.params as FilterPreference);
        }
    }

    // We should only pass valid ColDef properties as property
    private updateColumnPropertyFromState(property: string, cd: ColDef, columnState: ColDef[]): void {
        const columnFromState = columnState.find((c) => c.colId === cd.colId);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (columnFromState && (cd as any)[property] !== (columnFromState as any)?.[property]) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (cd as any)[property] = (columnFromState as any)?.[property];
        }
    }

    private calculateColumnToUpdate(
        isHedgeServComment: boolean,
    ): TrebekConversationFields.ClientComment | TrebekConversationFields.HSComment {
        return isHedgeServComment ? TrebekConversationFields.HSComment : TrebekConversationFields.ClientComment;
    }

    private removeCheckboxesFromTFLDetailsGroupRows(): void {
        if (this.isTFLDetails && this.gridConfiguration?.gridOptions) {
            this.gridConfiguration.gridOptions.rowSelection = {
                ...this.gridConfiguration.gridOptions.rowSelection!,
                isRowSelectable: (node: IRowNode): boolean => !node.group,
            };
        }
    }

    private toggleCrosstalkCheckboxColumn(
        columns: ColDef[],
        columnDefinitions: (ColDef | ColGroupDef)[],
        showCompareColumns: boolean,
    ): void {
        const crosstalkCheckboxColumnIndex = columns.findIndex((c) => c.field === crosstalkCheckboxFieldId);
        const crosstalkChechboxColumn = columnDefinitions.find((cd: ColDef) => cd.field?.includes(crosstalkCheckboxFieldId));
        if (showCompareColumns && crosstalkCheckboxColumnIndex !== -1) {
            columns.splice(crosstalkCheckboxColumnIndex, 1);
        } else if (!showCompareColumns && crosstalkCheckboxColumnIndex === -1 && crosstalkChechboxColumn) {
            columns.unshift(crosstalkChechboxColumn);
        }
    }

    private toggleEditableCheckboxColumn(
        columns: ColDef[],
        columnDefinitions: (ColDef | ColGroupDef)[],
        showCompareColumns: boolean,
    ): void {
        const editableColumnIndex = columns.findIndex((c) => c.field === EDITABLE_CHECKBOX_FIELD);
        const editableCheckboxColumn = columnDefinitions.find((cd: ColDef) => cd.field?.includes(EDITABLE_CHECKBOX_FIELD));
        if (showCompareColumns && editableColumnIndex !== -1) {
            columns.splice(editableColumnIndex, 1);
        } else if (!showCompareColumns && editableColumnIndex === -1 && editableCheckboxColumn) {
            columns.unshift(editableCheckboxColumn);
        }
    }

    private getColumnDefinitions(): (ColDef | ColGroupDef)[] {
        if (!this.preferences) {
            return [];
        }

        return this.gridConfigService.getGridConfiguration(
            this.preferences,
            this.widgetId,
            this.datasetId,
            this.bulkCommentingOn,
            this.actions?.some((action) => action.type === 'each'),
        ).columnDefinitions;
    }

    private shouldUpdateGroupColumnOverride(event: ColumnRowGroupChangedEvent): boolean {
        const inPivotMode = this.dataGridComponent?.isPivotMode();
        const isDetailWidget = this.widgetPrefs?.isDetailWidget;
        const inCompareMode = this.widgetPrefs?.widgetFilters?.isComparing;
        return !isDetailWidget &&
            !inCompareMode &&
            !inPivotMode &&
            this.isInViewMode &&
            (event.source === 'toolPanelUi' || event.source === 'columnMenu');
    }

    private hasAutoGroupAndCrosstalkCheckboxColumns(): boolean {
        const checkboxColumn = this.gridState?.columnState.find((col) => col.colId === crosstalkCheckboxFieldId);
        const autoGroupColumn = this.gridState?.columnState.find((col) => col.colId === AUTO_GROUP_COLUMN_ID);
        return !!(checkboxColumn && autoGroupColumn);
    }

    private isSubscribedToDashboardFilters(): boolean {
        return this.getWidgetPreferences()?.isSubscribedToDashboardFilters ?? false;
    }
}
