import { Injectable } from '@angular/core';
import { anyWidgetIsRealtime, AppWidgetState } from '@ddv/models';
import { Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable()
export class RealtimeActiveService {
    public readonly realtimeIsActive: Observable<boolean>;
    private readonly realtimeIsActiveSubject: Subject<boolean> = new ReplaySubject<boolean>(1);

    constructor() {
        this.realtimeIsActive = this.realtimeIsActiveSubject.asObservable();
    }

    updateRealtimeActive(widgetsOrState: AppWidgetState[] | boolean): void {
        const active = Array.isArray(widgetsOrState) ? anyWidgetIsRealtime(widgetsOrState) : widgetsOrState;
        this.realtimeIsActiveSubject.next(active);
    }
}
